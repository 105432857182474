footer{
	position: relative;
	background-color: $grey-lighter;

	.footer-top-outer{
		padding: 70px 0;

		@media (max-width: $b3){
			padding: 70px 0 20px;
		}

		h5{
			color: $green;
			font-weight: 700;
			margin-bottom: 1em;
		}
	}

	.btn{
		border-color: $green;
		background-color: $green;
//		padding: 13px 53px;
//		font-size: 22.5px!important;
//		font-size: 2.25rem!important;
		color: white!important;

		@media (max-width: $b3){
			bottom: 0px;
			font-size: 16px!important;
			font-size: 1.6rem!important;
			padding: 13px 15px;
			margin-bottom: 1em;
		}
	}

	.col-lg-6{

		h3{
			color: $blue;
			font-weight: 700;
		}
	}

	.col-lg-6{

		@media (max-width: $b2){
			margin-bottom: 10px;
		}
	}

	ul.footer-menu{
		margin: 0;
		padding: 0;
		@include font-size(0);
		display: flex;
		flex-direction: column;

		@media (max-width: $b3){
			padding-bottom: 20px;
		}

//		@media (min-width: $b2 + 1){
//			flex-grow: 1;
//			justify-content: flex-end;
//		}
//
//		@media (max-width: $b2){
//			justify-content: center;
//			padding-top: 10px;
//		}

		li{
			@include font-size(15);
			line-height: 1em;
			text-align: left;
			margin-bottom: .4em;

			> a{
				@include font-size(15);
				width: auto;
				color: $colour;
				line-height: 1.25em;
				font-weight: 500;
				position: relative;
				font-family: $main-font;
				display: block;
				text-decoration: none;

				@media (max-width: $b3){
				}

				@media (min-width: 1024px + 1){

					&:hover,
					&:focus{
						color: $green;
					}
				}
			}
		}
	}

	.footer-bottom-outer{
		padding: 90px 0 61px;

		@media (max-width: $b3){
			padding: 0 0 20px;
		}

		.row{
			align-items: center;
		}

		.col-md-6{
			display: flex;
			align-items: center;

			@media (min-width: $b3 + 1){
				padding-left: 10px;
			}

			@media (max-width: $b3){
				flex-direction: column;
				align-items: flex-start;
			}
		}

		.footer-logo{

			img{

				@media (min-width: 2132px + 1){
					width: 38.72px * 1.1109;
				}

				@media (min-width: 2346px + 1){
					width: 38.72px * 1.2218;
				}

				@media (min-width: 2558px + 1){
					width: 38.72px * 1.3328;
				}
			}

			@media (max-width: $b3){
				margin-bottom: 20px;
			}
		}

		.col-md-2 + .col-md-2{
			text-align: right;

			@media (max-width: $b3){
				padding: 10px 15px 0;
				text-align: left;
			}

			.by{
				text-align: right;
				@include font-size(12);
				color: $colour;
				text-decoration: none;
				font-weight: 500;

				@media (max-width: $b2){
					@include font-size(10);
				}

				&:hover,
				&:focus{
					color: $green;
				}
			}
		}

		.social-menu{

			@media (max-width: $b3){
				margin-bottom: 13px;
				justify-content: flex-start;
			}

			a{
				&:hover,
				&:focus{
					color: $blue;
				}
			}
		}

		.copy-menu{
			display: flex;
			align-items: center;
			margin-bottom: 0;

			@media (max-width: $b3){
				margin-bottom: 20px;
			}

			li{
				margin-bottom: 0;
				@include font-size(12);
				margin: 0 6.5px;

				a{
					color: $colour;
					@include font-size(12);
					font-weight: 500;
					text-decoration: none;

					@media (max-width: $b2){
						@include font-size(10);
					}

					&:hover,
					&:focus{
						color: $green;
					}
				}
			}
		}

		.copy{
			@include font-size(12);
			color: $colour;
			font-weight: 500;
			padding-left: 12px;
			padding-right: 9px;

			@media (max-width: $b2){
				padding-left: 12px;
				padding-right: 9px;
				@include font-size(10);
			}

			@media (max-width: $b3){
				padding-left: 0px;
				padding-right: 0px;
				margin-bottom: 20px;
			}
		}
	}
}

.main-wrapper{

  .product-section:first-of-type{
    background-image: url(../images/strategic.svg);
    background-repeat: no-repeat;
    background-position: 55% 89%;

    &.colour-grey{
      background-image: url(../images/strategic-dark.svg);
    }

    .text-side{
      padding-top: 200px;

      @media (max-width: $b2){
        padding-top: 0;
      }
    }
  }
}

.grey-form-section + .product-section.colour-pink{
  background-image: url(../images/strategic.svg);
  background-repeat: no-repeat;
  background-position: 55% 89%;
}

.product-section{
  position: relative;
  overflow: hidden;

  &.colour-blue.colour-blue.colour-blue{

    @media (max-width: $b3){
      &:before{
        background-image: url(../images/Blue_background_DNA_pattern_reduced_height.svg);
        height: 85.6px;
        background-size: auto 85.6px;
      }
    }
  }

  &.colour-pink.colour-pink.colour-pink{

    @media (max-width: $b3){
      &:before{
        background-image: url(../images/Pink_background_DNA_pattern_reduced_height.svg);
        height: 85.6px;
        background-size: auto 85.6px;
      }
    }
  }

  &.colour-grey.colour-grey.colour-grey{

    @media (max-width: $b3){
      &:before{
        background-image: url(../images/Grey_background_DNA_pattern_reduced_height.svg);
        height: 85.6px;
        background-size: auto 85.6px;
      }
    }
  }

  &:not(.featured){

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 313.65px;
      background-size: auto 313.65px;
      background-position: 50% 0;
      background-repeat: repeat-x;
      background-image: url(../images/dna-grey.svg);

      height: 32.1%;
      max-height: 313.65px;
      background-size: auto 100%;

      @media (max-width: 1500px){
        height: 29%;
      }

      //			@media (max-width: 1300px){
      //				height: 156px;
      //				background-size: auto 156px;
      //			}
      @media (max-width: $b3){
        height: 86.3px;
        background-size: auto 86.3px;
      }

      @media (max-width: $b4){
        height: 86.3px;
        background-size: auto 86.3px;
      }

    }
  }

  &.featured{

    .text-side.text-side{
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .date{
      @include font-size(15);
      display: flex;
      flex-wrap: wrap;

      div{

        &:after{
          content: ', ';
          margin-right: 0.25em;
        }

        &:last-of-type{

          &:after{
            content: ' | ';
            margin-right: 0.25em;
          }
        }
      }
    }

    .featured{
      color: $pink;
      font-weight: 600;
      letter-spacing: 0.15em;
      margin-bottom: 1.3em;
    }

    h3{
      line-height: 1.05em;

      @media (min-width: 1500px + 1){
        @include font-size(40);
        padding-right: 90px;
      }
    }

    .btn.trans{
      background-color: transparent;
      color: white!important;
      padding: 5px 10px;
      min-width: 161px;

      &:hover,
      &:focus{
        background-color: $green;
        border-color: $green;
        color: $colour!important;

      }
    }
  }

  &.waypoint{
    transform: none;
    opacity: 1;
    visibility: visible;

    .title-con{

      div{

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4){
          transform: translateY(-114%);
          opacity: 0;
          visibility: hidden;
        }

        &:nth-of-type(3){
          transform: translateY(-290%);
        }

        &:nth-of-type(4){
          transform: translateY(-534%);
        }
      }
    }

    img{
      transform: translateY(100%) translateX(-50%);

      @media (max-width: $b3){
        transform: translateY(100%);
      }
    }

    &.animate{

      .title-con{

        div{

          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4){
            transform: translateY(0%);
            transition: .4s .4s;
            opacity: 1;
            visibility: visible;
          }
        }
      }

      img{
        transform: translateY(0%) translateX(-50%);
        transition: .4s .4s;

        @media (max-width: $b3){
          transform: translateY(0%);
        }
      }
    }
  }

  .row{
    min-height: 100vh;
    //min-height: -webkit-fill-available;
    padding-top: 160px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 100vh;
    }

    @media (max-width: $b3){
      padding-top: 85px;
    }
  }

  &.colour-blue{
    background-color: $blue;

    .btn{

      &:hover,
      &:focus{
        background-color: #282ca4;
        border-color: #282ca4;
      }
    }

    .title-con.title-con{
      margin-bottom: 2em;
      color: #292929;

      div{

        &:nth-of-type(2){
          //opacity: .55;
          color: #2704BB;
        }

        &:nth-of-type(3){
          //opacity: .55;
          color: #2704BB;
        }

        &:nth-of-type(4){
          //opacity: .55;
          color: #2704BB;
        }
      }
    }
  }

  &.colour-grey{
    background-color: $grey;

    .title-con.title-con{
      margin-bottom: 2em;
      color: #292929;

      div{

        &:nth-of-type(2){
          opacity: .55;
          color: #292929;
        }

        &:nth-of-type(3){
          opacity: .55;
          color: #292929;
        }

        &:nth-of-type(4){
          opacity: .55;
          color: #292929;
        }
      }
    }
  }

  &.colour-pink{
    background-color: $pink;
    background-color: #ff255e;
    background-color: #FF4563;

    .title-con.title-con{

      //I was #af3f4d, now it is #be334a

      div{

        &:nth-of-type(2){
          opacity: 1!important;
          color: #af3f4d;
          color: #be334a;
        }

        &:nth-of-type(3){
          opacity: 1!important;
          color: #af3f4d;
          color: #be334a;
        }

        &:nth-of-type(4){
          opacity: 1!important;
          color: #af3f4d;
          color: #be334a;
        }
      }
    }

    .btn{
      background-color: $blue;
      border-color: $blue;
      color: white!important;

      &:hover,
      &:focus{
        background-color: #be334a;
        border-color: #be334a;
        color: $white!important;
      }
    }

    .title-con.title-con{
      margin-bottom: 2em;

      div{

        &:nth-of-type(2){
          opacity: .55;
        }

        &:nth-of-type(3){
          opacity: .55;
        }

        &:nth-of-type(4){
          opacity: .55;
        }
      }
    }
  }

  &.colour-gradient-pink-blue {
    background:$pink url(../images/gradient-pink-to-blue.jpg) no-repeat center center;
    background-size:cover;
    .title-con.title-con{
      margin-bottom: 2em;
      color: #292929;

      div{

        &:nth-of-type(2){
          opacity: .55;
          color: #b0296b;
        }

        &:nth-of-type(3){
          opacity: .55;
          color: #b0296b;
        }

        &:nth-of-type(4){
          opacity: .55;
          color: #b0296b;
        }
      }
    }
    .btn {
      background:$pink;
      color:white!important;
      border-color:$pink;
    }
  }

  &.side-right{

    .text-side{

      @media (min-width: $b3 + 1){
        order: 2;
      }
    }

    .image-side{
      position: relative;

      @media (min-width: $b3 + 1){
        order: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }

      img{
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: 50%;
        z-index: 3;
        width: 148%;
        max-width: 960px;

        @media (min-width: 1920px + 1){
          width: 175%;
          max-width: 1150px;
        }

        @media (min-width: 2132px + 1){
          max-width: 1150px * 1.1109;
        }

        @media (min-width: 2346px + 1){
          max-width: 1150px * 1.2218;
        }

        @media (min-width: 2558px + 1){
          max-width: 1150px * 1.3328;
        }

        @media (min-width: $b3 + 1){
          left: 0;
        }

        img{
          margin: 0 auto;
        }

        @media (max-width: 1500px){
          width: 128%;
          left: 30%;
        }

        @media (max-width: $b3){
          width: 100%;
        }

        @media (max-width: $b3){
          //max-height: 220px;
          max-width: 100%;
          position: relative;
          position: static;
          margin: 0 auto;
        }
      }
    }
  }

  &.side-left{

    .image-side{
      position: relative;

      @media (max-width: $b3){
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }

      img{
        position: absolute;
        left: 50%;
        bottom: 0;
        max-width: 50%;
        z-index: 3;
        width: 148%;
        max-width: 960px;

        @media (min-width: 1920px + 1){
          width: 175%;
          max-width: 1150px;
        }

        @media (min-width: 2132px + 1){
          max-width: 1150px * 1.1109;
        }
        //
        //				@media (min-width: 2346px + 1){
        //					max-width: 1150px * 1.2218;
        //				}
        //
        //				@media (min-width: 2558px + 1){
        //					max-width: 1150px * 1.3328;
        //				}

        @media (min-width: $b3 + 1){
          left: 70%;
        }

        img{
          margin: 0 auto;
        }

        @media (max-width: 1500px){
          max-width: 128%;
          max-height: calc(100vh - 120px);
          width: auto;
        }

        @media (max-width: $b3){
          width: 100%;
          max-height: none;
        }

        @media (max-width: $b3){
          //max-height: 220px;
          max-width: 80%;
          max-width: 100%;
          position: static;
          margin: 15px auto 0;
        }
      }
    }
  }

  .image-side{
    position: static;

    @media (max-width: $b3){
      align-self: flex-end;
    }

    img{
      position: absolute;
      bottom: 0;
      max-width: 50%;

      @media (max-width: $b3){
        max-width: 50%;
        position: relative;
        margin-top: 2em;
      }
    }
  }

  .text-side{
    padding-top: 2em;
    padding-bottom: 2em;
    align-self: center;

    @media (max-width: $b3){
      padding-top: 0;
    }
  }

  h4{
    line-height: 1.15em;
  }

  .btn{
    margin-top: 2em;
  }

  .title-con{
    margin-bottom: 2em;

    div{
      @include font-size(100);
      font-family: $alt-font;
      font-weight: 700;
      line-height: .75em;
      line-height: 1em;
      overflow: hidden;

      @media (max-width: $b2){
        @include font-size(69);
      }

      @media (max-width: $b3){
        @include font-size(65/$divider-big);
      }

      &:nth-of-type(1){
        z-index: 2;
        position: relative;
      }

      &:nth-of-type(2){
        max-height: 33px;
        margin-top: .045em;
        opacity: .35;
        display: flex;
        align-items: flex-end;
        color: #444444;
        //mix-blend-mode: hard-light;

        @media (max-width: $b2){
          max-height: 22px;
        }

        @media (max-width: $b3){
          max-height: 16px;
        }
      }

      &:nth-of-type(3){
        max-height: 23px;
        margin-top: .06em;
        opacity: .35;
        display: flex;
        align-items: flex-end;
        color: #444444;
        //mix-blend-mode: hard-light;

        @media (max-width: $b2){
          max-height: 16px;
          margin-top: .01em;
        }

        @media (max-width: $b3){
          max-height: 13px;
          margin-top: .01em;
        }
      }

      &:nth-of-type(4){
        max-height: 18px;
        margin-top: 0.12em;
        opacity: .35;
        display: flex;
        align-items: flex-end;
        color: #444444;
        //mix-blend-mode: hard-light;

        @media (max-width: $b2){
          max-height: 14px;
          margin-top: .001em;
        }

        @media (max-width: $b3){
          max-height: 10px;
          margin-top: .001em;
        }
      }
    }
  }

  *{
    color: white;
  }
}
//!!BUTTON
button:focus,
.btn:focus{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -5px;
	max-width: calc(100% + 6px);

	.btn{
		margin: 0 5px 5px;
	}
}

.btn{
	@include inline;
	padding: 10px 33px;
	font-size: 15px!important;
	font-size: 1.5rem!important;
	text-decoration: none!important;
	line-height: 1.5em;
	border: none;
	background-color: $green;
	color: $colour!important;
	font-weight: 700;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	font-family: $alt-font;
	position: relative;
	border-radius: 30px;
	border: 1px solid $green;
	transition: $time;
	min-width: 197px;
	letter-spacing: .03em;

	@media (max-width: $b2){
		padding: 8px 15px;
		min-width: 150px;
	}

	@media (min-width: 1920px + 1){
		border-radius: 3.0rem;
		min-width: 19.7rem;
		border-width: .1rem;
		padding: 1.0rem 3.3rem;
	}

	&:hover,
	&:focus{
		outline: none;
		background-color: $blue;
		border-color: $blue;
		color: $white!important;
	}

	&.blue{
		background-color: $blue;
		border-color: $blue;
		color: $white!important;

		&:hover,
		&:focus{
			background-color: $green;
			border-color: $green;
			color: $colour!important;
		}
	}

	&.big{
		padding: 34.5px 8px;
		font-size: 24px!important;
		font-size: 2.4rem!important;

		@media (max-width: 1250px){
			padding: 17px 8px;
			font-size: 20px!important;
			font-size: 2.0rem!important;
		}

		@media (max-width: $b3){
			padding: 14.5px 8px;
			font-size: 15px!important;
			font-size: 1.5rem!important;
		}
	}

	&.small{
		min-width: 123px;
		padding: 12px 10px;
		font-size: 13px!important;
		font-size: 1.3rem!important;
	}
}

//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'main-menu';
@import 'footer';
@import 'general-overights'; /* stuff like float-left, clear-right, align-center */
@import 'buttons';
@import 'forms';
@import 'slick';
@import 'slick-theme';
@import 'fancybox';
@import 'grey-alt-text-with-image-section';
@import 'pink-text-with-icon-section';
@import 'product-section';
@import 'text-with-under-image';
@import 'text-with-video';
//@import 'tabs';

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

.matchHeight{
	margin-bottom: 0;
}

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0px;
	width: 100%;
	min-height: calc(100vh - 182px - 158px);

	@media (max-width: $b2){
		min-height: calc(100vh - 110.81px - 178px);
	}

	@media (max-width: $b3){
		min-height: calc(100vh - 50.19px - 322px);
	}

	&.has-hero{
		padding-top: 100vh;

//		@media (max-width: $b3){
//			padding-top: 65vh;
//		}
	}
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
	bottom: 0;

//	.menu-open &{
//		transform:  translateX(-372px);
//		transition: .25s .1.5s;
//	}
}

.fp-tableCell,
.wrapper{
	margin: 0 auto;
	width: 95.834%;
	padding-bottom: 0;
	max-width: $base-width;
	position: relative;
	clear: both;

	@media (max-width: $b2){
		width: 87%;
	}

	&.mob-wide{

		@media (max-width: $b3){
			width: 100%;
		}
	}
}

.mob-wrapper{

	@media (max-width: $b3){
		margin-left: auto;
		margin-right: auto;
		width: 81%;
		padding-bottom: 0;
		max-width: $base-width;
		position: relative;
		clear: both;
		float: none;
	}
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

hr{
	border: 0;
	width: 230px;
	max-width: 90%;
	height: 1px;
	margin: 0 auto 1.9em;
	position: relative;
	background-color: $colour;
	max-width: 20%;
}

.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

//.breadcrumb-con{
//	display: block;
//	font-family: $alt-font;
//	@include font-size(16);
//	margin-bottom: 74px;
//	color: $colour;
//	font-weight: 700;
//
//	@media (max-width: $b2){
//		margin-bottom: 25px;
//		@include font-size(16 / $divider);
//	}
//
//	a{
//		@include font-size(16);
//		text-decoration: none;
//		@include inline;
//		vertical-align: middle;
//		color: $colour;
//		font-weight: 700;
//		margin-right: 9px;
//		margin-left: 4px;
//
//		@media (max-width: $b2){
//			@include font-size(16 / $divider);
//		}
//
//		&:hover,
//		&:focus{
//			color: $green;
//			text-decoration: underline;
//		}
//	}
//
//	> a{
//		margin-left: 0;
//	}
//
//	.breadcrumb_last{
//		@include font-size(16);
//		color: $green;
//		text-decoration: none;
//		@include inline;
//		vertical-align: middle;
//		font-weight: 700;
//		margin-left: 4px;
//		position: relative;
//
//		&:before{
//			content: '>';
//			position: absolute;
//			//top: 50%;
//			left: -18px;
//			transform: translateY(-6%);
//			z-index: 1;
//			height: 100%;
//			width: 10px;
//			background-color: white;
//		}
//
//		@media (max-width: $b2){
//			@include font-size(16 / $divider);
//		}
//	}
//}

//
//!! BREADCRUM END
//

//.pagination-simple{
//	padding: 7px 0 2px;
//
//	a{
//		color: $blue;
//		font-weight: 700;
//		padding-top: 1px;
//		@include inline;
//		@include font-size(20);
//		@include line-height(20,33);
//		letter-spacing: .03em;
//		margin-bottom: 1em;
//		text-transform: uppercase;
//
//		@media (max-width: $b2){
//			@include font-size(20 / $divider);
//		}
//
//		&:hover,
//		&:focus{
//			color: $blue;
//		}
//
//		&.back-to,
//		&[rel="next"]{
//			float: left;
//		}
//
//		&[rel="prev"]{
//			float: right;
//		}
//	}
//}

//simple pagination

//complex pagination

//.pagination-con{
//	display: flex;
//	justify-content: space-between;
//
//	@media (max-width: $b2){
//		justify-content: center;
//		flex-direction: column;
//		align-items: center;
//
//		.pagination.pagination.pagination{
//			margin-bottom: 20px;
//		}
//	}
//}
//
//.pagination-under{
//
//	.col-12{
//		display: flex;
//		justify-content: center;
//		align-items: center;
//
//		.pagination.pagination{
//			margin-bottom: 50px;
//		}
//	}
//}
//

.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	padding-top: 0;
	padding-bottom: 0;
	display: flex;
	justify-content: center;

	li{
		list-style: none;
		@include inline;
		vertical-align: middle;
		padding: 0 0;
		color: $grey;
		@include font-size(18);
		font-weight: 600;
		line-height: 1em;
		overflow: hidden;
		text-align: center;
		margin: 0 4px;

		&:first-of-type{
			margin-left: 0;
		}

		&:last-of-type{
			margin-right: 0;
		}

		&.ellipse{
			line-height: 45px;
			color: $grey;
		}

		&.active{
			pointer-events: none;

			a{
				color: $green;
			}
		}

		a{
			@include font-size(18);
			display: block;
			color: $blue;
			font-weight: 600;
			padding: 0;
			min-width: 20px;
			min-height: 30px;
			line-height: 30px;

			&:hover,
			&:focus{
				text-decoration: none;
				color: $green;
			}
		}

		&.next,
		&.prev{

			a{
				@include font-size(0);
				text-decoration: none;
				width: 20px;
				height: 30px;
				line-height: 0;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				transition: $time, background-size 0s 0s;
				animation: none;
				animation-duration: 1.5s;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
				animation-play-state: running;

				&:hover,
				&:focus{
					text-decoration: none;
				}
			}
		}

		&.next{

			a{
				background-image: url(../images/arrow-right-blue.svg);

				&:hover,
				&:focus{
					background-image: url(../images/arrow-right-green.svg);
				}
			}
		}

		&.prev{

			a{
				background-image: url(../images/arrow-left-blue.svg);

				&:hover,
				&:focus{
					background-image: url(../images/arrow-left-green.svg);
				}
			}
		}
	}
}

//
//!!SOCIAL MENU START
//
$social-size:		20;
$social-addition:	10;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$rss:			#ff6600;
$slideshare:	#f7941e;
$google-plus:	#dd4b39;
$instagram:		#8a3ab9;
$reddit:		#ff4500;
$youtube:		#bb0000;
$mail:			#767676;
$behance:		#131418;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	a{
		@include font-size($social-size);
		line-height: $social-size + $social-addition + px;
		height:  $social-size + $social-addition + px;
		min-width: $social-size + $social-addition + px;
		display: block;
		text-align: center;
		color: $green;
		text-decoration: none;
		background-image: none;
		padding: 0;
		font-weight: 100;
		margin: 0 25px;
		background-color: transparent;
		border-radius: 150px;

		@media (min-width: 1920px + 1){
			line-height: 3.0rem;
			height: 3.0rem;
			min-width: 3.0rem;
			margin: 0 2.5rem;
		}

		@media (max-width: $b3){
			margin: 0 5px;
		}

		&:first-of-type{
			margin-left: 0;
		}

		&:last-of-type{
			margin-right: 0;
		}

		i{
			@include font-size($social-size);
			line-height: $social-size + $social-addition + px;
			min-width: $social-size + $social-addition + px;
			display: block;

			@media (min-width: 1920px + 1){
				line-height: 3.0rem;
				height: 3.0rem;
				min-width: 3.0rem;
			}

			@media (max-width: $b3){
				@include font-size(20);
				line-height: 40px;
				min-width: 40px;
			}
		}

		&.facebook{

			&:hover,
			&:focus{
				color: $facebook!important;
			}
		}
//
//
//		&.behance{
//
//			&:hover,
//			&:focus{
//				color: $behance!important;
//			}
//		}
//
		&.twitter{

			&:hover,
			&:focus{
				color: $twitter!important;
			}
		}

		&.linkedin{

			&:hover,
			&:focus{
				color: $linkedin!important;
			}
		}
//
//		&.pinterest{
//
//			&:hover,
//			&:focus{
//				color: $pinterest!important;
//			}
//		}
//
//		&.rss{
//
//			&:hover,
//			&:focus{
//				color: $rss!important;
//			}
//		}
//
//		&.slideshare{
//
//			&:hover,
//			&:focus{
//				color: $slideshare!important;
//			}
//		}
//
//		&.google-plus{
//
//			&:hover,
//			&:focus{
//				color: $google-plus!important;
//			}
//		}
//
		&.instagram{

			&:hover,
			&:focus{

				i{
					color: $instagram!important;
					background: -webkit-linear-gradient(45deg, rgba(255,204,101,1) 0%, rgba(218,34,108,1) 51%, rgba(73,103,211,1) 100%)!important;
					-webkit-background-clip: text!important;
					-webkit-text-fill-color: transparent!important;
				}
			}
		}
//
//		&.reddit{
//
//			&:hover,
//			&:focus{
//				color: $reddit!important;
//			}
//		}
//
//		&.youtube{
//
//			&:hover,
//			&:focus{
//				color: $youtube!important;
//			}
//		}
//
//		&.mail{
//
//			&:hover,
//			&:focus{
//				color: $mail!important;
//			}
//		}
	}
}

//
//!!SOCIAL MENU END
//

.waypoint{
	transition: .75s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(50px);
	will-change: transform;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

a{

	.hidden{
		height: 0;
		width: 0;
		overflow: hidden;
		display: block;
		opacity: 0;
		visibility: visible;
	}
}

//
//!!AJAX LOADING
//

@media (min-width: $b3 + 1){

	.has-custome-mouse{

		*{
			cursor: none!important;
		}

		$line: .29px;
		$lineR: .15px;

		#mouse{
			position: fixed;
			z-index: 99999999999;
			width: 19px;
			height: 19px;
			border-radius: 19px;
			border: 4px solid $green;
			transform: translate(-50%, -50%);
			pointer-events: none;
			filter: drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white) drop-shadow(0 0 $line white);

			@media (min-resolution: 120dpi){
				filter: drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white) drop-shadow(0 0 $lineR white);
			}

			&.hide{
				opacity: 0;
			}

			&.hovered{
				transform: translate(-50%, -50%);
				width: 19px;
				height: 19px;
				border-radius: 19px;
				border: 4px solid $blue;
			}
		}
	}
}

html{

	&.loaded{

		body{

			&:before{
				transform: rotate(-10deg) translate3d(150%,-50%,0);
				transition: 1s 1s;
			}

			&:after{
				opacity: 0;
				visibility: hidden;
				transition: .5s .5s;
			}

		}
	}

	&.loaded-done{

		body{

			&:before{
				transition: .5s 0s;
			}
		}
	}

	&.ajax-leave{

		body{

			&:before{
				transform: rotate(-10deg) translate3d(-50%,-50%,0);
				transition: .5s 0s;
			}
		}
	}

	&.ajax-enter{

		body{

			&:before{
				transform: rotate(-10deg) translate3d(-50%,-50%,0);
				transition: .5s 0s;
			}
		}
	}

	&.ajax-leave.ajax-enter{

		body{

			&:before{
				//
				//transition: 0s;
				//transform: rotate(-10deg) translate3d(-50%,-50%,0);
			}
		}
	}
}

//
//!!PAGE SPECIFIC
//

.hero-slide{
	max-height: 100vh;

	&.loaded {
		max-height: 5000px;
	}
}

//.section{
//
//	&.fade-up .fp-tableCell{
//		opacity: 0;
//		transform: translateY(50px);
//		transition: $time;
//	}
//
//	&.active{
//
//		&.fade-up .fp-tableCell{
//			opacity: 1;
//			transform: translateY(0);
//			transition: .5s .5s;
//		}
//	}
//}

//html.loaded{
//
//	.hero-section{
//
//		.slick-slide{
//
//			&.slick-active{
//
//				&.text-side{
//					opacity: 0;
//					transform: translateY(50px);
//					transition: .1s 0s;
//				}
//
//				.img-side .img-con:after{
//					transform: translate(17%, 120%);
//					transition: .1s 0s;
//				}
//			}
//		}
//	}
//}

html.loaded.loaded-done{

	.hero-section{

		.slick-slide{

			&.slick-current{

				.text-side{
					opacity: 1;
					transform: translateY(0);
					transition: .4s .4s;
				}

				.img-side .img-con{
					transform: translateY(0) translateX(-50%);
					transition: .4s .4s;
				}
			}
		}
	}
}


.hero-section{
	overflow: hidden;

	.container.container{
		max-width: 1216px;

		@media (min-width: $b3 + 1){

			.col-md-6{
				max-width: 50%;
			}
		}
	}

	.slick-slide{
		overflow: hidden;
		position: relative;

		.text-side{
			opacity: 0;
			transform: translateY(50px);
			transition: .2s .4s;
		}

		.img-side .img-con{
			transform: translateY(100%) translateX(-50%);
			transition: .2s .4s;
		}
	}

	.text-side{
		padding-bottom: 10px;
	}

	.slick-track{
		display: flex;

		.slick-slide{
			float: none;
			height: auto;

			.container{
				height: 100%;

				.row{
					height: 100%;
				}
			}
		}
	}

	.slick-dots{

		li{

			&:first-of-type:last-of-type{
				display: none;
			}
		}
	}

	[data-colour="green"]{

//		.img-con{
//			background-color: $green;
//		}
//
//		svg{
//
//			path{
//				fill: $green;
//			}
//		}
	}

	[data-colour="blue"]{

		&[tabindex="0"]{

			h3{
				margin-bottom: 0;
			}

			h2{
				color: $blue;
				@include font-size(55);

				@media (max-width: 1500px){
					@include font-size(55);
				}

				@media (max-width: $b2){
					@include font-size(45/$divider);
				}
			}
		}
	}

	[data-colour="pink"]{

		h3{
			margin-bottom: 0;
		}

		h2{
			color: $blue;
		}

		.strategic{
			position: absolute;
			left: 50%;
			top: 64%;
			transform: translateX(-50%);
			z-index: 4;

			@media (max-width: $b2){
				display: none;
			}
		}
	}

	.text{
		//padding-bottom: 125px;
		position: relative;
		z-index: 1;

		@media (max-width: $b3){
			padding-bottom: 10px;
		}
	}

	h1{
		@include font-size(85);

		@media (max-width: 1500px){
			@include font-size(75);
		}

		@media (max-width: $b2){
			@include font-size(65);
		}

		@media (max-width: $b3){
			@include font-size(65/$divider-big);
		}
	}

	h2{
		@include font-size(50);

		@media (max-width: 1500px){
			@include font-size(50);
		}

		@media (max-width: $b2){
			@include font-size(45);
		}

		@media (max-width: $b3){
			@include font-size(45/$divider);
		}
	}

	h3{
		@include font-size(45);

		@media (max-width: 1500px){
			@include font-size(45);
		}

		@media (max-width: $b2){
			@include font-size(45);
		}

		@media (max-width: $b3){
			@include font-size(40/$divider);
		}
	}

	h4{
		@include font-size(25);

		@media (max-width: $b2){
			@include font-size(25/$divider);
		}
	}

	h5{
		@include font-size(22);

		@media (max-width: $b2){
			@include font-size(22/$divider);
		}
	}

	.news{

		.date{
			@include font-size(15);
			display: flex;
			flex-wrap: wrap;

			div{

				&:after{
					content: ', ';
					margin-right: 0.25em;
				}

				&:last-of-type{

					&:after{
						content: ' | ';
						margin-right: 0.25em;
					}
				}
			}
		}

		.btn.trans{
			background-color: transparent;
			color: $colour!important;

			&:hover,
			&:focus{
				background-color: $green;
				border-color: $green;
				color: $colour!important;

			}
		}
	}

	.row{
		padding-top: 130px;
		align-items: center;
		min-height: 100vh;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}

		@media (max-width: $b3){
			padding-top: 80px;
		}
	}

	.img-side{
		position: relative;
		align-self: stretch;
		display: flex;

		@media (max-width: $b3){
			align-items: flex-end;
		}

		.img-con{
			position: absolute;
			left: 50%;
			bottom: 0;
			max-width: 50%;
			z-index: 3;
			width: 148%;
			max-width: 960px;


			@media (min-width: $b3 + 1){

				img{
					max-height: calc(100vh - 120px);
				}
			}

			@media (min-width: 1920px + 1){
				width: 175%;
				max-width: 1150px;
			}

			@media (min-width: 2132px + 1){
				width: 175% * 1.1109;
				max-width: 1150px * 1.1109;

				img{
					width: 85%;
				}
			}

			@media (min-width: 2346px + 1){
				width: 175% * 1.2218;
				max-width: 1150px * 1.2218;
			}

			@media (min-width: 2558px + 1){
				width: 175% * 1.3328;
				max-width: 1150px * 1.3328;
			}

			@media (min-width: $b3 + 1){
				left: 70%;
			}

			img{
				margin: 0 auto;
			}

			@media (max-width: 1500px){
				width: 128%;
			}

			@media (max-width: 1500px){


				@media (min-width: $b3 + 1){
					max-height: 84vh;
				}
			}

			@media (max-width: $b3){
				width: 100%;
			}

			@media (max-width: $b3){
				//max-height: 220px;
				max-width: 100%;
				position: relative;
			}
		}
	}

//	.img-con.img-con{
//		position: absolute;
//		width: 151%;
//		max-width: 151%;
//		padding-top: 151%;
//		border-radius: 50%;
//		bottom: -261px;
//		left: -121px;
//
//
//		@media (max-width: $b2){
//			bottom: -150px;
//		}
//
//		@media (max-width: $b3){
//			position: relative;
//			padding-top: 0;
//			left: 0;
//			bottom: 0;
//			width: 100%;
//			max-width: 100%;
//
//		}
//
//		img{
//			position: absolute;
//			//bottom: 261px;
//			top: -58px;
//			left: 102px;
//			z-index: 1;
//
//			@media (max-width: $b3){
//				position: static;
//				margin: 0 auto;
//			}
//		}
//
//		svg{
//			position: absolute;
//			bottom: 261px;
//			left: 102px;
//			z-index: 2;
//
//			@media (max-width: $b2){
//				bottom: 0;
//				display: none;
//			}
//		}
//	}

	h1{
		color: $blue;
		margin-bottom: 0em;
	}

	h2{
		color: $pink;
	}

	.btn{
		margin-top: 1.6em;
	}
}

.pink-text-with-image-section{
	background-color: $pink;
	background-image: url(../images/strategic.svg);
	background-repeat: no-repeat;
	background-position: 50% 77%;
	overflow: hidden;

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		.top{
			transform: translateY(-100%);
			pointer-events: none;
			opacity: 0;
			visibility: hidden;

			@media (max-width: $b3){
				opacity: 0;
				visibility: hidden;
				left: 50%;
				transform: translateX(-50%) translateY(-100%);
			}
		}

		.lower{
			transform: translateY(100%);
		}

		&.animate{

			.top,
			.lower{
				transform: translateY(0%);
				transition: .4s .4s;
			}

			.top{
				opacity: 1;
				visibility: visible;

				@media (max-width: $b3){
					opacity: 1;
					visibility: visible;
					transform: translateX(-50%) translateY(0%);
				}
			}
		}
	}

	.row{
		padding-top: 100px;
		align-items: center;
		min-height: 100vh;

		@media (max-width: 1300px){

			@media (min-width: $b3 + 1){
				padding-top: 145px;
			}
		}

		@media (max-width: $b3){
			padding-top: 100px;

			@media (max-height: 500px){
				padding-top: 75px;
			}
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}
	}

	.img-side{
		align-self: flex-end;

		@media (max-width: 1333px){

			@media (min-width: $b3 + 1){

				img{
					max-width: 335px;
				}
			}
		}

		@media (max-width: $b3){
			order: 2;

			img{
				margin: 0 auto;

			}
		}

//		@media (max-width: 1500px){
//			img{
//				width: 75%;
//			}
//		}
//
//		@media (max-width: 1400px){
//			img{
//				width: 57%;
//			}
//		}
//
//		@media (max-width: $b3 + 10px){
//
//			img{
//				width: 100%;
//			}
//		}
//
//		@media (max-width: 1333px){
//
//			@media (min-width: $b3 + 1){
//
//				img{
//					max-width: 335px;
//				}
//			}
//		}
//
//		@media (max-width: $b3){
//			order: 2;
//
//			img{
//				margin: 0 auto;
//			}
//		}
//
//		@media (min-width: 1920px + 1){
//
//			img{
//				width: 86.111%;
//			}
//		}
	}

	*{
		color: white;
	}

	.img-side{

		@media (min-width: $b3 + 1){
			padding-left: 76px;
		}

		@media (min-width: 1920px + 1){

			img{
				width: 86.111%;
			}
		}

		img{

		}
	}

	h5{

		@media (max-width: 1500px){
			margin-bottom: .85em;
		}

	}

	.img-con{
		position: relative;

		.top{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			@media (max-width: 900px){
				max-width: 100%;
			}

			@media (max-width: $b3){
				left: 50%;
			}
		}

		.lower{
			position: relative;
			z-index: 2;

			@media (max-width: 900px){
				max-width: 100%;
			}
		}
	}

	.text-side{
		padding-bottom: 50px;

		@media (min-width: $b3 + 1){
			padding-left: 10px;
			padding-top: 0px;
		}
	}

	.text{

		@media (max-width: $b3){
			margin-bottom: 0;
		}
	}

	.text-column{

		@media (min-width: $b2 + 1){
			column-count: 2;
			column-gap: 26px;

//			flex-wrap: wrap;
//			display: flex;
////			column-count: 0;
//			justify-content: space-between;
//
//			p{
//				width: calc(50% - 7px);
//			}

			ul{
				break-inside: avoid;
				break-inside: avoid-column;

				li{
					break-inside: avoid;
				}
			}
		}

		p{
			break-inside: avoid;
		}
	}

	.btn-con{
		padding-top: 52px;

		@media (max-width: $b3){
			padding-top: 25px;
		}
	}
}

.grey-text-with-image-section{
	background-color: $grey;
	overflow: hidden;
	position: relative;

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		.back{
			opacity: 0;
			visibility: hidden;
		}

		.lower{
			transform: translateY(-100%) translateX(-50%);
			opacity: 0;
			visibility: hidden;

			@media (max-width: $b3){
				transform: translateY(-100%);
			}
		}

		&.animate{

			.back{
				opacity: 1;
				visibility: visible;
				transition: .4s .4s;
			}

			.lower{
				transform: translateY(0%) translateX(-50%);
				opacity: 1;
				visibility: visible;
				transition: .4s .4s;

				@media (max-width: $b3){
					transform: translateY(0%);
				}
			}
		}
	}

	.text-column{

		@media (min-width: $b2 + 1){
			column-count: 2;
			column-gap: 10px;

			ul{
				break-inside: avoid;
				break-inside: avoid-column;

				li{
					break-inside: avoid;
				}
			}
		}

		h6{
			break-after: avoid;
		}

		p{
			break-inside: avoid;
		}
	}

	.text-side{
		position: relative;
		z-index: 112;
		padding-bottom: 60px;

		@media (min-width: $b3 + 1){
			padding-right: 10px;
			padding-bottom: 40px;
		}

		*{
			color: white;
		}

		h2{
			margin-bottom: 0.75em;
		}

		h4{
			line-height: 1.15em;
			letter-spacing: .01em;
			margin-bottom: .6em;
		}
	}

	.row{
		padding-top: 180px;
		align-items: center;
		min-height: 100vh;
		//min-height: 904px;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}

		@media (max-width: $b3){
			padding-top: 100px;
		}
	}

	.img-side{
		position: relative;
		align-self: stretch;

		img{
			position: absolute;
			left: 50%;
			bottom: 0;
			z-index: -1;
			transform: translateX(-50%);
			max-width: 801px;
			z-index: 111;

			@media (min-width: 2132px + 1){
				max-width: 801px * 1.1109;
				width: 131%;
			}

			@media (min-width: 2346px + 1){
				max-width: 801px  * 1.2218;
			}

			@media (min-width: 2558px + 1){
				max-width: 801px  * 1.3328;
			}

			@media (max-width: 1333px){

				@media (min-width: 1100px + 1){
					max-width: 750px;
				}
			}

			@media (max-width: 1100px){
				max-width: 150%;
			}

			&.lower{
				margin-bottom: 186px;
				max-width: 84%;

				@media (max-width: 1333px){

					@media (min-width: 1024px + 1){
						max-width: 74%;
					}
				}

				@media (max-width: 1024px){
					max-width: 74%;
				}

				@media (max-width: $b3){
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 24%;
				}

				@media (max-height: 500px){
					max-width: 37%;
					margin-bottom: 11%;
				}
			}

			@media (max-width: $b3){
				position: relative;
				left: auto;
				max-width: 100%;

				&.back{
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 50%;

					@media (max-height: 500px){
						max-width: 58%;
					}
				}
			}
		}

	}

	.img-con.img-con{
		position: absolute;
		right: 0;
		bottom: 0;
		max-width: 50%;
		z-index: 3;

		@media (max-width: $b3){
			position: relative;
			right: -15px;
		}


//		position: absolute;
//		width: 146%;
//		max-width: 146%;
//		padding-top: 146%;
//		border-radius: 50%;
//		bottom: -221px;
//		left: 97px;
//
//		@media (max-width: $b3){
//			position: relative;
//			width: 100%;
//			max-width: 100%;
//			padding-top: 0;
//			bottom: 0;
//			left: 0;
//			background-color: transparent;
//		}

//		img{
//			position: absolute;
//			top: 71px;
//			left: -27px;
//			max-width: 510px;
//
//			@media (max-width: $b3){
//				position: static;
//				margin: 0 auto 2em;
//				max-width: 100%;
//			}
//		}

//		svg{
//			position: absolute;
//			bottom: 228px;
//			left: -192px;
//
//			@media (max-width: $b2){
//				display: none;
//			}
//
//			path{
//				fill: $grey;
//			}
//		}
	}

	h1{
		color: $blue;
		margin-bottom: 0em;
	}

	h2{
		color: $pink;
	}

	.btn{
		margin-top: 3.2em;

		@media (max-width: $b3){
			margin-top: 25px;
		}
	}

	.img-con{
		background-color: $green;
	}
}

.pink-alt-text-with-image-section{
	background-color: $pink;
	overflow: hidden;
	position: relative;

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		.back{
			opacity: 0;
			visibility: hidden;
		}

		.lower{
			transform: translateY(-100%) translateX(-59%);
			opacity: 0;
			visibility: hidden;

			@media (max-width: $b3){
				transform: translateY(-100%);
			}
		}

		&.animate{

			.back{
				opacity: 1;
				visibility: visible;
				transition: .4s .4s;
			}

			.lower{
				transform: translateY(0%) translateX(-59%);
				opacity: 1;
				visibility: visible;
				transition: .4s .4s;

				@media (max-width: $b3){
					transform: translateY(0%);
				}
			}
		}
	}

	.text-column{

		@media (min-width: $b2 + 1){
			column-count: 2;
			column-gap: 10px;

			ul{
				break-inside: avoid;
				break-inside: avoid-column;

				li{
					break-inside: avoid;
				}
			}
		}

		h6{
			break-after: avoid;
		}

		p{
			break-inside: avoid;
		}
	}

	.text-side{
		position: relative;
		z-index: 112;
		padding-bottom: 60px;

		@media (min-width: $b3 + 1){
			order: 2;
			padding-right: 10px;
			padding-bottom: 40px;
		}

		*{
			color: white;
		}

		ul{
			
			li{

				&:before{
					background-color: $green;
				}
			}
		}

		h2{
			margin-bottom: 0.75em;
		}

		h4{
			line-height: 1.15em;
			letter-spacing: .01em;
			margin-bottom: .6em;
		}
	}

	.row{
		padding-top: 180px;
		align-items: center;
		min-height: 100vh;
		//min-height: 904px;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}

		@media (max-width: $b3){
			padding-top: 100px;
		}
	}

	.img-side{
		position: relative;
		align-self: stretch;

		@media (min-width: $b3 + 1){
			order: 1;
		}

		img{
			position: absolute;
			left: 50%;
			bottom: 0;
			z-index: -1;
			transform: translateX(-50%);
			max-width: 801px;
			z-index: 111;

			@media (min-width: 2132px + 1){
				max-width: 801px * 1.1109;
				width: 131%;
			}

			@media (min-width: 2346px + 1){
				max-width: 801px  * 1.2218;
			}

			@media (min-width: 2558px + 1){
				max-width: 801px  * 1.3328;
			}

			@media (max-width: 1333px){

				@media (min-width: 1100px + 1){
					max-width: 750px;
				}
			}

			@media (max-width: 1100px){
				max-width: 150%;
			}

			&.lower{
				margin-bottom: 116px;
				max-width: 106%;

				@media (max-width: 1333px){

					@media (min-width: 1024px + 1){
						max-width: 74%;
					}
				}

				@media (max-width: 1024px){
					max-width: 74%;
				}

				@media (max-width: $b3){
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 24%;
				}

				@media (max-height: 500px){
					max-width: 37%;
					margin-bottom: 11%;
				}
			}

			@media (max-width: $b3){
				position: relative;
				left: auto;
				max-width: 100%;

				&.back{
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 50%;

					@media (max-height: 500px){
						max-width: 58%;
					}
				}
			}
		}

	}

	.img-con.img-con{
		position: absolute;
		right: 0;
		bottom: 0;
		max-width: 50%;
		z-index: 3;

		@media (max-width: $b3){
			position: relative;
			right: -15px;
		}
	}

	h1{
		color: $blue;
		margin-bottom: 0em;
	}

	h2{
		color: $pink;
	}

	.btn{
		margin-top: 3.2em;

		@media (max-width: $b3){
			margin-top: 25px;
		}
	}

	.img-con{
		background-color: $green;
	}
}




.grey-text-with-icon-section + .clients-section{
	background-color: white;
}

.clients-section{
	background-image: url(../images/strategic-125.svg);
	background-repeat: no-repeat;
	background-position: 50% 91.6%;

	&.fp-auto-heigh{
		height: auto!important;

		.fp-tableCell{
			height: auto!important;
		}
	}

	.row{
		padding-top: 134px;
		padding-bottom: 25px;

		@media (max-width: $b3){
			padding-top: 120px;
		}
	}

	.text{
		width: 740px;
		margin-bottom: 1.9em;
	}

	h2{
		color: $blue;
		margin-bottom: .5em;
	}

	h4{
		letter-spacing: .01em;
		line-height: 1.15em;
	}

	.slick-track{
		display: flex;
	}

	.client-slide{
		margin-top: 5.3em;

		@media (max-width: $b3){
			margin-top: 2.5em;
		}
	}

	.slick-slide{
		display: flex;
		justify-content: center;
		align-items: center;
		height: auto;
	}

	.clients{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		div{
			margin-bottom: -14px;
			flex: 0 0 16.666666%;
			padding: 0 15px;

			@media (max-width: $b2){
				flex: 0 0 25%;
				margin-bottom: 0;
			}

			@media (max-width: $b3){
				flex: 0 0 33.33333%;
			}

			@media (max-width: $b4){
				flex: 0 0 50%;
			}

			img{
				margin: 0 auto;
				max-width: 131px;
				width: auto;
				height: auto;
				width: 100%;

				@media (min-width: 2132px + 1){
					max-width: 131px * 1.1109;
				}

				@media (min-width: 2346px + 1){
					max-width: 131px * 1.2218;
				}

				@media (min-width: 2558px + 1){
					max-width: 131px * 1.3328;
				}
			}
		}
	}

	.container-fluid{
		padding-top: 76px;

		@media (max-width: $b3){
			padding-top: 15px;
		}

		.row{
			align-items: center;

			@media (max-width: $b3){
				justify-content: center;
			}
		}

		.col{
			padding-bottom: 15px;

			@media (min-width: $b3 + 1){
				padding-right: 15px;
				padding-left: 15px;
			}

			@media (max-width: $b3){
				flex: 0 0 33.3%;
			}

			@media (max-width: $b4){
				flex: 0 0 50%;
			}
		}

		img{
			margin: 0 auto;
		}
	}
}

.green-text-with-blocks-section{
	background-color: $green;
	background-image: url(../images/finger-print.svg);
	background-repeat: no-repeat;
	background-position: left 49.9% bottom -98px;

	@media (min-width: 1920px + 1){
		background-size: 43.5%;
	}

	@media (max-width: $b2){
		background-size: 80% auto;
	}

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		&.animate{

			.block{
				opacity: 1;
				transform: translateY(0);
				transition: .4s .4s;

				&:nth-of-type(1),
				&:nth-of-type(2),
				&:nth-of-type(3){
					transition: .4s .6s;
				}

				&:nth-of-type(4),
				&:nth-of-type(5),
				&:nth-of-type(6){
					transition: .4s .8s;
				}
			}
		}
	}

	&:not(.active){

		.block{
			opacity: 0;
			transform: translateY(75px);
		}
	}

	.container{
		max-width: 1042px;
	}

	.text-top{
		padding-bottom: 23px;

		@media (max-width: $b2){
			align-self: flex-end;
		}

		*{
			color: white;
		}

		.text{
			width: 620px;
		}
	}

	.row{
		padding-top: 196px;
		padding-bottom: 50px;
		min-height: 100vh;

		@media (max-width: $b2){
			padding-top: 120px;
		}

		@media (max-width: $b3){
			padding-top: 70px;
		}
	}

	h6{
		@include font-size(20);
		font-weight: 600;
		letter-spacing: .01em;
		margin-bottom: .3em;

		@media (max-width: $b2){
			@include font-size(20/$divider);
		}
	}

	.container-fluid{
		padding-left: 0;
		padding-right: 0;

		.row{
			padding-top: 0;
			padding-bottom: 0;
			min-height: 0;
		}
	}

	.blocks{
		align-items: baseline;
		padding-bottom: 72px;

		@media (max-width: $b2){

			@media (min-width: $b3 + 1){
				padding-left: 50px;
				padding-right: 50px;
			}
		}

		@media (min-width: $b3 + 1){
			margin-right: -20px;
			margin-left: -20px;
		}

		@media (max-width: $b3){
			padding-bottom: 0;
		}

		p{
			line-height: 1.2em;
		}

		.block{
			margin-bottom: .8em;

			@media (min-width: $b3 + 1){
				padding-right: 20px;
				padding-left: 20px;
			}

			@media (max-width: $b3){

				@media (min-width: 475px + 1){
					flex: 0 0 50%;
					max-width: 50%;
				}
			}

			@media (max-width: $b3){
				text-align: center;
			}
		}

		img{
			margin-bottom: .6em;

			@media (max-width: $b3){
				margin: 0 auto .1em;
			}
		}
	}
}

.client-slide{

	.slick-track{
		display: flex;
		align-items: center;

		.slick-slide{
			height: auto;
			float: none;
		}
	}

}

.awards-section{
	background-size: $grey-lighter;
	//background-image: url(../images/strategic.svg);
	background-repeat: no-repeat;
	background-position: 50% 79.8%;
	height: auto!important;
	overflow: hidden;

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		.a1-side,
		.a2-side{
			transform: translateY(100%);
			opacity: 0;
			visibility: hidden;
		}

		&.animate{

			.a1-side{
				transform: translateY(0);
				opacity: 1;
				visibility: visible;
				transition: .4s .4s;
			}

			.a2-side{
				transform: translateY(0);
				opacity: 1;
				visibility: visible;
				transition: .4s .6s;
			}
		}
	}

	.fp-tableCell{
		height: auto!important;
	}

	.row{
		padding-top: 158px;
		padding-bottom: 53px;

		@media (max-width: $b3){
			padding-top: 120px;
		}
	}

	.slick-slide{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.text{
		padding-bottom: 85px;

		@media (max-width: $b2){
			padding-bottom: 30px;
		}

		h4{
			letter-spacing: .01em;
			line-height: 1.12em;
		}
	}

	.text-side{

		@media (min-width: $b2 + 1){
			padding-right: 10px;
		}
	}

	.a1-side{

		@media (max-width: $b2){
			margin-bottom: 20px;
		}
	}

	.a1-side,
	.a2-side{

		img{
			width: 100%;
		}
	}

	@media (min-width: $b2 + 1){

		.a1-side,
		.a2-side{

			div{
				height: 1.2em;
				@include font-size(50);
				margin-bottom: .4em;

				@media (max-width: 1500px){
					@include font-size(40);
				}

				@media (max-width: $b2){
					@include font-size(30);
				}
			}
		}

		.a1-side{
			padding-left: 15px;
			padding-right: 3px;
			flex: 0 0 36.5%;
			max-width: 36.5%;
		}

		.a2-side{
			padding-left: 5px;
			flex: 0 0 38.5%;
			max-width: 38.5%;
		}
	}

	@media (max-width: $b2){


		@media (min-width: $b3 + 1){

			.a1-side{
				padding-right: 5px;
			}

			.a2-side{
				padding-left: 5px;
			}
		}
	}

//	.container-fluid{
//		padding-bottom: 25px;
//
//		@media (max-width: $b3){
//			padding-bottom: 0;
//		}
//
//		.row{
//			align-items: center;
//
//			@media (max-width: $b3){
//				justify-content: center;
//			}
//
//			.col{
//				padding-bottom: 15px;
//
//				@media (min-width: $b3 + 1){
//					padding-right: 10px;
//					padding-left: 10px;
//				}
//
//				@media (max-width: $b3){
//					flex: 0 0 33.3%;
//				}
//
//				@media (max-width: $b4){
//					flex: 0 0 50%;
//				}
//			}
//
//			img{
//				margin: 0 auto;
//			}
//		}
//	}
}

.grey-text-section{
	background-color: $grey;
	background-image: url(../images/grey-dna.svg);
	background-position: 50% -2px;
	background-repeat: repeat-x;

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		.text{

			h1{
				display: block;
				max-height: 0;
				overflow: hidden;
				line-height: 1.3em;
				margin-top: 1.3em;
				white-space: nowrap;
				margin-bottom: 0;
			}
		}

		&.animate{

			.text{

				h1{
					max-height: 1.3em;
					transition: .75s .4s;
					margin-top: 0em;
				}
			}

			.text-top{
				opacity: 1;
				transform: translateY(0);
				transition: .4s .4s;
			}

			.block{
				opacity: 1;
				transform: translateY(0);
				transition: .4s .4s;

				&:nth-of-type(1),
				&:nth-of-type(2),
				&:nth-of-type(3){
					transition: .4s .6s;
				}

				&:nth-of-type(4),
				&:nth-of-type(5),
				&:nth-of-type(6){
					transition: .4s .8s;
				}
			}
		}
	}

	.row{
		padding-top: 151px;
		padding-bottom: 150px;
		position: relative;
		min-height: 100vh;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}

		@media (max-width: 1366px){
			padding-top: 135px;
		}

		@media (max-width: $b3){
			padding-top: 100px;
			padding-bottom: 120px;
			min-height: auto;
		}

		.btn{
			position: absolute;
			left: 29px;
			bottom: 81px;
			padding: 13px 53px;
			font-size: 22.5px!important;
			font-size: 2.25rem!important;
			color: white!important;

			@media (max-width: $b3){
				bottom: 40px;
				font-size: 16px!important;
				font-size: 1.6rem!important;
				padding: 13px 15px;
			}
		}

		.logo{
			position: absolute;
			right: 33px;
			bottom: 81px;

			@media (min-width: 1920px + 1){
				width: 10%;
			}

			@media (max-width: $b3){
				max-width: 50px;
				bottom: 40px;
			}
		}
	}

	h1{
		@include font-size(126.4);
		font-family: $main-font;
		color: white;
		font-weight: 900;
		line-height: .95em;

		@media (max-width: 1366px){
			margin-bottom: .3em;
			@include font-size(90);
		}

		@media (max-width: $b2){
			@include font-size(88/$divider);
		}

		@media (max-width: $b3){
			@include font-size(75/$divider);
		}

		@media (max-width: $b4){
			@include font-size(50/$divider);
		}

		em{
			@include font-size(130);
			font-family: $alt-font;
			color: $green;
			font-style: normal;
			letter-spacing: .01em;

			@media (max-width: 1366px){
				@include font-size(90);
			}

			@media (max-width: $b2){
				@include font-size(88/$divider);
			}

			@media (max-width: $b3){
				@include font-size(75/$divider);
			}

			@media (max-width: $b4){
				@include font-size(50/$divider);
			}
		}
	}
}

.contact-section{
	background-color: white;

	.container{
		padding-bottom: 50px;

		.row{
			padding-top: 160px;

			@media (max-width: $b3){
				padding-top: 100px;
			}
		}
	}

	.gmnoprint{
		display: none!important;
	}


	h2{
		color: $green;
		margin-bottom: .5em;
	}

	.col-lg-5{

		@media (min-width: $b3 + 1){
			padding-right: 80px;
		}
	}

	.col-lg-3{

		@media (min-width: $b3 + 1){
			padding-right: 10px;
		}

		h6{
			margin-bottom: 0;
		}

		a{
			text-decoration: none;
			color: $colour;
			margin-bottom: 1.3em;
			display: inline-block;
			display: flex;
			align-items: center;

			&:hover,
			&:focus{
				color: $green;
			}

		}

		i{
			color: $green;
			@include font-size(24);
			margin-right: 15px;
			min-width: 21px;

			&.map-pin{
				background-image: url(../images/map-pin-simple.svg);
				background-repeat: no-repeat;
				background-size: auto 24px;
				height: 24px;
				width: 21px;
				background-position: 50% 50%;

				@media (min-width: 2132px + 1){
					font-size:62.5% * 1.1109;
					height: 24px * 1.1109;
					width: 21px * 1.1109;
				}

				@media (min-width: 2346px + 1){
					font-size:62.5% * 1.2218;
					height: 24px * 1.2218;
					width: 21px * 1.2218;
				}

				@media (min-width: 2558px + 1){
					font-size:62.5% * 1.3328;
					height: 24px * 1.3328;
					width: 21px * 1.3328;
				}
			}
		}

		span{
			line-height: 1em;
		}
	}

	.acf-map{
		height: 100vh;
		height: 50vh;
		width: 100%;

		@media (max-width: $b3){
			height: 50vh;
		}

		[title="Show street map with terrain"]{
			background-color: red;
			display: flex;
			align-items: center;

			label{
				margin-bottom: 0;
				padding-top: 5px;
			}
		}

		*{
			max-width: none!important;
		}

		img{
			max-width: none!important;
			width: none!important;
		}
	}
}

.thanks-modal{
	position: fixed;
	top: 50%;
	left: 50%;
	width: 375px;
	padding: 40px 40px 20px 30px;
	background-color: white;
	border-radius: 10px;
	transform: translate(-50%, -50%);
	box-shadow: 10px 10px 10px rgba(black, .3);

	.close{
		width: 22.63px;
		height: 22.63px;
		position: absolute;
		top: 22px;
		right: 24px;
		cursor: pointer;
		background-image: url(../images/close.svg);
		background-repeat: no-repeat;
	}

	&.open{
		display: block!important;
	}

	*{
		color: $colour!important;
	}

	h4{
		color: $blue!important;
		font-weight: 600;
		letter-spacing: 0;
	}

}

.form-section{
	background-color: $blue;
	background-image: url(../images/form-back.svg);
	background-repeat: no-repeat;
	background-position: left calc(50vw - #{$base-width / 2} - 177px) bottom -236px;

	@media (min-width: 1920px + 1){

	}

	@media (max-width: $b3){
		background-image: none;
	}

	.row{
		padding-top: 162px;
		padding-bottom: 75px;

		@media (max-width: 1366px){
			padding-top: 145px;
		}

		@media (max-width: $b3){
			padding-top: 100px;
		}
	}

	.title-side{
		align-self: flex-end;

		@media (max-width: $b3){
			display: none;
		}
	}

	*{
		color: white;
	}

	h2{
		margin-bottom: .1em;
	}

	p{
		margin-bottom: 1.9em;
	}

	.form-side{
		padding-bottom: 147px;

		@media (max-width: 1366px){
			padding-bottom: 0;
		}

		@media (min-width: $b2 + 1){
			padding-left: 13px;
			padding-right: 130px;
		}

		@media (max-width: $b3){
			padding-bottom: 0;
		}
	}

	h1{
		@include font-size(100);
		font-weight: 700;

		@media (max-width: $b2){
			@include font-size(65/$divider-big);
		}
	}

	form{

		@media (min-width: $b3 + 1){
			padding-right: 20px;
		}
	}
}

.small-hero-section{
	background-image: url(../images/strategic.svg);
	background-repeat: no-repeat;
	background-position: 50% 93%;
	height: auto!important;

	.fp-tableCell{
		height: auto!important;
	}

	.fp-tableCell.fp-tableCell.fp-tableCell{
		opacity: 1;
		transform: translateY(0);
	}

	.row{
		padding-top: 180px;
		padding-bottom: 20px;
		height: 362px;
		align-items: flex-end;

		@media (max-width: $b2){
			height: auto;
			min-height: 150px;
		}

		@media (max-width: $b3){
			padding-top: 100px;
		}
	}

	h1{
		@include font-size(100);
		font-weight: 700;
		color: $blue;

		@media (max-width: $b2){
			@include font-size(65/$divider-big);
		}
	}
}

.content-section{

	.row{
		padding-top: 48px;
		padding-bottom: 226px;

		@media (max-width: $b2){
			padding-bottom: 100px;
		}
	}

	.fp-tableCell.fp-tableCell.fp-tableCell{
		opacity: 1;
		transform: translateY(0);
	}

	.container{
		max-width: 828px;

		@media (min-width: 2132px + 1){
			max-width: 828px * 1.1109;
		}

		@media (min-width: 2346px + 1){
			max-width: 828px * 1.2218;
		}

		@media (min-width: 2558px + 1){
			max-width: 828px * 1.3328;
		}
	}

	*{
		color: black;
	}

	p + h4{
		margin-top: 1.4em;
	}

	h4{
		@include font-size(30);
		margin-bottom: .7em;
		color: $blue;
		font-weight: 700;
		letter-spacing: .01em;
		margin-bottom: .2em;

		@media (max-width: $b2){
			@include font-size(30/$divider);
		}
	}

	h5{
		font-family: $main-font;
		margin-bottom: .7em;
	}

	p,
	li{
		line-height: 1.2em;

		@media (max-width: $b2){
			line-height: 1.4em;
		}
	}

	ul{
		@include font-size(15);
		margin-bottom: 2.8em;

		li{
			margin-bottom: 0;

			&:before{
				background-color: black;
			}
		}
	}
}

.text-with-stats-section{
	background-image: url(../images/strategic.svg);
	background-repeat: no-repeat;
	background-position: 54% 86.4%;
	overflow: hidden;

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		img{
			transform: translateY(100%);
			opacity: 0;
			visibility: hidden;
		}

		&.animate{

			img{
				transform: translateY(0%);
				opacity: 1;
				visibility: visible;
				transition: .4s .4s;
			}
		}
	}

	.row{
		padding-top: 100px;
		align-items: center;
		min-height: 100vh;
		padding-bottom: 100px;

		@media (max-width: 1366px){


			@media (min-width: $b2 + 1){
				padding-top: 145px;
			}
		}

		@media (max-width: $b3){
			min-height: auto;
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;

			@media (max-width: $b3){
				height: auto;
			}
		}
	}

	.container-fluid{

		@media (max-width: $b3){
			padding-right: 0px;
			padding-left: 0px;
		}
	}

	.stats-side{

		@media (max-width: $b3){
			order: 2;
		}

		img{

			@media (max-width: $b3){
				margin: 0 auto;
			}

			@media (min-width: 1920px + 1){
				width: 95.8%
			}
		}

//		.row{
//			min-height: 0;
//			padding-top: 0;
//		}
//
//		.col-12{
//
//			&.blue{
//
//				*{
//					color: $blue;
//				}
//			}
//
//			&.pink{
//
//				*{
//					color: $pink;
//				}
//			}
//
//			&.green{
//
//				*{
//					color: $green;
//				}
//			}
//		}
//
//		.stat{
//			@include font-size(125.6);
//			font-family: $alt-font;
//			font-weight: 700;
//			line-height: .95em;
//
//			@media (max-width: $b2){
//				@include font-size(60/$divider);
//			}
//		}
//
//		.sub{
//			@include font-size(15);
//			font-family: $alt-font;
//			font-weight: 700;
//		}
	}

	h2{
		color: $blue;
		margin-bottom: .8em;
	}

	.img-side{

		@media (min-width: $b3 + 1){
			padding-left: 76px;
		}
	}

	.text-side{
		//padding-bottom: 50px;

		@media (min-width: $b3 + 1){
			padding-left: 20px;
		}

		@media (max-width: $b3){
			order: 1;
			margin-bottom: 1em;
		}
	}

	.text{
		margin-bottom: 1em;
	}

	.text-column{

		@media (min-width: $b2 + 1){
			column-count: 2;
			column-gap: 5px;

			ul{
				break-inside: avoid;
				break-inside: avoid-column;

				li{
					break-inside: avoid;
				}
			}
		}

		li{

			&:before{
				background-color: $blue;
			}
		}

		p{
			break-inside: avoid;
		}


		@media (max-width: $b3){

			*:last-child{
				margin-bottom: 0;
			}
		}

	}

	.btn-con{
		padding-top: 52px;
	}
}

.background-with-fingerprint-section{
	position: relative;

	.title{
		position: absolute;
		top: 190px;
		left: 0;
		@include font-size(20);
		font-family: $alt-font;
		font-weight: 700;

		@media (max-width: $b3){
			top: 90px;
		}
	}

	&.green{
		background-color: $green;
	}

	&.blue{
		background-color: $blue;
	}

	&.pink{
		background-color: $pink;
	}

	.row{
		padding-top: 100px;
		align-items: center;
		min-height: 100vh;
		position: relative;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}

		@media (max-width: $b3){
			padding-top: 100px;
			padding-bottom: 100px;
		}
	}

	*{
		color: white;
	}

	.finger{
		position: absolute;
		top: 57.4%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 534.876px;
		max-width: 50%;

		@media (min-width: 2132px + 1){
			width: 534.876px * 1.1109;
		}

		@media (min-width: 2346px + 1){
			width: 534.876px * 1.2218;
		}

		@media (min-width: 2558px + 1){
			width: 534.876px * 1.3328;
		}

		@media (max-width: $b3){
			max-width: 75%;
		}
	}

	.col-md-8{
		padding-top: 10px;

		@media (min-width: $b3 + 1){
			padding-left: 17px;
			padding-right: 70px;
		}

		*:last-child{
			margin-bottom: 0;
		}
	}
}

.grey-slide-section{
	background-color: $grey;
	position: relative;

	.fp-slidesContainer{
		max-width: none;
	}

	.fp-slidesNav.fp-slidesNav.fp-slidesNav.fp-slidesNav{
		display: none;
	}

	.over-con{
		position: absolute;
		top: 0px;
		left: 50%;
		transform: translateX(-50%);

		@media (max-width: $b3){
			top: 90px;

		}

		.title{
			position: absolute;
			top: 176px;
			left: 0;
			@include font-size(20);
			font-family: $alt-font;
			font-weight: 700;

			@media (max-width: $b3){
				position: static;
			}
		}
	}

	*{
		color: white;
	}

	.back-con + .container{
		max-width: 925px;
	}

	.slick-slide{
		position: relative;

		@media (min-width: 1920px + 1){

			&[data-slick-index="2"]{

				.text-side{
					transform: translateX(10%);
				}
			}

			&[data-slick-index="4"]{

				.text-side{
					transform: translateX(24%);
				}
			}
		}

		&:not([data-slick-index="0"]){

			.image-side{

				img{

					@media (max-width: $b2){

						@media (min-width: $b3 + 1){
							margin: 0 auto;
							max-width: 70%;
						}
					}
				}
			}
		}

		.slick-slide{
			overflow: hidden;

			.container{
				max-width: 915px;
			}

			@media (max-width: $b2){

				@media (min-width: $b3 + 1){

				}
			}
		}

		.side-left{

			.text-side{

				@media (min-width: $b3 + 1){
					padding-left: 80px;
					padding-right: 40px;
				}
			}

			.image-side{

				@media (max-width: $b2){

					@media (min-width: $b3 + 1){
						padding-left: 0;
						margin-left: 12%;
					}
				}

				@media (min-width: $b3 + 1){
					display: flex;
					justify-content: flex-end;
				}
			}
		}

		.side-right{

			.text-side{

				@media (min-width: $b3 + 1){
					padding-left: 80px;
					padding-right: 30px;
				}
			}

			.image-side{

				@media (max-width: $b2){

					@media (min-width: $b3 + 1){
						padding-right: 0;
						margin-right: 12%;
					}
				}

				@media (min-width: $b3 + 1){
					display: flex;
					justify-content: flex-end;
				}
			}

			@media (min-width: $b3 + 1){

				.text-side{
					order: 2;
				}

				.image-side{
					order: 1;
				}
			}
		}

		.text-side{
			align-self: flex-end;
			padding-bottom: 20px;

			@media (max-width: 1366px){
				align-self: center;
				padding-bottom: 0;
				padding-top: 27%;
			}

			@media (min-width: $b3 + 1){
				flex: 0 0 60%;
				max-width: 60%;
			}

			@media (max-width: $b3){
				padding-bottom: 10px;
			}

			*:last-child{
				margin-bottom: 0;
			}

			h2{
				margin-bottom: -.1em;
				color: $pink;
				margin-bottom: 10px;
			}

			h4{
				line-height: 1.15em;
				letter-spacing: .01em;

				@media (max-width: 1500px){
					@include font-size(22);
				}

				@media (max-width: $b2){
					@include font-size(25/$divider);
				}
			}
		}



		.image-side{

			@media (min-width: $b2 + 1){
				flex: 0 0 40%;
				max-width: 40%;
			}

			@media (min-width: $b3 + 1){
				flex: 0 0 28%;
				max-width: 28%;
			}

			img{



				@media (max-width: $b3){
					margin: 2em auto 0;
					max-width: 50%;
				}
			}
		}

		.back-con{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: .25;
			@include font-size(179.4);
			font-family: $alt-font;
			font-weight: 700;
			display: flex;
			flex-direction: column;
			justify-content: center;
			letter-spacing: .02em;

			@media (max-width: 1200px){
				font-size: 14.4vw;
			}

			&:before{
				content: '';
				position: absolute;
				top: -60px;
				right: 40.7%;
				width: 64.0639px;
				height: 26.124px;
				background-size: cover;
				background-image: url(../images/dna-top.svg);
				background-repeat: no-repeat;
			}

			&:after{
				content: '';
				position: absolute;
				bottom: -151px;
				left: 7%;
				width: 26.124px;
				height: 64.0639px;
				background-size: cover;
				background-image: url(../images/dna-bottom.svg);
				background-repeat: no-repeat;

				@media (max-width: 1200px){
					left: 14%;
				}
			}

			@media (max-width: $b2){
				//@include font-size(100/$divider);
				opacity: .1;
			}

			div{
				line-height: 0.75em;
				overflow: hidden;
				margin: 1px 0;

				&:nth-of-type(1){
					max-height: 16px;
					margin-bottom: .1em;
					opacity: .25;
				}

				&:nth-of-type(2){
					max-height: 24px;
					margin-bottom: .05em;
					opacity: .5;
				}

				&:nth-of-type(3){
					max-height: 38px;
					opacity: .75;
				}

				&:nth-of-type(4){
					opacity: 1;
				}

				&:nth-of-type(5){
					max-height: 35px;
					margin-top: .03em;
					opacity: .75;
					display: flex;
					align-items: flex-end;
				}

				&:nth-of-type(6){
					max-height: 21px;
					margin-top: .05em;
					opacity: .5;
					display: flex;
					align-items: flex-end;
				}

				&:nth-of-type(7){
					max-height: 13px;
					margin-top: .09em;
					opacity: .25;
					display: flex;
					align-items: flex-end;
				}
			}
		}

		.row{
			padding-top: 210px;
			padding-bottom: 230px;
			align-items: center;
			min-height: 100vh;
			position: relative;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				height: 100vh;
			}

			@media (max-width: 1366px){
				padding-top: 100px;
				padding-bottom: 100px;
			}

			@media (max-width: $b3){
				padding-top: 100px;
				padding-bottom: 100px;
			}
		}
	}
}

.videos-section{
	min-height: 100vh;

	@media (max-width: $b3){
		height: auto!important;
	}

	&.darker{
		background-color: #A1A1A1;

		.top{

			*{
				color: white;
			}

			@media (max-width: $b3){

				br{
					display: none;
				}
			}
		}

		h5{

			@media (max-width: 1300px){
				width: 430px;
				max-width: 100%;

				br{
					display: none;
				}
			}
		}

		.videos{

			.video-line{

				.link{
					color: white;

					&:hover{
						color: $green;
					}

					.before,
					.after{
						color: #BDBDBD;
					}
				}

			}
		}
	}

	@media (max-width: $b3){
		height: auto!important;

		.fp-tableCell{
			height: auto!important;
		}
	}

	.top{
		padding-top: 164px;
		padding-bottom: 3px;

		@media (max-width: $b3){
			padding-top: 87px;
		}
	}

	.row{
		padding-top: 13px;
		padding-bottom: 13px;

		@media (max-width: $b3){
			min-height: 0;
			padding-bottom: 22px;
		}
	}

	.container{
		max-width: 1110px;
	}

	.video-side{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 51.3%;
		pointer-events: none;

		@media (max-width: 1210px){
			right: 20px;
		}

		@media (max-width: $b3){
			display: none;
		}

		@media (min-width: 1210px + 1){
			right: -23px;
		}

		.video-con{
			width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: none;

			&.active{
				display: block;
			}

			.over{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				max-height: 100%;
				padding: 36px 25px;
				@include font-size(49.8);
				line-height: 1.1em;
				color: white;
				font-weight: 700;

				@media (max-width: 1550px){
					font-size: 40px;
				}

				@media (max-width: 1300px){
					font-size: 36px;
				}
			}
		}
	}

	.videos{
		display: flex;
		flex-direction: column;

		.video-line{

			.vid-mob{
				margin-bottom: 2em;
				margin-top: .5em;
				display: none;
//
//				@media (min-width: $b3 + 1){
//					display: none;
//				}
			}

			.link{
				position: relative;
				white-space: nowrap;
				display: inline-block;
				text-decoration: none;
				color: white;
				color: #a1a1a1;
				@include font-size(86.63);
				line-height: 1em;
				font-family: $alt-font;
				font-weight: 700;
				margin-bottom: .07em;
				letter-spacing: -.02em;

				@media (max-width: $b2){
					@include font-size(86/$divider);
				}

				@media (max-width: $b2){
					@include font-size(86/$divider);
				}

				@media (max-width: $b3){
					@include font-size(45/$divider);
				}

				@media (max-width: $b4){
					@include font-size(30/$divider);
				}

				@media (min-width: $b3 + 1){
					&.hovered{
						color: $green;
					}
				}

				.before,
				.after{
					position: absolute;
					top: 0;
					max-width: none;
					color: #CFCFCF;
					color: white;
					pointer-events: none;
				}

				.before{
					right: calc(100% + .22em);
				}

				.after{
					left: calc(100% + .22em);
				}
			}
		}
	}
}

.chart-section{
	background-color: $grey;

	*{
		color: white;
	}

	.row{
		position: relative;
		padding-top: 186px;
		padding-bottom: 117px;
		min-height: 100vh;

		@media (max-width: $b2){
			padding-top: 100px;
			padding-bottom: 50px;
		}
	}

	.text{

		@media (max-width: $b2){

			@media (min-width: $b3 + 1){
				flex: 0 0 79%;
				max-width: 79%;
			}
		}
	}

	h5{
		color: $green;
		font-family: $alt-font;
		font-weight: 700;
		@include font-size(20);
		margin-bottom: 1em;
	}

	h4{
		@include font-size(35);
		line-height: 1.15em;
	}

	h3{
		@include font-size(31.63);
		color: $green;
		font-weight: 900;
		text-transform: uppercase;
		font-family: $main-font;
	}

	.chart-con{
		margin-top: -231px;
		position: relative;
		align-self: flex-end;

		@media (max-width: $b2){
			margin-top: 0;
		}

		.big{
			width: 100%;

			@media (max-width: $b3){
				display: none;
			}
		}

		h3{
			margin-bottom: .1em;
		}

		p{
			line-height: 1.3em;
			letter-spacing: 0;
		}

		.inner-text{
			position: absolute;
			width: 20%;

			@media (max-width: $b3){
				position: static;
				width: 100%!important;
				margin-bottom: 1em;
			}

			&:nth-of-type(1){
				bottom: 13.6%;
				bottom: 8%;
				left: 30px;
			}

			&:nth-of-type(2){
				width: 16%;
				bottom: 22.6%;
				bottom: 18.2%;
				left: 26%;
			}

			&:nth-of-type(3){
				width: 15.1%;
				bottom: 30.5%;
				bottom: 30%;
				left: 44.7%;
			}

			&:nth-of-type(4){
				width: 15.1%;
				bottom: 45.7%;
				bottom: 43%;
				left: 59.9%;
			}

			&:nth-of-type(5){
				width: 15.1%;
				bottom: 62.2%;
				left: 70.7%;
			}

			&:nth-of-type(6){
				width: 14.1%;
				bottom: 85.9%;
				left: 79.1%;

				@media (max-width: $b2){
					width: 18.1%;
					bottom: 93.9%;
					left: 76.1%;
				}
			}

			*:last-child{
				margin-bottom: 0;
			}
		}
	}
}

.half-image-hero-section{
	position: relative;

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		img{
			transform: translateY(100%) translateX(-50%);

			@media (max-width: $b3){
				transform: translateY(100%);
			}
		}

		&.animate{

			img{
				transform: translateY(0%) translateX(-50%);
				transition: .4s .4s;

				@media (max-width: $b3){
					transform: translateY(0%);
				}
			}
		}
	}

	.row{
		min-height: 100vh;
		padding-top: 140px;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}

		@media (max-width: $b3){
			padding-top: 100px;
		}

		.text-side{
			align-self: center;

			@media (max-width: $b2){
				max-width: 460px;
			}

//			@media (min-width: $b3 + 1){
//				padding-right: 100px;
//			}
		}
	}

	h3{
		margin-bottom: 0;
	}

	h2{
		color: $blue;

		@media (min-width: 1500px + 1){
			@include font-size(55);
		}

		@media (max-width: 1500px){
			@include font-size(50);
		}
	}

	h1{
		@include font-size(85);

		@media (max-width: 1500px){
			@include font-size(75);
		}

		@media (max-width: $b2){
			@include font-size(65/$divider-big);
		}
	}

	h2{
		@include font-size(50);

		@media (max-width: 1500px){
			@include font-size(50);
		}

		@media (max-width: $b2){
			@include font-size(45/$divider);
		}
	}

	h3{
		@include font-size(45);

		@media (max-width: 1500px){
			@include font-size(45);
		}

		@media (min-width: $b2 + 1){
			@include font-size(55);
		}

		@media (max-width: $b2){
			@include font-size(40/$divider);
		}
	}

	h4{
		@include font-size(25);

		@media (max-width: $b2){
			@include font-size(25/$divider);
		}
	}

	h5{
		@include font-size(22);

		@media (max-width: $b2){
			@include font-size(22/$divider);
		}
	}

	.image-side{
		position: relative;

		@media (max-width: $b3){
			align-self: flex-end;
		}

		img{
			position: absolute;
			left: 50%;
			bottom: 0;
			max-width: 50%;
			z-index: 3;
			width: auto;
			max-width: 148%;


			@media (min-width: $b3 + 1){
				max-height: calc(100vh - 120px);
			}

			@media (min-width: 1920px + 1){
				max-width: 175%;
			}

			@media (min-width: $b3 + 1){
				left: 70%;
			}

			img{
				margin: 0 auto;
			}

			@media (max-width: 1500px){
				max-width: 128%;
			}

			@media (max-width: $b3){
				max-width: 100%;
			}

			@media (max-width: $b3){
				//max-height: 220px;
				max-width: 100%;
				position: static;
				margin: 0 auto;
			}
		}

//		img{
//			position: absolute;
//			right: 0;
//			bottom: 0;
//			max-width: 50%;
//
//			@media (max-width: $b3){
//
//				right: -15px;
//				max-width: 100%;
//			}
//		}
	}
}

.half-image-section{
	position: relative;

	&.side-left{

		@media (min-width: $b3 + 1){
			
			.text-side{
				order: 2;
			}

			.image-side{
				order: 1;
				
				img{
					right: 70%;
					left: auto;
				}
			}
		}
	}

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		&.side-left{

			@media (min-width: $b3 + 1){
				
				img{
					transform: translateY(100%) translateX(50%);
				}
			}
		}

		.title-con{

			div{

				&:nth-of-type(2),
				&:nth-of-type(3),
				&:nth-of-type(4){
					transform: translateY(-114%);
					opacity: 0;
					visibility: hidden;
				}

				&:nth-of-type(3){
					transform: translateY(-290%);
				}

				&:nth-of-type(4){
					transform: translateY(-534%);
				}
			}
		}

		img{
			transform: translateY(100%) translateX(-50%);

			@media (max-width: $b3){
				transform: translateY(100%);
			}
		}

		&.animate{

			&.side-left{

				@media (min-width: $b3 + 1){
					
					img{
						transform: translateY(0%) translateX(50%);
					}
				}
			}

			.title-con{

				div{

					&:nth-of-type(2),
					&:nth-of-type(3),
					&:nth-of-type(4){
						transform: translateY(0%);
						transition: .4s .4s;
						opacity: 1;
						visibility: visible;
					}
				}
			}

			img{
				transform: translateY(0%) translateX(-50%);
				transition: .4s .4s;

				@media (max-width: $b3){
					transform: translateY(0%);
				}
			}
		}
	}

	&.colour-blue{
		background-color: $blue;

		.btn{

			&:hover,
			&:focus{
				background-color: #282ca4;
				border-color: #282ca4;
			}
		}

		.title-con.title-con{
			margin-bottom: 2em;
			color: #292929;

			div{

				&:nth-of-type(2){
					//opacity: .55;
					color: #2704BB;
				}

				&:nth-of-type(3){
					//opacity: .55;
					color: #2704BB;
				}

				&:nth-of-type(4){
					//opacity: .55;
					color: #2704BB;
				}
			}
		}
	}

	&.colour-grey{
		background-color: $grey;

		.title-con.title-con{
			margin-bottom: 2em;
			color: #292929;

			div{

				&:nth-of-type(2){
					opacity: .55;
					color: #292929;
				}

				&:nth-of-type(3){
					opacity: .55;
					color: #292929;
				}

				&:nth-of-type(4){
					opacity: .55;
					color: #292929;
				}
			}
		}
	}

	&.colour-pink{
		background-color: $pink;
		background-color: #ff255e;
		background-color: #FF4563;

		.title-con.title-con{

			//I was #af3f4d, now it is #be334a

			div{

				&:nth-of-type(2){
					opacity: 1!important;
					color: #af3f4d;
					color: #be334a;
				}

				&:nth-of-type(3){
					opacity: 1!important;
					color: #af3f4d;
					color: #be334a;
				}

				&:nth-of-type(4){
					opacity: 1!important;
					color: #af3f4d;
					color: #be334a;
				}
			}
		}

		.btn{
			background-color: $blue;
			border-color: $blue;
			color: white!important;

			&:hover,
			&:focus{
				background-color: #be334a;
				border-color: #be334a;
				color: $white!important;
			}
		}

		.title-con.title-con{
			margin-bottom: 2em;

			div{

				&:nth-of-type(2){
					opacity: .55;
				}

				&:nth-of-type(3){
					opacity: .55;
				}

				&:nth-of-type(4){
					opacity: .55;
				}
			}
		}
	}

	.title-con{
		margin-bottom: 2em;

		div{
			@include font-size(100);
			font-family: $alt-font;
			font-weight: 700;
			line-height: .75em;
			line-height: 1em;
			overflow: hidden;

			@media (max-width: $b2){
				@include font-size(69);
			}

			@media (max-width: $b3){
				@include font-size(65/$divider-big);
			}

			&:nth-of-type(1){
				z-index: 2;
				position: relative;
			}

			&:nth-of-type(2){
				max-height: 33px;
				margin-top: .045em;
				opacity: .35;
				display: flex;
				align-items: flex-end;
				color: #444444;
				//mix-blend-mode: hard-light;

				@media (max-width: $b2){
					max-height: 22px;
				}

				@media (max-width: $b3){
					max-height: 16px;
				}
			}

			&:nth-of-type(3){
				max-height: 23px;
				margin-top: .06em;
				opacity: .35;
				display: flex;
				align-items: flex-end;
				color: #444444;
				//mix-blend-mode: hard-light;

				@media (max-width: $b2){
					max-height: 16px;
					margin-top: .01em;
				}

				@media (max-width: $b3){
					max-height: 13px;
					margin-top: .01em;
				}
			}

			&:nth-of-type(4){
				max-height: 18px;
				margin-top: 0.12em;
				opacity: .35;
				display: flex;
				align-items: flex-end;
				color: #444444;
				//mix-blend-mode: hard-light;

				@media (max-width: $b2){
					max-height: 14px;
					margin-top: .001em;
				}

				@media (max-width: $b3){
					max-height: 10px;
					margin-top: .001em;
				}
			}
		}
	}

	*{
		color: white;
	}

	.btn{
		margin-top: 2em;
	}

	.row{
		min-height: 100vh;
		padding-top: 140px;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}

		@media (max-width: $b3){
			padding-top: 100px;
		}

		.text-side{
			align-self: center;

			@media (max-width: $b2){
				max-width: 460px;
			}

//			@media (min-width: $b3 + 1){
//				padding-right: 100px;
//			}
		}
	}

	h3{
		margin-bottom: 0;
	}

	h2{
		color: $blue;

		@media (min-width: 1500px + 1){
			@include font-size(55);
		}

		@media (max-width: 1500px){
			@include font-size(50);
		}
	}

	h1{
		@include font-size(85);

		@media (max-width: 1500px){
			@include font-size(75);
		}

		@media (max-width: $b2){
			@include font-size(65/$divider-big);
		}
	}

	h2{
		@include font-size(50);

		@media (max-width: 1500px){
			@include font-size(50);
		}

		@media (max-width: $b2){
			@include font-size(45/$divider);
		}
	}

	h3{
		@include font-size(45);

		@media (max-width: 1500px){
			@include font-size(45);
		}

		@media (min-width: $b2 + 1){
			@include font-size(55);
		}

		@media (max-width: $b2){
			@include font-size(40/$divider);
		}
	}

	h4{
		@include font-size(25);

		@media (max-width: $b2){
			@include font-size(25/$divider);
		}
	}

	h5{
		@include font-size(22);

		@media (max-width: $b2){
			@include font-size(22/$divider);
		}
	}

	.image-side{
		position: relative;

		@media (max-width: $b3){
			align-self: flex-end;
		}

		img{
			position: absolute;
			left: 50%;
			bottom: 0;
			max-width: 50%;
			z-index: 3;
			width: auto;
			max-width: 148%;


			@media (min-width: $b3 + 1){
				max-height: calc(100vh - 120px);
			}

			@media (min-width: 1920px + 1){
				max-width: 175%;
			}

			@media (min-width: $b3 + 1){
				left: 70%;
			}

			img{
				margin: 0 auto;
			}

			@media (max-width: 1500px){
				max-width: 128%;
			}

			@media (max-width: $b3){
				max-width: 100%;
			}

			@media (max-width: $b3){
				//max-height: 220px;
				max-width: 100%;
				position: static;
				margin: 0 auto;
			}
		}

//		img{
//			position: absolute;
//			right: 0;
//			bottom: 0;
//			max-width: 50%;
//
//			@media (max-width: $b3){
//
//				right: -15px;
//				max-width: 100%;
//			}
//		}
	}
}



section{
	overflow: hidden;
}

.videos-section.videos-section{

	+ .product-section.side-left.colour-pink{

		.image-side img{

			@media (max-width: $b3){
				width: 50%;
				margin: 0 auto;
			}

			@media (max-width: 600px){
				width: 100%;
			}
		}
	}
}


.grey-text-with-image-section.grey-text-with-image-section.grey-text-with-image-section.grey-text-with-image-section.grey-text-with-image-section + .product-section{

	&.colour-blue{

		&:before{
			background-image: url(../images/Blue_background_DNA_pattern_reduced_height.svg);
			height: 155.6px;
			background-size: auto 155.6px;

			@media (max-width: $b3){
				height: 85.6px;
				background-size: auto 85.6px;
			}
		}
	}

	&.colour-pink{

		&:before{
			background-image: url(../images/Pink_background_DNA_pattern_reduced_height.svg);
			height: 155.6px;
			background-size: auto 155.6px;

			@media (max-width: $b3){
				height: 85.6px;
				background-size: auto 85.6px;
			}
		}
	}

	&.colour-grey{

		&:before{
			background-image: url(../images/Grey_background_DNA_pattern_reduced_height.svg);
			height: 155.6px;
			background-size: auto 155.6px;

			@media (max-width: $b3){
				height: 85.6px;
				background-size: auto 85.6px;
			}
		}
	}

	.row{
		min-height: 0vh;
	}


	.text-side{
		padding-top: 0;
	}

	.image-side{

		img{
			max-height: calc(50vh - 40px);
			max-height: calc(100vh - 130px);
			max-height: 100%;
			width: auto;
		}

	}
}





.pink-text-with-blocks-section + .grey-text-with-icon-section{

	.text-side ul{

		li{

			&:before{
				background-color: $pink;
			}
		}
	}
}

.grey-text-with-icon-section{
	background-color: $grey;
	position: relative;
	overflow: hidden;

	.text-side{

		@media (min-width: $b3 + 1){
			order: 2;
		}
	}

	.img-side{

		img{
			margin: 4.3em auto 0;
			margin: 0 auto;

			@media (min-width: 1920px + 1){
				width: 73.9%;
			}

			@media (max-width: 1366px){
				margin: 0 auto;
			}
		}

		@media (min-width: $b3 + 1){
			order: 1;
		}
	}

	.text-column{

		@media (min-width: $b1 + 1){
			column-count: 2;
			column-gap: 10px;

			ul{
				break-inside: avoid;
				break-inside: avoid-column;

				li{
					break-inside: avoid;
				}
			}
		}

		p{
			break-inside: avoid;
		}
	}

	.text-side{
		position: relative;
		z-index: 1;
		padding-bottom: 177px;
		padding-bottom: 2em;

		@media (max-width: 1366px){
			padding-bottom: 2em;
		}

		@media (min-width: $b3 + 1){
			padding-right: 10px;
		}

		@media (max-width: $b2){
			padding-bottom: 2em;
		}

		*{
			color: white;
		}

		h6{
			color: $green;
		}

		h2{
			margin-bottom: 0.75em;
		}

		h4{
			line-height: 1.15em;
			letter-spacing: .01em;
			margin-bottom: .6em;
		}

		ul{

			li{

				&:before{
					background-color: $green;
				}
			}
		}
	}

	.under{
		position: absolute;
		position: relative;
		left: -30px;
		bottom: 0;
		padding: 85px 100px 85px 30px;
		z-index: 1;
		width: 500px;
		margin-top: 105px;
		padding: 55px 100px 46px 30px;

		@media (max-width: 1200px){
			left: 0;
		}

		@media (min-width: 1366px + 1){
			width: 500px;
			margin-top: 105px;
		}

		@media (min-width: 2132px + 1){
			width: 500px * 1.1109;
		}

		@media (min-width: 2346px + 1){
			width: 500px * 1.2218;
		}

		@media (min-width: 2558px + 1){
			width: 500px * 1.3328;
		}

		@media (max-width: 1366px){
			padding: 55px 100px 46px 30px;
		}

		@media (max-width: $b2){
			position: relative;
			padding: 20px 0px 50px 0px;
		}

		@media (max-width: $b3){
			position: static;
		}

		&:after{
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 600px;
			max-width: 600px;
			height: 600px;
			border-radius: 50%;
			background-color: #696969;
			transform: translate(-63%, -51.6%);
			z-index: -1;

			@media (max-width: $b2){
				top: 90%;
				left: 38%;
			}

			@media (max-width: $b3){
				display: none;
			}
		}

		*{
			color: white;
		}

		h2{
			@include font-size(30);
			line-height: 1.1em;
			letter-spacing: .01em;
		}
	}

	.row{
		padding-top: 180px;
		align-items: center;
		min-height: 100vh;
		padding-top: 145px;

		@media (max-width: 1366px){
			padding-top: 145px;
		}

		@media (max-width: $b2){
			min-height: 0;
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}

		@media (max-width: $b3){
			padding-top: 100px;
		}
	}

	.img-side{
		position: relative;
		align-self: stretch;

		img{
			position: relative;
			z-index: 11;
			transform: translateY(20%);

			@media (min-width: 1366px + 1){
				transform: translateY(20%);
			}

			@media (max-width: $b3){
				max-width: 50%;
			}

			@media (max-height: 450px ){
				max-width: 200px;
			}
		}
	}

//	.img-con.img-con{
//		position: absolute;
//		width: 146%;
//		max-width: 146%;
//		padding-top: 146%;
//		border-radius: 50%;
//		bottom: -221px;
//		left: 97px;
//
//		img{
//			position: absolute;
//			top: 71px;
//			left: -27px;
//			max-width: 510px;
//		}
//
//		svg{
//			position: absolute;
//			bottom: 228px;
//			left: -192px;
//
//			path{
//				fill: $grey;
//			}
//		}
//	}

	h1{
		color: $blue;
		margin-bottom: 0em;
	}

	h2{
		color: $pink;
	}

	.btn{
		margin-top: 3.2em;
	}

//	.img-con{
//		background-color: $green;
//	}
}


.careers-section + .grey-form-section,
.quote-slide-section + .grey-form-section{

	.image-side{

		@media (min-width: $b3 + 1){
			padding-top: 0;
			padding-bottom: 0;

			img{
				margin-top: -70px;
			}
		}
	}
}

.grey-form-section{

	+ .clients-section{
		background-color: white;
	}

	.row{
		padding-top: 209px;
		padding-bottom: 147px;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			padding-top: 160px;
		}

		@media (max-width: $b3){
			padding-top: 100px;
			padding-bottom: 75px;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				padding-top: 100px;
			}
		}
	}

	h2{
		@include font-size(75);
		font-family: $alt-font;
		line-height: 1.05em;
		letter-spacing: 0;
		margin-bottom: .25em;

		@media (max-width: 1500px){
			@include font-size(60);
		}

		@media (max-width: $b2){
			@include font-size(65/$divider-big);
		}
	}

	&.colour-pink{

		.ajax-loader:before,
		.ajax-loader:after{
			border-color: $pink!important;
		}

		.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus,
		input:focus,
		textarea:focus,
		select:focus{
			border-color: $pink!important;
		}

		h2{
			color: $pink;
		}

		[type="submit"]{
			background-color: $pink;
			border-color: $pink;
			color: white!important;
		}
	}

	&.colour-blue{

		h2{
			color: $blue;
		}

		[type="submit"]{
			background-color: $green;
			border-color: $green;
		}
	}

	&.colour-green{

		[type="submit"]{
			background-color: $green;
			border-color: $green;
		}
	}

	input[type="submit"]{
		margin-top: 13px;
	}

	.image-side{
		padding-top: 53px;

		@media (min-width: $b3 + 1){
			padding-left: 41px;
		}

		@media (max-width: $b3){
			display: none;
		}

		img{

			@media (min-width: 1920px + 1){
				width: 78.7%;
			}
		}
	}

	.form-side{

		@media (max-width: $b3){

			br{
				display: none;
			}
		}

		@media (min-width: $b3 + 1){
			padding-left: 4px;
		}
	}

	form{

		@media (min-width: $b2 + 1){
			padding-right: 123px;
		}

		[type="submit"]{
			margin-top: 23px;

			&:hover,
			&:focus{
				background-color: $blue;
				border-color: $blue!important;
			}
		}

		.cv-label{
			position: relative;
			width: 100%;
			overflow: hidden;

			&:hover{

				&:before{
					background-color: $green;
					color: $grey;
				}
			}

			.windows &{

				@-moz-document url-prefix() {

					&:after{
						content: '';
						height: 100%;
						position: absolute;
						left: 0;
						top: 0;
						height: 38px;
						width: 200px;
						background-color: #f0f0f0;
					}
				}
			}

			&:before{
				content:"ATTACH CV";
				position:absolute;
				left:0;
				top: 1px;
				font-family: $alt-font;
				padding:10px 5px;
				background: $grey;
				@include font-size(12.6);
				font-weight: 600;
				color:white;
				width:165px;
				text-align:center;
				border-radius:50px;
				cursor:pointer;
				z-index: 1;
				letter-spacing: .04em;
				transition: $time;

				@supports (-ms-ime-align: auto) {
					display: none;
				}

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					display: none;
				}
			}

			::-webkit-file-upload-button{
				opacity: 0;
			}

			label{
				display: none;

				@supports (-ms-ime-align: auto) {
					display: block;
				}

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					display: block;
				}
			}

			input{
				padding-left: 101px;
				padding-bottom: 24px;

				@supports (-ms-ime-align: auto) {
					padding-left: 0;
				}

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					padding-left: 0;
				}

				&::-webkit-file-upload-button{
					opacity: 0;
				}

				.windows &{

					@-moz-document url-prefix() {
						padding-left: 120px;
						margin-top: -4px;
					}
				}

				@-moz-document url-prefix() {
					padding-left: 71px;
				}

				&::-webkit-file-upload-button {
					visibility: hidden;
				}
			}
		}
	}

	input,
	input.input-text,
	textarea,
	select{
		border-color: $grey;
		color: $grey;
	}

	::-webkit-input-placeholder {
		color: $grey;
	}

	:-moz-placeholder { /* Firefox 18- */
		color: $grey;
	}

	::-moz-placeholder {  /* Firefox 19+ */
		color: $grey;
	}

	:-ms-input-placeholder {
		color: $grey;
	}
}

.pink-text-with-blocks-section{
	background-image: url(../images/half-print.svg);
	background-position: 50% 100%;
	background-repeat: no-repeat;

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		&.animate{

			.text-top{
				opacity: 1;
				transform: translateY(0);
				transition: .4s .4s;
			}

			.block{
				opacity: 1;
				transform: translateY(0);
				transition: .4s .4s;

				&:nth-of-type(1),
				&:nth-of-type(2),
				&:nth-of-type(3){
					transition: .4s .6s;
				}

				&:nth-of-type(4),
				&:nth-of-type(5),
				&:nth-of-type(6){
					transition: .4s .8s;
				}
			}
		}
	}

	&:not(.active){

		.block,
		.text-top{
			opacity: 0;
			transform: translateY(75px);
		}
	}

	.row{
		padding-top: 161px;
		padding-bottom: 138px;

		@media (max-width: 1024px){
			padding-bottom: 60px;
		}

		@media (max-width: $b3){
			padding-top: 100px;
			padding-bottom: 75px;
		}
	}

	.text{
		width: 640px;
		padding-bottom: 35px;

		@media (max-width: 1366px){
			padding-bottom: 15px;
		}
	}

	h2,
	h6{
		color: $pink;
	}

	.block-bottom{

		.row{
			padding-top: 40px;
			padding-bottom: 0;
		}

		.container-fluid{
			padding: 0;
		}


		@media (min-width: $b3 + 1){
			//padding-left: 40px;
			//padding-right: 40px;
		}

		.block{
			display: flex;
			align-items: center;
			margin-bottom: 59px;

			@media (max-width: 1024px){
				margin-bottom: 29px;
			}

			@media (max-width: $b3){

				@media (min-width: 475px + 1){
					flex: 0 0 50%;
					max-width: 50%;
				}
			}

			.icon-side{
				display: flex;
				justify-content: center;
				align-items: center;
				width: 130px;
				padding-right: 5px;

				@media (min-width: 2132px + 1){
					width: 130px * 1.1109;
					padding-right: 5px * 1.1109;
				}

				@media (min-width: 2346px + 1){
					width: 130px * 1.2218;
					padding-right: 5px * 1.2218;
				}

				@media (min-width: 2558px + 1){
					width: 130px * 1.3328;
					padding-right: 5px * 1.3328;
				}

				img{
					width: 100%;
				}
			}

			.text-side{
				width: calc(100% - 130px);

				@media (min-width: 2132px + 1){
					width: calc(100% - 130px * 1.1109);
				}

				@media (min-width: 2346px + 1){
					width: calc(100% - 130px * 1.2218);
				}

				@media (min-width: 2558px + 1){
					width: calc(100% - 130px * 1.3328);
				}

				*:last-child{
					margin-bottom: 0;
				}
			}
		}
	}

	h6{
		@include font-size(20);
		margin-bottom: 0;

		@media (max-width: $b2){
			@include font-size(20/$divider);
		}
	}

	p{
		@include font-size(20);
		line-height: 1.3em;

		@media (max-width: $b2){
			@include font-size(16/$divider);
		}
	}
}

.fp-controlArrow.fp-next.fp-next{
	background-image: url(../images/slide-next.svg);
	width: 50px;
	height: 50px;
	background-size: 50px 50px;
	border: none;
	top: auto;
	bottom: 50px;
	transform: none;
	right: 60px;

	@media (max-width: $b2){
		right: 30px;
	}

	@media (max-width: $b3){
		right: 30px;
		width: 25px;
		height: 25px;
		background-size: 25px 25px;
	}

	@media (min-width: 1024px + 1){
		&:hover{
			background-image: url(../images/slide-next-hover-green.svg);
		}
	}
}

.fp-controlArrow.fp-prev.fp-prev{
	background-image: url(../images/slide-prev.svg);
	width: 50px;
	height: 50px;
	background-size: 50px 50px;
	border: none;
	top: auto;
	bottom: 50px;
	transform: none;
	left: 60px;

	@media (max-width: $b2){
		left: 30px;
	}

	@media (max-width: $b3){
		left: 30px;
		width: 25px;
		height: 25px;
		background-size: 25px 25px;
	}

	@media (min-width: 1024px + 1){
		&:hover{
			background-image: url(../images/slide-prev-hover-green.svg);
		}
	}
}

.fp-slidesNav.fp-slidesNav.fp-slidesNav{
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto!important;
	width: calc(100% - 230px);
	bottom: 51px;

	@media (max-width: $b2){
		display: none!important;
		width: calc(100% - 150px);
	}

	@media (max-width: $b2){
		display: none!important;
		width: calc(100% - 110px);
	}

	li{
		width: 15px;
		height: 15px;
	}

	a{
		background: white;
		display: block;
		height: 15px;
		width: 15px;
		border-radius: 15px;
		box-shadow: inset 0 0 0 2px #fff;

		&.active{
			background-color: $blue;
		}
		span{
			display: none;
		}
	}
}

//html{
//	position: relative;
//	height: 100vh;
//	width: 100%;
//	overflow: auto;
//}

.number-slide-section{

//	h2{
//
//		@media (max-width: 1200px){
//			@include font-size(50/$divider);
//		}
//	}

	.slick-dots{
		right: auto;
		left: 50%;
		transform: translateX(-50%);
		flex-direction: row;
		width: auto;
		top: auto;
		bottom: 42px;

		@media (max-width: $b2){
			display: none!important;
		}

		li{
			margin: 0 7.5px;

			@media (max-width: $b3){
				margin: 0 5px;
			}
		}
	}

	@media (min-width: 1024px + 1){
		.slick-next{

			&:hover{

				&:before{
					background-image: url(../images/slide-next-hover-blue.svg);
				}
			}
		}

		.slick-prev{

			&:hover{

				&:before{
					background-image: url(../images/slide-prev-hover-blue.svg);
				}
			}
		}
	}

	.icon-con{
		margin-bottom: 1em;
		margin-bottom: .2em;
		max-width: 240px!important;
		max-width: 120px!important;

		img{
			max-height: 120px;

			@media (min-width: 2132px + 1){
				max-height: 120px * 1.1109;
			}

			@media (min-width: 2346px + 1){
				max-height: 120px * 1.2218;
			}

			@media (min-width: 2558px + 1){
				max-height: 120px * 1.3328;
			}
		}

		@media (max-width: $b2){
			max-width: 180px!important;

			img{
				max-height: 180px;
			}
		}

		@media (max-width: $b3){
			max-width: 120px!important;

			img{
				max-height: 120px;
			}
		}
	}
	
	h2{
		@include font-size(44);

		@media (max-width: 1500px){
			@include font-size(40);
		}

		@media (max-width: $b3){
			@include font-size(30.1665);
			line-height: 1.14em;
		}
	}

	#slick-slide00{

		.row{

			.text-top{
				align-self: flex-start;
			}

			.image-under{
				align-self: baseline;

				@media (min-width: 1920px + 1){

					img{
						width: 92.327%;
					}
				}
			}
		}
	}

	.slide-1,
	.slide-2,
	.slide-3,
	.slide-4,
	.slide-5,
	.slide-6{
//	#slick-slide01,
//	#slick-slide02,
//	#slick-slide03,
//	#slick-slide04,
//	#slick-slide05,
//	#slick-slide06{

		@media (max-width: 1366px){

			@media (min-width: $b3 + 1){
				background-size: auto 89%;
			}
		}

		@media (min-width: 1920px + 1){
			background-size: 60.2% auto;
		}
	}

	.slide-1{
//	#slick-slide01{
		background-image: url(../images/ns1.svg);
		background-repeat: no-repeat;
		background-position: 50% 0;

		@media (max-width: $b2){
			background-position: 38% 0%;
		}

		@media (max-width: $b3){
			background-image: url(../images/ns1m.svg);
			background-position: 62% -50px;

			@media (max-height: 450px){
				background-position: 50% -100px;
			}

			.col-md-6.col-md-6{
				padding: 0px 36px 5px;
				align-self: flex-start;

				@media (max-height: 450px){
					padding: 0px 76px 5px 56px;
				}

				h2{
					@include font-size(30.1665);
					line-height: 1.14em;
				}

				.icon-con{
					max-width: 100%!important;
					width: 100%;
					display: flex;
					align-items: flex-end;
					margin-bottom: 16px;

					&:before{
						content: '';
						background-image: url(../images/n1.svg);
						background-repeat: no-repeat;
						background-size: 70px 145px;
						width: 70px;
						height: 145px;
						margin-right: 26px;

						@media (max-height: 450px){
							margin-left: 6px;
						}
					}

					img{
						width: 76.04px!important;
						margin-bottom: 2px;
					}
				}
			}
		}

		.col-md-6{

			@media (min-width: $b3 + 1){
				margin-left: 50.6%;
				padding-top: 54px;
			}
		}

		*{
			color: $blue;
		}
	}

	.slide-2{
//	#slick-slide02{
		background-image: url(../images/ns2.svg);
		background-repeat: no-repeat;
		background-position: 50% 100%;

		@media (max-width: 1292px){

			@media (min-width: $b3 + 1){
				background-position: 50% 120px;
			}
		}

		@media (max-width: $b3){
			background-image: url(../images/ns2m.svg);
			background-position: 50% 100%;

			@media (max-height: 450px){
				background-position: left 50% bottom -100px;
			}

			.col-md-6.col-md-6{
				padding: 5px 31px 46px 36px;
				align-self: flex-end;

				@media (max-height: 450px){
					padding: 5px 31px 67px 56px;
				}

				h2{
					@include font-size(30.1665);
					line-height: 1.14em;
				}

				.icon-con{
					max-width: 100%!important;
					width: 100%;
					display: flex;
					align-items: flex-end;
					margin-bottom: 16px;

					&:before{
						content: '';
						background-image: url(../images/n2.svg);
						background-repeat: no-repeat;
						background-size: 117px 147px;
						width: 117px;
						height: 147px;
						margin-right: 13px;
					}

					img{
						width: 94.04px !important;
						margin-bottom: -14px;
					}
				}
			}
		}

		.col-md-6{

			@media (min-width: $b3 + 1){
				margin-left: 48.8%;
				margin-left: 47.6%;
				padding-top: 100px;
			}

			@media (max-width: 1333px){

				@media (min-width: $b3 + 1){
					padding-top: 60px;
				}
			}

			.inner{

				@media (min-width: $b3 + 1){
					max-width: 105%;
					width: 105%;
				}
			}
		}

		*{
			color: $green;
		}
	}

	.slide-3{
//	#slick-slide03{
		background-image: url(../images/ns3.svg);
		background-repeat: no-repeat;
		background-position: 50% 0;

		@media (max-width: $b2){
			background-position: 53% 0;
		}

		@media (max-width: $b3){
			background-image: url(../images/ns3m.svg);
			background-position: 62% -50px;

			@media (max-height: 450px){
				background-position: 50% -100px;
			}

			.col-md-6.col-md-6{
				padding: 0px 33px 5px 36px;
				align-self: flex-start;

				@media (max-height: 450px){
					padding: 0px 26px 5px 56px;
					margin-top: -4px;
				}

				h2{
					@include font-size(30.1665);
					line-height: 1.14em;
				}

				.icon-con{
					max-width: 100%!important;
					width: 100%;
					display: flex;
					align-items: flex-end;
					margin-bottom: 16px;

					&:before{
						content: '';
						background-image: url(../images/n3.svg);
						background-repeat: no-repeat;
						background-size: 119px 149px;
						width: 119px;
						height: 149px;
						margin-right: 23px;
						margin-left: 5px;
					}

					img{
						width: 138.04px !important;
						margin-bottom: 5px;
					}
				}
			}
		}

		.col-md-6{

			@media (min-width: $b3 + 1){
				margin-left: 48.3%;
				padding-top: 97px;
			}

			@media (max-width: 1333px){

				@media (min-width: $b3 + 1){
					padding-top: 50px;
				}
			}
		}

		.icon-con{
			max-width: 160px !important;

			@media (min-width: 2132px + 1){
				max-width: 160px * 1.1109!important;
			}

			@media (min-width: 2346px + 1){
				max-width: 160px * 1.2218!important;
			}

			@media (min-width: 2558px + 1){
				max-width: 160px * 1.3328!important;
			}


			@media (max-width: $b3){
				max-width: 120px !important;
			}
		}

		*{
			color: $pink;
		}
	}

	.slide-4{
//	#slick-slide04{
		background-image: url(../images/ns4.svg);
		background-repeat: no-repeat;
		background-position: 50% 100%;

		@media (max-width: $b3){
			background-image: url(../images/ns4m.svg);
			background-position: 50% 100%;

			@media (max-height: 450px){
				background-position: left 50% bottom -100px;
			}

			.col-md-6.col-md-6{
				padding: 5px 31px 46px 36px;
				align-self: flex-end;

				@media (max-height: 450px){
					padding: 5px 31px 71px 56px;
				}

				h2{
					@include font-size(30.1665);
					line-height: 1.14em;
				}

				.icon-con{
					max-width: 100%!important;
					width: 100%;
					display: flex;
					align-items: flex-end;
					margin-bottom: 16px;

					@media (max-height: 450px){
						margin-bottom: 10px;
					}

					&:before{
						content: '';
						background-image: url(../images/n4.svg);
						background-repeat: no-repeat;
						background-size: 129px 145px;
						width: 129px;
						height: 145px;
						margin-right: 13px;
						margin-left: 2px;
					}

					img{
						width: 94.04px !important;
						margin-bottom: -6px;
					}
				}
			}
		}

		.col-md-6{

			@media (min-width: $b3 + 1){
				margin-left: 48.8%;
				padding-top: 184px;

				.inner{
					width: 105%;
					max-width: 105%;
				}
			}

			@media (max-width: 1333px){

				@media (min-width: $b3 + 1){
					padding-top: 60px;
				}
			}
		}

		*{
			color: $blue;
		}
	}

	.slide-5{
//	#slick-slide05{
		background-image: url(../images/ns5.svg);
		background-repeat: no-repeat;
		background-position: 50% 0;

		@media (max-width: $b2){
			background-position: 65% 0;
		}

		@media (max-width: $b3){
			background-image: url(../images/ns5m.svg);
			background-position: 50% -50px;

			@media (max-height: 450px){
				background-position: 50% -100px;
			}

			.col-md-6.col-md-6{
				padding: 0px 33px 5px 36px;
				align-self: flex-start;

				@media (max-height: 450px){
					padding: 0px 84px 5px 56px;
					margin-top: -1px;
				}

				h2{
					@include font-size(30.1665);
					line-height: 1.14em;
				}

				.icon-con{
					max-width: 100%!important;
					width: 100%;
					display: flex;
					align-items: flex-end;
					margin-bottom: 16px;

					@media (max-height: 450px){
						margin-bottom: 13px;
					}

					&:before{
						content: '';
						background-image: url(../images/n5.svg);
						background-repeat: no-repeat;
						background-size: 119px 149px;
						width: 119px;
						height: 149px;
						margin-right: 18px;
						margin-left: 7px;

						@media (max-height: 450px){
							margin-left: 3px;
						}
					}

					img{
						width: 56.04px !important;
						margin-bottom: 5px;
					}
				}
			}
		}

		.col-md-6{

			@media (min-width: $b3 + 1){
				margin-left: 50.4%;
				padding-top: 53px;

				.inner{
					width: 105%;
					max-width: 105%;
				}
			}
		}

		*{
			color: $green;
		}
	}

	.slide-6{
//	#slick-slide06{
		background-image: url(../images/ns6.svg);
		background-repeat: no-repeat;
		background-position: 43% 100%;

		@media (max-width: $b2){
			background-position: 49% 100%;
		}

		@media (max-width: $b3){
			background-position: 63% 100%;
		}

		@media (max-width: $b3){
			background-image: url(../images/ns6m.svg);
			background-position: 50% 100%;

			@media (max-height: 450px){
				background-position: left 50% bottom -100px;
			}

			.col-md-6.col-md-6{
				padding: 5px 31px 46px 36px;
				align-self: flex-end;

				@media (max-height: 450px){
					padding: 5px 31px 64px 56px;
				}

				h2{
					@include font-size(30.1665);
					line-height: 1.14em;
				}

				.icon-con{
					max-width: 100%!important;
					width: 100%;
					display: flex;
					align-items: flex-end;
					margin-bottom: 16px;

					&:before{
						content: '';
						background-image: url(../images/n6.svg);
						background-repeat: no-repeat;
						background-size: 123px 149px;
						width: 123px;
						height: 149px;
						margin-right: 16px;
						margin-left: 6px;
					}

					img{
						width: 94.04px !important;
						margin-bottom: -10px;
					}
				}
			}
		}

		.col-md-6{

			@media (min-width: $b3 + 1){
				margin-left: 43.5%;
				padding-top: 81px;
			}
		}

		*{
			color: $pink;
		}
	}

	.slick-arrow{
		bottom: 2px;

		@media (max-width: $b3){
			bottom: 45px;
		}
	}

	.slick-track{
		display: flex;

		.slick-slide{
			float: none;
			height: auto;
		}
	}

	.container.container{
		max-width: 1220px;

		.row{
			padding-top: 178px;
			min-height: 100vh;

			@media (max-width: 1600px){
				padding-top: 130px;
			}

			@media (max-width: 1366px){
				padding-top: 120px;
			}

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				height: 100vh;
			}

			@media (max-width: $b3){
				padding-top: 100px;

				@media (max-height: 450px){
					padding-top: 27px;
				}
			}
		}

		.text-con.text-con{

			@media (max-width: $b3){
				padding-top: 0px;
				padding-bottom: 100px;
				align-self: center;
			}

			@media (max-height: 500px){
				padding-top: 0;
			}
		}

		.image-under{
			display: flex;
			align-items: flex-end;
			justify-content: center;
			padding-bottom: 65px;
			align-self: baseline;

			@media (max-width: $b4){
				margin-top: -10px;
			}

			@media (min-width: $b3 + 1){
				max-height: calc(100vh - 389px);
				max-height: calc(100vh - 240px);
			}

			img{
				max-height: 100%;
				max-height: calc(100vh - 389px);
				max-height: calc(100vh - 320px);
			}

			@media (max-width: 1366px){

				img{
					max-width: 60%;
				}
			}

			@media (max-width: 1050px){

				img{
					max-width: 85%;
				}
			}

			@media (max-width: 900px){

				img{
					max-width: calc(100% - 60px);
				}
			}

			@media (max-width: $b3){
				max-height: none;

				img{
					max-width: 100%;
					max-height: none;
				}
			}

			@media (max-height: 500px){
				padding-bottom: 65px;

				img{
					max-width: 40%;
				}

				@media (max-width: $b3){

					img{
						max-width: 60%;
					}
				}
			}
		}

		.text-top{
			text-align: center;
			align-self: flex-start;

			h2{
				@include font-size(40);

				@media (max-width: $b3){
					//max-width: 270px;
					margin-left: auto;
					margin-right: auto;

					em{
						display: block;
					}

					br{
						display: none;
					}
				}

//				@media (max-height: 500px){
//					@include font-size(21);
//					max-width: none;
//				}
			}

			h2{
				@include font-size(30.1665);
				line-height: 1.14em;
			}

			.inner{
				width: 840px;
				margin: 0 auto;
				margin-bottom: 2.1em;
				max-width: 100%;

				em{
					color: $blue;
					font-style: normal;
				}
			}
		}
	}
}

.coloured-text-with-image-and-icon-section{
	position: relative;
	overflow: hidden;
	margin-bottom: -1px;

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		.img-con img{
			transform: translateY(100%);
			opacity: 0;
			visibility: hidden;
		}

		&.animate{

			.img-con img{
				transform: translateY(0%);
				opacity: 1;
				visibility: visible;
				transition: .4s .4s;
			}
		}
	}

	&.colour-grey{
		background-color: $grey;

		ul{

			li{

				&:before{
					background-color: $green;
				}
			}
		}
	}

	&.colour-blue{
		background-color: $blue;

		ul{

			li{

				&:before{
					background-color: $green;
				}
			}
		}
	}

	&.colour-light-grey{
		background-color: $grey-lighter;

		*{
			color: $grey;
		}

		.icon-con .i-text,
		h2{
			color: $blue;
		}

		ul{

			li{

				&:before{
					background-color: $blue;
				}
			}
		}
	}

	&.side-left{

		.img-side{
			order: 2;

			img{
				right: 0;

				@media (max-width: $b1){

					@media (min-width: $b3 + 1){
						right: -20%
					}
				}

				@media (max-width: $b3){
					right: -15px;
					float: right;
				}
			}
		}

		.text-side{
			order: 1;
		}
	}

	&.side-right{

		.img-side{

			img{
				left: 0;

				@media (max-width: $b1){

					@media (min-width: $b3 + 1){
						left: -20%
					}
				}

				@media (max-width: $b3){
					left: -15px;
				}
			}
		}
	}

	h2{
		margin-bottom: .8em;
	}

	h4{
		line-height: 1.1em;
		letter-spacing: 0.01em;
	}

	.icon-con{
		display: flex;
		align-items: center;
		margin-top: 3em;

		@media (max-width: 1366px){
			margin-top: 2em;
		}

		.i-text{
			@include font-size(30);
			margin-left: 10px;
			line-height: 1.2em;
			font-family: $alt-font;
		}
	}

	.row{
		padding-top: 160px;
		align-items: center;
		min-height: 100vh;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}

		@media (max-width: $b3){
			padding-top: 100px;
		}
	}

	.img-side{
		align-self: flex-end;
		position: static;

		@media (max-width: $b3){
			order: 2;
		}

		img{
			position: absolute;
			bottom: 0;
			max-width: 50%;

			@media (max-width: $b1){

				@media (min-width: $b3 + 1){
					max-width: 70%;
				}
			}

			@media (max-width: $b3){
				position: relative;
				max-width: 50%;
			}
		}
	}

	*{
		color: white;
	}

	.img-side{

		@media (min-width: $b3 + 1){
			padding-left: 76px;
		}

		@media (max-width: $b3){
			display: none;
		}
	}

	.text-side{
		padding-bottom: 50px;

		@media (min-width: $b3 + 1){
			padding-left: 10px;
		}
	}

	.text{
		margin-bottom: 1.1em;
	}

	.text-column{

		@media (min-width: $b2 + 1){
//			column-count: 2;
//			column-gap: 26px;

			display: flex;
			justify-content: space-between;

			p,
			ul{
				flex: 0 0 calc(50% - 13px);
			}

			ul{
				break-inside: avoid;
				break-inside: avoid-column;

				li{
					break-inside: avoid;
				}
			}
		}

		p{
			break-inside: avoid;
		}
	}

	.btn-con{
		padding-top: 52px;
	}
}




.step-slide-section{
	background-color: #A1A1A1;

	.fp-slidesNav.fp-slidesNav.fp-slidesNav{
		display: none;
	}

	.fp-controlArrow.fp-controlArrow.fp-controlArrow{
		top: 50%;
		transform: translateY(-50%);

		@media (min-width: $b2 + 1){
			top: 83%;
		}

		&.fp-next{
			@media (min-width: $b2 + 1){
				right: calc(50vw - 370px);
			}
		}

		&.fp-prev{
			@media (min-width: $b2 + 1){
				left: calc(50vw - 370px);
			}
		}
	}

	.row{
		padding-top: 185px;
		padding-bottom: 63px;
		height: 100vh;

		@media (max-width: 1366px){
			padding-top: 145px;
		}

		@media (max-width: 1050px){
			padding-top: 100px;
			padding-bottom: 0;
			height: auto;
			min-height: 100vh;
		}
	}

	.container{
		max-width: 1143px;
	}

	.step-slide{

		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 770px;
			height: 364px;
			background-image: url(../images/over.svg);
			background-repeat: no-repeat;
			background-position: 50% 0;
			background-size: auto 364px;
			pointer-events: none;

			@media (min-width: 2132px + 1){
				width: 770px * 1.1109;
				height: 364px * 1.1109;
				background-size: auto 364px * 1.1109;
			}

			@media (min-width: 2346px + 1){
				width: 770px * 1.2218;
				height: 364px * 1.2218;
				background-size: auto 364px * 1.2218;
			}

			@media (min-width: 2558px + 1){
				width: 770px * 1.3328;
				height: 364px * 1.3328;
				background-size: auto 364px * 1.3328;
			}

			@media (min-width: $b3 + 1){

				@media (max-height: 732px){
					height: 269px;
					background-size: auto 269px;
					background-position: 50% 50%;
					box-shadow: 0 0 0 10px #A1A1A1;
					top: 8px;
				}
			}

			@media (max-width: $b3){
				height: 269px;
				background-size: auto 269px;
				background-position: 50% 50%;
				box-shadow: 0 0 0 10px #A1A1A1;
				top: 8px;
			}
		}
	}

	.slick-slide{

		.img-con{
			height: 364px;
			display: flex;
			justify-content: center;
			align-items: center;

			@media (min-width: 2132px + 1){
				height: 364px * 1.1109;

				img{
					width: 365px * 1.1109;
				}
			}

			@media (min-width: 2346px + 1){
				height: 364px * 1.2218;

				img{
					width: 365px * 1.2218;
				}
			}

			@media (min-width: 2558px + 1){
				height: 364px * 1.3328;

				img{
					width: 365px * 1.332;
				}
			}

			@media (min-width: $b3 + 1){

				@media (max-height: 732px){
					height: 284px;

					img{
						max-height: 284px;
					}
				}
			}

			@media (max-width: $b3){
				height: 284px;

				img{
					max-height: 284px;
				}
			}


		}

		.text-con{
			flex-grow: 1;
			display: flex;
			align-items: flex-start;
			padding: 18px 0;

			@media (max-width: $b3){
				padding: 10px 15px 70px;
			}

			h6{
				@include font-size(25);
				font-weight: 900;
				margin-bottom: 0;

				@media (max-width: 1333px){
					@include font-size(20);
				}

				@media (max-width: $b2){
					@include font-size(20/$divider);
				}
			}

			p{
				@include font-size(20);
				line-height: 1.1em;

				@media (max-width: 1333px){
					@include font-size(16);
				}

				@media (max-width: $b2){
					@include font-size(16/$divider);
				}
			}

			.text{
				width: 600px;
				margin: 0 auto;
			}
		}
	}

	h2{

		@media (max-width: 1200px){
			@include font-size(30);
		}
	}

	.fp-slidesContainer{
		max-width: none;
	}

	*{
		color: white;
	}

	.title{
		padding-bottom: 8px;
	}

	.step-slide{
		width: 660px;
		margin: 0 auto;
		max-width: 100%;

		@media (min-width: 2132px + 1){
			width: 660px * 1.1109;
		}

		@media (min-width: 2346px + 1){
			width: 660px * 1.2218;
		}

		@media (min-width: 2558px + 1){
			width: 660px * 1.3328;
		}

		@media (max-width: $b2){
			width: 550px;
		}

		@media (max-width: $b3){
			width: calc(100% - 70px);
			max-width: 660px;
		}

		.img-con{

			img{
				margin: 0 auto;
			}
		}

		.slick-next{
			right: -60px;

			@media (max-width:$b2){
				right: -65px;
				bottom: 11px;
			}

			@media (max-width: $b3){
				right: -41px;
				bottom: 84px;
			}

			@media (min-width: 1024px + 1){
				&:hover:before{
					background-image: url(../images/slide-next-hover-green.svg);
					background-repeat: no-repeat;
				}
			}
		}

		.slick-prev{
			left: -60px;

			@media (max-width: $b2){
				left: -65px;
				bottom: 11px;
			}

			@media (max-width: $b3){
				left: -41px;
				bottom: 84px;
			}

			@media (min-width: 1024px + 1){
				&:hover:before{
					background-image: url(../images/slide-prev-hover-green.svg);
					background-repeat: no-repeat;
				}
			}
		}

		.slick-track{
			display: flex;
			align-items: stretch;

			.slick-slide{
				height: auto;
				float: none;
				display: flex;
				flex-direction: column;
			}
		}
	}
}

.quote-slide-section{

	.slick-arrow{
		z-index: 999;

		@media (max-width: $b3){

			&.slick-next:hover:before{
				background-image: url(../images/slide-next.svg);
			}

			&.slick-prev:hover:before{
				background-image: url(../images/slide-prev.svg);
			}
		}
	}



	.fp-slidesContainer{
		max-width: none;
	}

	.slick-track{
		display: flex;
	}

//	.slide{
//
//		&:nth-of-type(3n + 1){
//
//			blockquote{
//
//				&:before{
//					color: $blue;
//				}
//			}
//		}
//
//		&:nth-of-type(3n + 2){
//
//			blockquote{
//
//				&:before{
//					color: $green;
//				}
//			}
//		}
//
//		&:nth-of-type(3n + 3){
//
//			blockquote{
//
//				&:before{
//					color: $pink;
//				}
//			}
//		}
//	}

	.slick-slide{
		min-height: 100vh;
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		background-position: 50% 100%;
		float: none;
		position: relative;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100vh;
		}

		.btn{
			position: absolute;
			bottom: 24px;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: rgba(black, .25);
			border-color: rgba(black, .0);
			color: white!important;

			&:hover{
				background-color: $green;
				border-color: $green;
				color: white!important;
			}
		}

//		&:nth-of-type(3n + 2){
//
//			blockquote{
//
//				&:before{
//					color: $blue;
//				}
//			}
//		}
//
//		&:nth-of-type(3n + 3){
//
//			blockquote{
//
//				&:before{
//					color: $green;
//				}
//			}
//		}
//
//		&:nth-of-type(3n + 1){
//
//			blockquote{
//
//				&:before{
//					color: $pink;
//				}
//			}
//		}
	}

	*{
		color: white;
	}

	.quote-con{
		width: 910px;
		padding: 0 15px;
		margin: 0 auto;

		@media (min-width: 2132px + 1){
			width: 910px * 1.1109;
		}

		@media (min-width: 2346px + 1){
			width: 910px * 1.2218;
		}

		@media (min-width: 2558px + 1){
			width: 910px * 1.3328;
		}

		@media (max-width: 1006px){

			@media (min-width: $b3 + 1){
				width: 100%;
				padding: 0 60px;
			}
		}
	}

	blockquote{
		@include font-size(39.64);
		font-weight: 700;
		font-family: $main-font;
		margin-bottom: .45em;
		padding-top: 95px;
		position: relative;

		@media (max-height: 510px){
			padding-top: 100px;
		}

		@media (max-width: $b2){
			@include font-size(34);
		}

		@media (max-width: $b3){
			@include font-size(28/$divider);
		}

		&:before{
			content: '“';
			position: absolute;
			top: 57px;
			left: 2px;
			@include font-size(144.15);

			@media (max-height: 510px){
				top: 80px;
			}

			@media (max-width: $b2){

				@include font-size(144.15/$divider);
			}
		}
	}

	.by{
		@include font-size(20);
		font-weight: 600;

		@media (max-width: $b2){
			@include font-size(20/$divider);
		}
	}

	img{
		margin-top: 3.3em;

		@media (max-width: $b3){
			margin-top: 1.7em;
			margin-bottom: 0;
		}
	}

	@media (max-width: $b3){

		.slick-arrow{
			bottom: 64px;
		}
	}

	@media (min-width: 1024px + 1){
		.slick-next{

			&:hover:before{
				background-image: url(../images/slide-next-hover-green.svg);
				background-repeat: no-repeat;
			}
		}

		.slick-prev{

			&:hover:before{
				background-image: url(../images/slide-prev-hover-green.svg);
				background-repeat: no-repeat;
			}
		}
	}
}


.submit-con.submit-con.submit-con{
	justify-content: flex-end;
	align-items: center;
	flex-wrap: wrap;

	.cf7sr-g-recaptcha{
		margin-bottom: 10px;
	}
}

@media (max-width: $b3){

	.container-fluid,
	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: 15px;
		padding-left: 15px;
	}

	.row{
		margin-right: -15px;
		margin-left: -15px;
	}
}

/*!
 * fullPage 3.0.8
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
html.fp-enabled,
.fp-enabled body {
	margin: 0;
	padding: 0;
	overflow:hidden;

	/*Avoid flicker on slides transitions for mobile phones #336 */
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
.fp-section {
	position: relative;
	-webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
	-moz-box-sizing: border-box; /* <=28 */
	box-sizing: border-box;
}
.fp-slide {
	float: left;
}
.fp-slide, .fp-slidesContainer {
	height: 100%;
	display: block;
}
.fp-slides {
	z-index:1;
	height: 100%;
	overflow: hidden;
	position: relative;
	-webkit-transition: all 0.3s ease-out; /* Safari<=6 Android<=4.3 */
	transition: all 0.3s ease-out;
}
.fp-section.fp-table, .fp-slide.fp-table {
	display: table;
	table-layout:fixed;
	width: 100%;
}
.fp-tableCell {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}
.fp-slidesContainer {
	float: left;
	position: relative;
}
.fp-controlArrow {
	-webkit-user-select: none; /* webkit (safari, chrome) browsers */
	-moz-user-select: none; /* mozilla browsers */
	-khtml-user-select: none; /* webkit (konqueror) browsers */
	-ms-user-select: none; /* IE10+ */
	position: absolute;
	z-index: 4;
	top: 50%;
	cursor: pointer;
	width: 0;
	height: 0;
	border-style: solid;
	margin-top: -38px;
	-webkit-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}
.fp-controlArrow.fp-prev {
	left: 15px;
	width: 0;
	border-width: 38.5px 34px 38.5px 0;
	border-color: transparent #fff transparent transparent;
}
.fp-controlArrow.fp-next {
	right: 15px;
	border-width: 38.5px 0 38.5px 34px;
	border-color: transparent transparent transparent #fff;
}
.fp-scrollable {
	overflow: hidden;
	position: relative;
}
.fp-scroller{
	overflow: hidden;
}
.iScrollIndicator{
	border: 0 !important;
}
.fp-notransition {
	-webkit-transition: none !important;
	transition: none !important;
}
#fp-nav {
	position: fixed;
	z-index: 100;
	margin-top: -32px;
	top: 50%;
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
}
#fp-nav.fp-right {
	right: 17px;
}
#fp-nav.fp-left {
	left: 17px;
}
.fp-slidesNav{
	position: absolute;
	z-index: 4;
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
	left: 0 !important;
	right: 0;
	margin: 0 auto !important;
}
.fp-slidesNav.fp-bottom {
	bottom: 17px;
}
.fp-slidesNav.fp-top {
	top: 17px;
}
#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
}
#fp-nav ul li,
.fp-slidesNav ul li {
	display: block;
	width: 14px;
	height: 13px;
	margin: 7px;
	position:relative;
}
.fp-slidesNav ul li {
	display: inline-block;
}
#fp-nav ul li a,
.fp-slidesNav ul li a {
	display: block;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
}
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span{
	height: 12px;
	width: 12px;
	margin: -6px 0 0 -6px;
	border-radius: 100%;
 }
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
	border-radius: 50%;
	position: absolute;
	z-index: 1;
	height: 4px;
	width: 4px;
	border: 0;
	background: #333;
	left: 50%;
	top: 50%;
	margin: -2px 0 0 -2px;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span{
	width: 10px;
	height: 10px;
	margin: -5px 0px 0px -5px;
}
#fp-nav ul li .fp-tooltip {
	position: absolute;
	top: -2px;
	color: #fff;
	font-size: 14px;
	font-family: arial, helvetica, sans-serif;
	white-space: nowrap;
	max-width: 220px;
	overflow: hidden;
	display: block;
	opacity: 0;
	width: 0;
	cursor: pointer;
}
#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
	-webkit-transition: opacity 0.2s ease-in;
	transition: opacity 0.2s ease-in;
	width: auto;
	opacity: 1;
}
#fp-nav ul li .fp-tooltip.fp-right {
	right: 20px;
}
#fp-nav ul li .fp-tooltip.fp-left {
	left: 20px;
}
.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell{
	height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
	height: auto !important;
}

/*Only display content to screen readers*/
.fp-sr-only{
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.wpcf7-mail-sent-ok{
	display: none!important;
}


.label-move{
	position: relative;

	&.selected{

		label{
			transform: translateY(-17px);
			font-size: 11px;
		}
	}

	label{
		display: block;
		padding: 12px 0;
		@include font-size(15);
		font-weight: 300;
		line-height: 1.3em;
		margin-bottom: 0;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
		transition: $time;
	}
}

.four-section{
	overflow: hidden;

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;


		.img-side{
			opacity: 0;
			visibility: hidden;
			transform: translateY(100%);
		}

		&.animate{

			.img-side{
				opacity: 1;
				visibility: visible;
				transform: translateY(0%);
				transition: .4s .4s;
			}
		}
	}

	.row{
		min-height: 100vh;
		align-items: center;
		padding-top: 160px;
		padding-bottom: 160px;

		@media (max-width: $b3){
			min-height: 0;
			padding-top: 100px;
			padding-bottom: 100px;
		}

	}

	.img-side{
		display: flex;
		justify-content: center;

		@media (max-width: $b3){
			order: 2;
		}
	}

	.text-side{

		@media (max-width: $b3){
			order: 1;
			text-align: center;
			padding-bottom: 10px;
		}
	}

	h1{
		@include font-size(130);
		margin-bottom: 0.03em;
		color: $pink;
		font-weight: 700;

		@media (max-width: $b3){
			@include font-size(100);
		}
	}

	h2{
		color: $blue;
		@include font-size(50);
		line-height: 1em;

		@media (max-width: $b3){
			@include font-size(35);
		}
	}
}

html body{

	.pink-text-with-image-section,
	.pink-alt-text-with-image-section,
	.grey-alt-text-with-image-section,
	.grey-text-with-image-section,
	.clients-section,
	.green-text-with-blocks-section,
	.awards-section,
	.text-with-stats-section,
	.contact-section,
	.form-section,
	.coloured-text-with-image-and-icon-section,
	.step-slide-section,
	.pink-text-with-blocks-section,
	.grey-text-with-icon-section{

		h2{
			@include font-size(40);

			@media (max-width: 1500px){
				@include font-size(40);
			}

			@media (max-width: $b2){
				@include font-size(30);
			}
		}
	}

	.grey-form-section{

		h2{
			@include font-size(60);

			@media (max-width: 1500px){
				@include font-size(46.4286);
			}
		}
	}
}

.news-hero{
	padding-top: 168px;

	@media (max-width: $b3){
		padding-top: 100px;
	}

	.container{
		max-width: 1160px;

		.img-con{
			padding-top: 63.5%;
			position: relative;
			overflow: hidden;

			.over{
				position: absolute;
				left: 50%;
				width: 100%;
				bottom: -2px;
				transform: translateX(-50%);
			}
		}
	}
}

.news-single-section{
	padding-top: 41px;

	.title-con{

		h2{

			@media (min-width: 1500px + 1){
				@include font-size(66);
				line-height: 1.05em;
			}
		}
	}

	.info{
		padding-top: 18px;
		padding-bottom: 18px;

		.date{
			color: $blue;
			@include font-size(15);
			display: flex;
			flex-wrap: wrap;

			@media (max-width: $b3){
				@include font-size(13);
			}

			div{

				&:after{
					content: ', ';
					margin-right: 0.25em;
				}

				&:last-of-type{

					&:after{
						content: ' | ';
						margin-right: 0.25em;
					}
				}
			}
		}

		.col-12{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.share{
			display: flex;
			justify-content: flex-end;

			a{
				width: 30px;
				line-height: 30px;
				@include font-size(20);
				font-weight: 800;
				font-family: $alt-font;
				text-align: center;
				color: white;
				background-color: $green;
				margin-left: 19px;
				display: block;
				text-decoration: none;

				@media (max-width: $b3){
					margin-left: 10px;
				}

				&:hover,
				&:focus{
					background-color: $blue;
				}

			}
		}
	}

	.content{
		padding-top: 67px;
		padding-bottom: 50px;

		@media (max-width: $b3){
			padding-top: 30px;
		}

		.col-12{
			max-width: 828px;
			margin: 0 auto;

			@media (min-width: 2132px + 1){
				max-width: 828px * 1.1109;
			}

			@media (min-width: 2346px + 1){
				max-width: 828px * 1.2218;
			}

			@media (min-width: 2558px + 1){
				max-width: 828px * 1.3328;
			}
		}

		h5{
			margin-bottom: 1.6em;

			@media (min-width: 1500px + 1){
				@include font-size(25);
				line-height: 1.2em;
			}
		}

		@media (max-width: $b2){

			li,
			p{
				@include font-size(15);
			}
		}
	}
}

.back-section{
	padding-top: 28px;
	padding-bottom: 101px;

	@media (max-width: $b3){
		padding-bottom: 52px;
	}

	.col-12{
		display: flex;
		justify-content: center;
		align-items: center;

		a{
			text-transform: uppercase;
			color: $blue!important;

			&:hover,
			&:focus{
				color: white!important;
			}
		}
	}
}

.recent-posts{

	.container{
//		padding-right: 10px;
//		padding-left: 10px;

		.row{
			margin-right: -10px;
			margin-left: -10px;

			.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
				padding-right: 10px;
				padding-left: 10px;
			}
		}
	}

	h4{
		color: $blue;

		@media (min-width: 1500px + 1){
			@include font-size(30);
		}

	}
}

.news-section{
	padding-top: 183px;
	padding-bottom: 183px;

	@media (max-width: $b3){
		padding-top: 100px;
		padding-bottom: 54px;
	}

	&.simple{
		padding-top: 75px;
		padding-bottom: 30px;

		.top{

			.title-con{
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				margin-bottom: 2.42em;

				h2{
					font-weight: 900;
					color: $blue;

					@media (min-width: 1500px + 1){
						@include font-size(40);
					}
				}

				.btn.trans{
					background-color: transparent;
					color: $grey!important;

					@media (min-width: $b3 + 1){
						margin-top: 0.45em;
					}
			
					@media (max-width: $b3){
						width: 100%;
					}
			
					&:hover,
					&:focus{
						background-color: $green;
						border-color: $green;
						color: $colour!important;
					}
				}
			}
		}

		.middle{

			&:after{
				display: none;
			}
		}
	}

	.titles{
		max-width: 800px;

		span{
			color: #A0A0A0;
			@include font-size(100);
			line-height: .9em;
			display: inline-block;
			font-family: $alt-font;
			font-weight: 600;
			text-transform: uppercase;
			margin-right: .2em;

			@media (max-width: 1500px){
				@include font-size(75);
			}

			@media (max-width: $b2){
				@include font-size(34);
				line-height: 1.1em;
			}

			&:after{
				content: ', ',
			}

			&:nth-of-type(2){

				&:after{
					content: ' ',
				}
			}

			&:nth-of-type(1){
				color: $blue;
			}

			&:last-of-type{
				color: #444444;

				&:first-of-type{
					color: $blue;

					&:before{
						content: '',
					}
				}

				&:before{
					content: '& ',
				}

				&:after{
					content: '',
				}
			}
		}
	}

	.filter-con{

		.loading{
			pointer-events: none;
		}

		.fliter{
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 208px;
			flex-wrap: wrap;

			@media (max-width: $b3){
				padding-top: 60px;
			}

			a{
				text-align: center;
				min-width: 120px;
				padding: 8px 5px;
				border-radius: 20px;
				background-color: white;
				color: $colour;
				@include font-size(15);
				font-weight: 600;
				font-family: $alt-font;
				margin: 0 10px 5px;
				text-decoration: none;
				text-transform: uppercase;

				@media (min-width: 2132px + 1){
					padding: 8px 5px * 1.1109;
					margin: 0 10px * 1.3328 5px * 1.3328;
					min-width: 120px * 1.3328;
				}

				@media (min-width: 2346px + 1){
					padding: 8px 5px * 1.2218;
					margin: 0 10px * 1.3328 5px * 1.3328;
					min-width: 120px * 1.3328;
				}

				@media (min-width: 2558px + 1){
					padding: 8px 5px * 1.3328;
					margin: 0 10px * 1.3328 5px * 1.3328;
					min-width: 120px * 1.3328;
				}

				@media (max-width: $b3){
					margin: 0 2.5px 5px;
				}

				&.active,
				&:hover{
					color: $green;
				}

				&.active{
					pointer-events: none;
				}
			}
		}
	}

	.container{
//		padding-right: 10px;
//		padding-left: 10px;

		.row{
			margin-right: -10px;
			margin-left: -10px;

			.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
				padding-right: 10px;
				padding-left: 10px;
			}
		}
	}

	.middle{
		padding-top: 29px;
		position: relative;

		&:after{
			content: '';
			position: absolute;
			padding-top: 84.8%;
			width: 84.8%;
			border-radius: 10000px;
			background-color: #EBEBEB;
			background-color: #dadada;
			left: 50%;
			transform: translateX(-50%);
			top: -203px;
			z-index: -1;

			@media (max-width: $b3){
				padding-top: 125%;
				width: 110%;
				max-width: 110%;
			}
		}
	}

	#blogLoadMore{
		padding-top: 29px;

		&.no-more{
			display: none;
		}

		.loading{
			pointer-events: none;
		}

		.ajax-btn-con{
			display: flex;
			justify-content: center;
			align-items: center;

			a{
				text-transform: uppercase;
			}
		}
	}
}

.n-item{
	margin-bottom: 73px;
	display: flex;
	flex-direction: column;

	@media (max-width: $b3){
		margin-bottom: 25px;
	}

	&.press{

		.img-con{
			position: relative;
			padding-top: 67.025%;

			img{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: auto;
				max-height: calc(100% - 20px);
			}
		}

	}

	img{
		width: 100%;
	}

	a{
		position: absolute;
		top: 0;
		right: 10px;
		bottom: 0;
		left: 10px;
		z-index: 1;

		&:hover,
		&:focus{

			~ .text-con{

				h4{
					color: $blue;
				}
			}
		}
	}

	.text-con{
		background-color: white;
		padding: 10px 19px 35px;
		flex-grow: 1;
		position: relative;

		*{
			color: black;
		}

		h4{
			font-weight: 700;
			font-family: $main-font;
			margin-bottom: 2.7em;
			transition: $time;

			@media (min-width: 1500px + 1){
				@include font-size(20);
			}

			@media (max-width: $b3){
				margin-bottom: 1.5em;
			}
		}

		.date{
			position: absolute;
			left: 19px;
			bottom: 20px;
			display: flex;
			flex-wrap: wrap;
			width: calc(100% - 19px - 19px);

			div{

				&:after{
					content: ', ';
					margin-right: 0.25em;
				}

				&:last-of-type{

					&:after{
						content: ' | ';
						margin-right: 0.25em;
					}
				}
			}
		}
	}
}

.fancybox-slide--inline{
	
	video{
		padding: 0;
		width: 1400px;
		max-width: calc(100% - 88px);
	}
}

.solution-section{

	.slick-arrow{
		z-index: 999;

		@media (max-width: $b3){

			&.slick-next:hover:before{
				background-image: url(../images/slide-next.svg);
			}

			&.slick-prev:hover:before{
				background-image: url(../images/slide-prev.svg);
			}
		}
	}

	&:nth-of-type(6){

		.slide-2{

			.col-md-6.col-md-6.col-md-6.col-md-6.col-md-6.col-md-6{

				@media (min-width: $b3 + 1){

					column-count: unset;
					display: flex;
					justify-content: space-between;

					p{
						width: calc(50% - 11px);
					}
				}
			}

		}
	}

	.slick-track{
		display: flex;

		.slide{
			float: none;
			height: auto;
		}
	}

	.slick-arrow.slick-arrow.slick-arrow{
		//bottom: 40px;
	}

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		.slide-1{

			.text-side{
				transform: translateY(-100%);
				opacity: 0;
				visibility: hidden;
				transition: .4s;
			}

			.title-con{

				div{

					&:nth-of-type(2),
					&:nth-of-type(3),
					&:nth-of-type(4){
						transform: translateY(-114%);
						opacity: 0;
						visibility: hidden;
					}

					&:nth-of-type(3){
						transform: translateY(-290%);
					}

					&:nth-of-type(4){
						transform: translateY(-534%);
					}
				}
			}
		}

		&.animate{

			.slide-1{

				.text-side{
					transform: translateY(0%);
					opacity: 1;
					visibility: visible;
				}

				.title-con{

					div{

						&:nth-of-type(2),
						&:nth-of-type(3),
						&:nth-of-type(4){
							transform: translateY(0%);
							transition: .4s .4s;
							opacity: 1;
							visibility: visible;
						}

						&:nth-of-type(2){
							opacity: .5;
						}

						&:nth-of-type(3){
							opacity: .25;
						}

						&:nth-of-type(4){
							opacity: .1;
						}
					}
				}
			}
		}
	}

	&.colour-blue{
		background-color: $blue;

		.btn{
			background-color: $green;
			border-color: $green;
			color: $colour!important;

			&:hover,
			&:focus{
				background-color: #282ca4;
				border-color: #282ca4;
				color: white!important;
			}

			&.white{
				background-color: $pink;
				border-color: $pink;
				color: $white!important;

				&:hover,
				&:focus{
					background-color: #282ca4;
					border-color: #282ca4;
					color: white!important;
				}
			}
		}
	}

	&.colour-grey{
		background-color: $grey;

		.btn{
			background-color: $green;
			border-color: $green;
			color: $colour!important;

			&:hover,
			&:focus{
				background-color: #282ca4;
				border-color: #282ca4;
				color: white!important;
			}

			&.white{
				background-color: $pink;
				border-color: $pink;
				color: $colour!important;

				&:hover,
				&:focus{
					background-color: #282ca4;
					border-color: #282ca4;
					color: white!important;
				}
			}
		}
	}

	&.colour-pink{
		background-color: $pink;

		.btn{
			background-color: $green;
			border-color: $green;
			color: $colour!important;

			&:hover,
			&:focus{
				background-color: #282ca4;
				border-color: #282ca4;
				color: white!important;
			}

			&.white{
				background-color: $white;
				border-color: $white;
				color: $colour!important;

				&:hover,
				&:focus{
					background-color: #282ca4;
					border-color: #282ca4;
					color: white!important;
				}
			}
		}
	}

	&.colour-green{
		background-color: $green-alt;

		.btn{
			background-color: $pink;
			border-color: $pink;
			color: $colour!important;

			&:hover,
			&:focus{
				background-color: #282ca4;
				border-color: #282ca4;
				color: white!important;
			}

			&.white{
				background-color: $white;
				border-color: $white;
				color: $colour!important;

				&:hover,
				&:focus{
					background-color: #282ca4;
					border-color: #282ca4;
					color: white!important;
				}
			}
		}
	}

	.slick-next:hover{

		&:before{
			background-image: url(../images/slide-next-hover-green.svg);
		}
	}

	h4{

		@media (max-width: 1300px){

			@media (min-width: $b3 + 1){
				@include font-size(20);
			}
		}
	}

	.slick-prev:hover{

		&:before{
			background-image: url(../images/slide-prev-hover-green.svg);
		}
	}

	.row{
		padding-top: 150px;
		padding-bottom: 150px;
		min-height: 100vh;

		@media (max-width: 1500px){
			padding-top: 115px;
			padding-bottom: 125px;
		}

		@media (max-width: 1300px) and (min-width: 768px){
			padding-top: 145px;
		}

		@media (max-width: $b2){
			padding-bottom: 125px;
		}

		@media (max-width: $b3){
			padding-top: 100px;
			padding-bottom: 75px;
			min-height: 0;
		}
	}

	.slide{

		.container{
			max-width: 1220px;
		}

		&.slide-1{

			.title-side{
				align-self: center;

				@media (max-width: $b3){
					align-self: flex-start;
				}
			}

			.title-con{
				z-index: 1;

				@media (max-width: $b3){
					margin-top: 0;
				}
			}

		}

		&.slide-2{

			.row{
				min-height: 100vh;
			}

			&:after{
				content: '';
				position: absolute;
				left: 64.8%;
				transform: translate(-50%, -50%);
				top: 44.7%;
				width: 156.5%;
				max-width: none;
				padding-top: 17.9%;
				padding-top: 28.3%;
				background-size: 100% auto;
				background-image: url(../images/sol-back.svg);
				background-repeat: no-repeat;
				pointer-events: none;

				@media (max-width: $b3){
					width: 276.5%;
					padding-top: 50.3%;
					left: 58.8%;
				}
			}

			.col-md-6{
				align-self: flex-end;

				@media (max-width: 1300px){
					width: 100%;
					flex: 0 0 100%;
				}

				@media (min-width: $b3 + 1){
					column-count: 2;
					column-gap: 22px;

					p{
						margin-bottom: 1.15em;
						//padding-bottom: 1.15em;
					}
				}

				p{
					break-inside: avoid-column;
				}
			}
		}

		&.slide-3{

			&.waypoint{
				transform: none;
				opacity: 1;
				visibility: visible;

				.top{
					transform: translateY(-100%);
					pointer-events: none;
					opacity: 0;
					visibility: hidden;

					@media (max-width: $b3){
						opacity: 0;
						visibility: hidden;
						left: 50%;
						transform: translateX(-50%) translateY(-100%);
					}
				}

				.lower{
					transform: translateY(100%);
				}

				&.animate{

					.top,
					.lower{
						transform: translateY(0%);
						transition: .4s .4s;
					}

					.top{
						opacity: 1;
						visibility: visible;

						@media (max-width: $b3){
							opacity: 1;
							visibility: visible;
							transform: translateX(-50%) translateY(0%);
						}
					}
				}
			}

			.row{
				//padding-top: 100px;
				padding-bottom: 0;
				align-items: center;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					height: 100vh;
				}
			}

			.img-side{
				align-self: flex-end;

				@media (max-width: 1500px){

					img{
						width: 75%;
						margin: 0 auto;
					}
				}

				@media (max-width: 1400px){
					img{
						width: 57%;
					}
				}

				@media (max-width: $b3 + 10px){

					img{
						width: 100%;
					}
				}

				@media (max-width: $b3){
					display: none;
				}

				@media (max-width: 1333px){

					@media (min-width: $b3 + 1){

						img{
							max-width: 335px;
						}
					}
				}

				@media (max-width: $b3){
					order: 2;

					img{
						margin: 0 auto;
					}
				}

				@media (min-width: 1920px + 1){

					img{
						width: 86.111%;
					}
				}
			}

			*{
				color: white;
			}

			.img-side{

				@media (min-width: $b3 + 1){
					padding-left: 76px;
				}
			}

			h5{

				@media (max-width: 1500px){
					margin-bottom: .85em;
				}
			}

			.img-con{
				position: relative;

				.top{
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;

					@media (max-width: 1500px){
						left: 50%;
						transform: translateX(-50%);
					}

					@media (max-width: 900px){
						max-width: 100%;
					}

					@media (max-width: $b3){
						left: 50%;
					}
				}

				.lower{
					position: relative;
					z-index: 2;

					@media (max-width: 900px){
						max-width: 100%;
					}
				}
			}

			.text-side{
				padding-bottom: 50px;
				padding-bottom: 0;

				@media (max-width: $b3){
					align-self: flex-start;
					padding-top: 0;
					padding-bottom: 120px;
				}

				@media (min-width: $b3 + 1){
					padding-left: 10px;
					padding-top: 0px;
					align-self: stretch;
					position: relative;
					padding-bottom: 110px;

					.btn-con{
						position: absolute;
						bottom: 47px;

						@media (max-width: $b2){
							bottom: 49px;
						}

						@media (max-width: 925px){
							bottom: 49px;
							left: -67px;
							z-index: 3;
						}
					}
				}

				.btn{
					margin-right: 15px;

					@media (max-width: 1100px){
						margin-right: 5px;
						padding: 10px 22px;
						min-width: 150px;
					}

					@media (max-width: $b3){
						margin-right: 5px;
						min-width: 60px;
						padding: 8px 10px;
					}

					+ .btn{
						margin-right: 5px;
					}
				}
			}

			.text{

				@media (max-width: $b3){
					margin-bottom: 0;
				}
			}

			.text-column{

				@media (min-width: $b2 + 1){
//					column-count: 2;
//					column-gap: 22px;

					display: flex;
					justify-content: space-between;

					p{
						width: calc(50% - 11px);
					}

					ul{
						break-inside: avoid;
						break-inside: avoid-column;

						li{
							break-inside: avoid;
						}
					}
				}

				p{
					break-inside: avoid;
					break-inside: avoid-column;
					margin-bottom:  1.15em;
					//padding-bottom: 1.15em;
				}
			}
		}
	}


	.text-side{
		padding-top: 11px;

		@media (min-width: $b3 + 1){
			padding-left: 8px;
		}
	}

	h4{
		letter-spacing: .01em;
		line-height: 1.12em;
	}


	.title-con{
		margin-top: 14.5em;

		@media (max-width: 1300px){
			margin-top: 0em;
		}

		div{
			@include font-size(100);
			font-family: $alt-font;
			font-weight: 700;
			line-height: .75em;
			line-height: 1em;
			overflow: hidden;

			@media (max-width: 1100px){
				@include font-size(90);
			}

			@media (max-width: $b2){
				@include font-size(69);
			}

			@media (max-width: $b3){
				@include font-size(65/$divider-big);
			}

			&:nth-of-type(1){
				z-index: 2;
				position: relative;
			}

			&:nth-of-type(2){
				max-height: 33px;
				margin-top: -0.08em;
				opacity: .5;
				display: flex;
				align-items: flex-end;
				color: white;
				//mix-blend-mode: hard-light;

				@media (max-width: $b2){
					max-height: 22px;
				}

				@media (max-width: $b3){
					max-height: 16px;
				}
			}

			&:nth-of-type(3){
				max-height: 23px;
				margin-top: -0.04em;
				opacity: .25;
				display: flex;
				align-items: flex-end;
				color: white;
				//mix-blend-mode: hard-light;

				@media (max-width: $b2){
					max-height: 16px;
					margin-top: .01em;
				}

				@media (max-width: $b3){
					max-height: 13px;
					margin-top: .01em;
				}
			}

			&:nth-of-type(4){
				max-height: 18px;
				margin-top: -0.01em;
				opacity: .1;
				display: flex;
				align-items: flex-end;
				color: white;
				//mix-blend-mode: hard-light;

				@media (max-width: $b2){
					max-height: 14px;
					margin-top: .001em;
				}

				@media (max-width: $b3){
					max-height: 10px;
					margin-top: .001em;
				}
			}
		}
	}

	*{
		color: white;
	}
}

.careers-section{
	background-color: $grey;
	position: relative;
	background-position: 50% 100%;

	.slick-arrow{
		z-index: 999;

		@media (max-width: $b3){

			&.slick-next:hover:before{
				background-image: url(../images/slide-next.svg);
			}

			&.slick-prev:hover:before{
				background-image: url(../images/slide-prev.svg);
			}
		}
	}

	.row{
		padding-top: 187px;
		padding-bottom: 197px;
		//min-height: 100vh;
		align-items: flex-start;

		@media (max-width: 1300px){
			padding-top: 128px;
		}

		@media (max-width: $b3){
			padding-top: 100px;
			padding-bottom: 120px;
		}
	}

	.title{

		h2{
			font-weight: 600;
			letter-spacing: 0;
			font-family: $alt-font;
			color: $pink;
			margin-bottom: 0.35em;

			@media (min-width: 1500px + 1){
				@include font-size(75);
			}
		}
	}

	*{
		color: white;
	}

	.slick-next:hover{

		&:before{
			background-image: url(../images/slide-next-hover-green.svg);
		}
	}

	.slick-prev:hover{

		&:before{
			background-image: url(../images/slide-prev-hover-green.svg);
		}
	}

	.col-12{
		position: static;
	}

	.slick-dots{
		right: auto;
		left: 50%;
		transform: translateX(-50%);
		flex-direction: row;
		width: auto;
		top: auto;
		bottom: 66px;

		@media (max-width: $b2){
			display: none!important;
		}

		li{
			margin: 0 7.5px;

			@media (max-width: $b3){
				margin: 0 5px;
			}
		}
	}

	.career-slide{
		position: static;
		margin: 0 -30px;

		@media (max-width: $b3){
			margin: 0;
		}

		.slick-track{
			display: flex;
			align-items: stretch;

			.slick-slide{
				float: none;
				height: auto;
			}
		}

		.container{
			max-width: 1220px;
			//padding: 0;
		}

		.row{
			padding: 0;
			min-height: 0;
			height: 100%;
		}

		.col-md-12{
			position: relative;
			padding-bottom: 49px;
			align-self: stretch;

			@media (max-width: $b3){
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.btn-con{
		position: absolute;
		bottom: 0;
		right: 30px;
	}

	.btn{
		color: white!important;
		background-color: $pink;
		border-color: $pink;

		&:hover,
		&:focus{
			color: $grey!important;
			background-color: $green;
			border-color: $green;
		}

	}

	p{
		line-height: 1.34em;
		letter-spacing: 0.003em;
		margin-bottom: 1.3em;
	}

	h3{
		font-family: $main-font;
		font-weight: 900;
		margin-bottom: 0.6em;

		@media (min-width: 1500px + 1){
			@include font-size(30);
		}
	}
}

.case-studies-section{
	overflow: hidden;

	.slick-arrow{
		z-index: 999;

		@media (max-width: $b3){

			&.slick-next:hover:before{
				background-image: url(../images/slide-next.svg);
			}

			&.slick-prev:hover:before{
				background-image: url(../images/slide-prev.svg);
			}
		}
	}

	@media (min-width: $b2 + 1){
				
		&#TALKTALK{

			.slide-2 .image-side{

				img{
					float: right;
					width: 150%;
					max-width: 150%;
				}
			}
		}
	}

	.slick-dots{
		right: auto;
		left: 50%;
		transform: translateX(-50%);
		flex-direction: row;
		width: auto;
		top: auto;
		bottom: 66px;

		@media (max-width: $b2){
			display: none!important;
		}

		li{
			margin: 0 7.5px;

			@media (max-width: $b3){
				margin: 0 5px;
			}
		}
	}

	&.waypoint{
		transform: none;
		opacity: 1;
		visibility: visible;

		.slide-1{

			.text-side{
				transform: translateY(-100%);
				opacity: 0;
				visibility: hidden;
				transition: .4s;

				.btn{
					margin-top: 1.5em;
				}
			}

			.title-con{

				div{

					&:nth-of-type(2),
					&:nth-of-type(3),
					&:nth-of-type(4){
						transform: translateY(-114%);
						opacity: 0;
						visibility: hidden;
					}

					&:nth-of-type(3){
						transform: translateY(-290%);
					}

					&:nth-of-type(4){
						transform: translateY(-534%);
					}
				}
			}
		}

		&.animate{

			.slide-1{

				.text-side{
					transform: translateY(0%);
					opacity: 1;
					visibility: visible;
				}

				.title-con{

					div{

						&:nth-of-type(2),
						&:nth-of-type(3),
						&:nth-of-type(4){
							transform: translateY(0%);
							transition: .4s .4s;
							opacity: 1;
							visibility: visible;
						}

						&:nth-of-type(2){
							opacity: .5;
						}

						&:nth-of-type(3){
							opacity: .25;
						}

						&:nth-of-type(4){
							opacity: .1;
						}
					}
				}
			}
		}
	}

	&.colour-blue{
		background-color: $blue;
	}

	&.colour-grey{
		background-color: $grey;
	}

	&.colour-pink{
		background-color: $pink;
	}

	&.colour-orange{
		background-color: $orange;
	}

	&.colour-purple{
		background-color: $purple;
	}

	&.colour-green{
		background-color: $green-alt;
	}

	.slick-next:hover{

		&:before{
			background-image: url(../images/slide-next-hover-green.svg);
		}
	}

	h4{
		@media (max-width: 1300px){

			@media (min-width: $b3 + 1){
				font-size: 20px;
			}
		}
	}

	.slick-prev:hover{

		&:before{
			background-image: url(../images/slide-prev-hover-green.svg);
		}
	}

	.row{
		padding-top: 150px;
		padding-bottom: 150px;
		min-height: 100vh;

		@media (max-width: 1300px){

			@media (min-width: $b3 + 1){
				padding-top: 145px;
				padding-bottom: 100px;
			}
		}

		@media (max-width: $b3){
			padding-top: 100px;
			padding-bottom: 100px;

			@media (max-height: 500px){
				padding-top: 75px;
			}
		}
	}

	.slick-track{
		display: flex;

		.slide{
			float: none;
			height: auto;
		}
	}

	.slide{

		.container{
			max-width: 1220px;
		}

		&.slide-1{

			.title-side{
				align-self: center;

				@media (max-width: $b3){
					align-self: flex-start;
				}

				.title-con{

					@media (max-width: $b3){
						margin-top: 0;
					}
				}
			}

			.text-side{
				z-index: 1;
			}
		}

		&.slide-2{

			&:after{
				content: '';
				position: absolute;
				left: 64.8%;
				transform: translate(-50%, -50%);
				top: 44.7%;
				width: 156.5%;
				max-width: none;
				padding-top: 17.9%;
				padding-top: 28.3%;
				background-size: 100% auto;
				background-image: url(../images/sol-back.svg);
				background-repeat: no-repeat;
				pointer-events: none;

				@media (max-width: $b3){
					width: 276.5%;
					padding-top: 50.3%;
					left: 58.8%;
				}
			}

			.col-md-6{
				align-self: flex-end;
				z-index: 1;

				@media (max-width: $b2){
					width: 100%;
					flex: 0 0 100%;
				}

				@media (min-width: $b3 + 1){
					column-count: 2;
					column-gap: 22px;

					p{
						margin-bottom: 0;
						padding-bottom: 1.15em;
					}
				}

				p{
					break-inside: avoid-column;
				}

				&.image-side{
					align-self: center;

					img{
						width: 100%;

						@media (max-width: 1300px){
							width: 75%;
							margin: 0 auto;
						}

						@media (max-width: $b2){
							width: 50%;
							margin: 0 auto;

							@media (min-width: 900px + 1){
								width: 75%;
							}
						}

						@media (max-width: $b4){
							width: 75%;
						}
					}

					@media (min-width: $b3 + 1){
						column-count: unset;
						column-gap: 22px;
					}

					@media (max-width: $b3){
						margin: 0 auto;
						max-width: 80%;
					}
				}
			}
		}

		&.slide-3{

			&.waypoint{
				transform: none;
				opacity: 1;
				visibility: visible;

				.top{
					transform: translateY(-100%);
					pointer-events: none;
					opacity: 0;
					visibility: hidden;

					@media (max-width: $b3){
						opacity: 0;
						visibility: hidden;
						left: 50%;
						transform: translateX(-50%) translateY(-100%);
					}
				}

				.lower{
					transform: translateY(100%);
				}

				&.animate{

					.top,
					.lower{
						transform: translateY(0%);
						transition: .4s .4s;
					}

					.top{
						opacity: 1;
						visibility: visible;

						@media (max-width: $b3){
							opacity: 1;
							visibility: visible;
							transform: translateX(-50%) translateY(0%);
						}
					}
				}
			}

			.row{
				//padding-top: 100px;
				padding-bottom: 0;
				align-items: center;

				@media (max-width: $b3){
					align-items: flex-start;
				}

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					height: 100vh;
				}
			}

			.container{
				height: 100%;

				.row{
					height: 100%;
				}
			}

			.img-side{
				align-self: flex-end;

				@media (max-width: 1500px){
					img{
						width: 75%;
						margin: 0 auto;
					}
				}

				@media (max-width: 1400px){
					img{
						width: 57%;
					}
				}

				@media (max-width: $b3 + 10px){

					img{
						width: 100%;
					}
				}

				@media (min-width: 1920px + 1){

					img{
						width: 86.111%;
					}
				}

				@media (max-width: 1333px){

					@media (min-width: $b3 + 1){

						img{
							max-width: 335px;
						}
					}
				}

				@media (max-width: $b3){
					order: 2;
					display: none;

					img{
						margin: 0 auto;
					}
				}
			}

			*{
				color: white;
			}

			.img-side{

				@media (min-width: $b3 + 1){
					padding-left: 76px;
				}
			}

			h5{

				@media (max-width: 1500px){
					margin-bottom: .85em;
				}
			}

			.img-con{
				position: relative;

				.top{
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;

					@media (max-width: 1500px){
						left: 50%;
						transform: translateX(-50%);
					}

					@media (max-width: 900px){
						max-width: 100%;
					}

					@media (max-width: $b3){
						left: 50%;
					}
				}

				.lower{
					position: relative;
					z-index: 2;

					@media (max-width: 900px){
						max-width: 100%;
					}
				}
			}

			.text-side{
				padding-bottom: 50px;

				@media (min-width: $b3 + 1){
					padding-left: 10px;
					padding-top: 0px;
					align-self: flex-start;
				}

				@media (max-width: $b3){
					padding-top: 0;
					padding-bottom: 0;
				}
			}

			.text{

				@media (max-width: $b3){
					margin-bottom: 0;
				}
			}

			.text-column{

				@media (min-width: $b2 + 1){
//					column-count: 2;
//					column-gap: 13px;

					display: flex;
					flex-wrap: wrap;
//					column-count: 0;
					justify-content: space-between;

					p{
						width: calc(50% - 7px);
					}


					ul{
						break-inside: avoid;
						break-inside: avoid-column;

						li{
							break-inside: avoid;
						}
					}
				}

				p{
					break-inside: avoid;
					margin-bottom: 0;
					padding-bottom: 1.15em;
				}
			}
		}

		&.slide-4{
			position: relative;

			@media (max-height: 500px){


				.container{
					height: 100%;

					.row{
						height: 100%;
						padding-bottom: 52px;
					}
				}
			}

			.row{


				@media (max-width: 1300px){

					@media (min-width: $b3 + 1){
						padding-bottom: 0;
					}

				}
			}

			@media (max-width: $b3){

				@media (min-width: 600px + 1){

					img{
						width: 40% !important;
						margin-bottom: 50px;
					}

					.lower{
						width: 40% !important;
						margin-bottom: 0;
					}
				}
			}

			.lower{
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translateX(-50%);
				width: 49.7%;

				@media (max-width: $b2){
					width: 75%;
				}

				@media (min-width: $b3 + 1){

					@media (max-height: 800px){
						width: 40%;
					}
				}
			}

			.col-md-8{

				@media (min-width: $b3 + 1){
					padding-right: 60px;
				}
			}

			.col-md-12{
				align-self: flex-end;

				@media (max-width: $b3){
					margin-top: 10px;

					iframe,
					img{
						width: 80%;
					}
				}

				iframe{
					display: block;
				}

				iframe,
				img{
					margin: 0 auto 0;
					width: 54.2%;
					width: 43.36%;
					position: relative;
					z-index: 1;

					@media (max-width: 1300px){
						margin: 0 auto 100px;
					}

					@media (min-width: $b3 + 1){

						@media (max-height: 800px){
							width: 37.2%;
						}
					}

					@media (max-width: $b2){
						width: 80%;
						margin-bottom: 125px;
					}

					@media (max-width: 1000px){
						width: 50%;
					}

					@media (max-width: 800px){
						margin-bottom: 173px;
						width: 80%;

					}

					@media (max-width: $b3){
						margin-bottom: 10px;
						width: 80%;
					}
				}

				.play{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;

					&:hover{

						&::before{
							background-color: $green;
						}
					}

					&:before,
					&::after{
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						transition: $time;
					}

					&::before{
						width: 82px;
						height: 52px;
						background-color: $blue;
						mix-blend-mode: multiply;
						z-index: 1;
					}

					&::after{
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 12px 0 12px 17px;
						border-color: transparent transparent transparent #ffffff;
						z-index: 2;
					}
				}



			}
		}

		&.slide-5{
			min-height: 100vh;
			height: auto;
			display: flex;
			justify-content: center;
			align-items: center;
			background-position: 50% 100%;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				height: 100vh;
			}

			@media (max-width: $b3){
				align-items: flex-start;
				padding-top: 50px;
			}

			.quote-con{
				width: 910px;
				padding: 0 15px;
				margin: 0 auto;

				@media (min-width: 2132px + 1){
					width: 910px * 1.1109;
				}

				@media (min-width: 2346px + 1){
					width: 910px * 1.2218;
				}

				@media (min-width: 2558px + 1){
					width: 910px * 1.3328;
				}

				@media (max-width: 1006px){

					@media (min-width: $b3 + 1){
						width: 100%;
						padding: 0 60px;
					}
				}
			}

			blockquote{
				@include font-size(39.64);
				font-weight: 700;
				font-family: $main-font;
				margin-bottom: .45em;
				padding-top: 95px;
				position: relative;

				@media (max-height: 510px){
					padding-top: 100px;
				}

				@media (max-width: $b2){
					@include font-size(34);
				}

				@media (max-width: $b3){
					@include font-size(28/$divider);
				}

				&:before{
					content: '“';
					position: absolute;
					top: 57px;
					left: 2px;
					@include font-size(144.15);

					@media (max-height: 510px){
						top: 80px;
					}

					@media (max-width: $b2){

						@include font-size(144.15/$divider);
					}
				}
			}

			.by{
				@include font-size(20);
				font-weight: 600;

				@media (max-width: $b2){
					@include font-size(20/$divider);
				}
			}

			img{
				margin-top: 3.3em;

				@media (max-width: $b3){
					margin-top: 1.7em;
					margin-bottom: 0px;
				}
			}
		}
	}

	.text-side{
		padding-top: 11px;

		@media (min-width: $b3 + 1){
			padding-left: 8px;
		}
	}

	h4{
		letter-spacing: .01em;
		line-height: 1.12em;
	}


	.title-con{
		margin-top: 10.2em;

		div{
			@include font-size(100);
			font-family: $alt-font;
			font-weight: 700;
			line-height: .75em;
			line-height: 1em;
			overflow: hidden;

			@media (max-width: 1100px){
				@include font-size(90);
			}

			@media (max-width: $b2){
				@include font-size(69);
			}

			@media (max-width: $b3){
				@include font-size(65/$divider-big);
			}

			&:nth-of-type(1){
				z-index: 2;
				position: relative;
			}

			&:nth-of-type(2){
				max-height: 33px;
				margin-top: -0.08em;
				opacity: .5;
				display: flex;
				align-items: flex-end;
				color: white;
				//mix-blend-mode: hard-light;

				@media (max-width: $b2){
					max-height: 22px;
				}

				@media (max-width: $b3){
					max-height: 16px;
				}
			}

			&:nth-of-type(3){
				max-height: 23px;
				margin-top: -0.04em;
				opacity: .25;
				display: flex;
				align-items: flex-end;
				color: white;
				//mix-blend-mode: hard-light;

				@media (max-width: $b2){
					max-height: 16px;
					margin-top: .01em;
				}

				@media (max-width: $b3){
					max-height: 13px;
					margin-top: .01em;
				}
			}

			&:nth-of-type(4){
				max-height: 18px;
				margin-top: -0.01em;
				opacity: .1;
				display: flex;
				align-items: flex-end;
				color: white;
				//mix-blend-mode: hard-light;

				@media (max-width: $b2){
					max-height: 14px;
					margin-top: .001em;
				}

				@media (max-width: $b3){
					max-height: 10px;
					margin-top: .001em;
				}
			}
		}
	}

	*{
		color: white;
	}
}

.featured-article-section{

	.container{
		max-width: 1160px;
	}

	.row{
		padding-top: 154px;
		padding-bottom: 58px;
		min-height: 100vh;

		@media (max-width: $b3){
			min-height: 0;
			padding-top: 100px;
		}
	}

	.col-12{
		background-image: url(../images/f-back.jpg);
		background-repeat: no-repeat;
		position: relative;
		display: flex;
		align-items: flex-end;
		padding-top: 190px;

		@media (min-width: $b3 + 1){
			padding-left: 33px;
			padding-right: 33px;
		}

		@media (min-width: $b1 + 1){
			padding-right: 150px;
		}

		&:before{
			content: '';
			background-image: url(../images/white-over.svg);
			background-repeat: no-repeat;
			position: absolute;
			top: 0;
			left: 50%;
			width: 1177px;
			height: 185px;
			background-position: 50% 0;
			transform: translateX(-50%);
			background-size: 1177px auto;
			pointer-events: none;

			@media (max-width: 800px){
				background-size: 882px auto;
			}
		}
	}

	*{
		color: white;
	}

	h2{
		line-height: 1.08em;
		margin-bottom: 0.42em;
		letter-spacing: 0;

		@media (min-width: 1500px + 1){
			@include font-size(65);
		}
	}

	.date{
		@include font-size(15);
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@media (max-width: $b2){

			@media (min-width: $b3 + 1){
				padding-top: 30px;
			}
		}

		div{

			&:after{
				content: ', ';
				margin-right: 0.25em;
			}

			&:last-of-type{

				&:after{
					content: ' | ';
					margin-right: 0.25em;
				}
			}
		}
	}

	.btn.trans{
		background-color: transparent;
		color: $white!important;
		padding: 6px 7px;
		min-width: 161px;

		@media (max-width: $b3){
			width: 100%;
		}

		&:first-of-type{

			@media (min-width: $b3 + 1){
				margin-left: 34px;
			}

			@media (max-width: $b3){
				margin-top: 10px;
			}
		}

		&:not(:last-of-type){

			@media (min-width: $b3 + 1){
				margin-right: 34px;
			}

			@media (max-width: $b3){
				margin-bottom: 10px;
			}
		}

		&:hover,
		&:focus{
			background-color: $green;
			border-color: $green;
			color: $colour!important;

		}
	}

	.text{
		padding: 35px 0 35px;

		@media (max-width: $b3){
			padding-top: 0;
			margin-top: -5px;

			h2{
				@include font-size(19);
			}
		}
	}
}

.person-section{
	overflow: hidden;
	position: relative;

	&:not(.open){

		.text-side{

			.text-con{

				div{

					@media (min-width: $b3 + 1){
						column-count: 0;
						column-gap: 13px;
						display: flex;
						justify-content: space-between;
					}
				}

				p{

					@media (min-width: $b3 + 1){
						display: none;

						&:first-of-type{
							display: block;
							overflow: hidden;
							max-height: 4.2em;;
							width: calc(50% - 6.5px);
							display: -webkit-box;
							  -webkit-line-clamp: 3;
							  -webkit-box-orient: vertical;
							display: box;
							line-clamp: 3;
							box-orient: vertical;
						}
					}
				}
			}
		}
	}

	&:first-of-type{

		.row{
			padding-top: 150px;
			padding-bottom: 150px;

			@media (max-width: 1300px){
				padding-top: 100px;
				padding-bottom: 25px;
			}

			@media (max-width: $b3){
				height: auto;
			}

			@media (max-width: $b3){
				padding-top: 100px;
				padding-bottom: 25px;
			}
		}
	}

	.row{
		padding-top: 150px;
		padding-bottom: 150px;
		height: 100vh;
		align-items: center;

		@media (max-width: 1300px){
			padding-top: 100px;
			padding-bottom: 25px;
		}

		@media (max-width: $b3){
			height: auto;
		}

		@media (max-width: $b3){
			padding-top: 100px;
			padding-bottom: 25px;
		}
	}

	@media (min-width: $b3 + 1){

		&.open{

			*{
				color: white!important;
			}

			.image-side{

				.img-con{
					clip-path: ellipse(100vw 100vw at calc(50vw - -261px) 53%);

					@media (max-width: $b2){
						clip-path: ellipse(150vw 150vw at calc(50vw - -191px) 53%);
					}
				}
			}
		}
	}

	@media (min-width: 1300px + 1){

		&:not(.open){

			.image-side{

				.img-con{

					&:hover{
						clip-path: ellipse(275px 275px at calc(50vw - -261px) 53%);
					}
				}
			}

			&.side-right{

				.image-side{

					.img-con{

						&:hover{
							clip-path: ellipse(275px 275px at calc(50vw - 321px) 53%);
						}
					}
				}
			}
		}
	}

	&.side-right{

		.text-side{
			order: 2;
		}

		@media (min-width: $b3 + 1){

			&.open{

				.image-side{

					.img-con{
						clip-path: ellipse(100vw 100vw at calc(50vw - 321px) 53%);

						@media (max-width: $b2){
							clip-path: ellipse(150vw 150vw at calc(50vw - 191px) 53%);
						}
					}
				}
			}

			.image-side{

				.img-con{
					clip-path: ellipse(250px 250px at calc(50vw - 321px) 53%);

					@media (max-width: 1300px){
						clip-path: ellipse(140px 140px at calc(50vw - 261px) 53%);
						background-position: 45% 15%;
						background-size: auto 100%;
						background-position: 5% 50%;
					}

					@media (max-width: $b2){
						clip-path: ellipse(160px 160px at calc(50vw - 191px) 43%);
						background-position: 40% 0%;
					}

					@media (max-width: 1024px) and (min-width: 1023px + 1){
						background-position: 65% 50%;
					}


				}
			}
		}

		.image-side{

			.img-con{

				@media (max-width: 540px){
					background-position: 40% 50%;
				}
			}
		}
	}

	.image-side{
		display: flex;
		justify-content: center;
		align-items: center;
		position: static;

		.img-con{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-position: 50% 50%;
			transition: .75s .1s;
			will-change: transform;

			@media (min-width: 1300px + 1){
				clip-path: ellipse(250px 250px at calc(50vw - -261px) 53%);
				cursor: pointer;
			}

			@media (max-width: 1300px) and (min-width: $b3 + 1){
				clip-path: ellipse(140px 140px at calc(50vw - -261px) 53%);
				cursor: pointer;
				background-size: auto 100%;
				background-position: 95% 50%;


				@media (max-width: $b2){
					clip-path: ellipse(160px 160px at calc(50vw - -191px) 47%);
					background-position: 60% 0%;
					background-size: auto 100%;
				}
			}

			@media (max-width: 1024px) and (min-width: 1023px + 1){
				background-position: 35% 50%;
			}

			@media (max-width: $b3){
				background-size: cover;
				background-repeat: no-repeat;
			}

			@media (max-width: 540px){
				background-position: 56% 50%;
				background-repeat: repeat-y;
				background-size: auto 630px;
			}
		}
	}

	.image-side{

		@media (max-width: 540px){
			flex: 0 0 40%;
			max-width: 40%;
		}
	}

	.text-side{

		@media (max-width: $b3){

			*{
				color: white!important;
			}
		}

		@media (max-width: 540px){
			flex: 0 0 60%;
			max-width: 60%;
		}

		*{
			//transition: .2s;
		}

		h2{
			color: $blue;
			margin-bottom: 0;
		}

		h3{
			letter-spacing: .01em;

			@media (min-width: $b2 + 1){
				@include font-size(30);
			}

			@media (max-width: $b3){
				@include font-size(18);
			}
		}

		.text-con{
			position: relative;
			z-index: 1;

			div{

				@media (min-width: $b3 + 1){
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;

					p{
						width: calc(50% - 7px);
					}
				}
			}
		}

		.linkedin{
			@include font-size(29);
			color: $colour;
			margin-top: 0.3em;
			display: inline-block;
		}



		p{
//			max-width: 240px;
		}
	}
}

.fancybox-button--fullscreen,
.fancybox-button--thumbs,
.fancybox-button--share{
	display: none!important;
}

@media (min-width: 1920px + 1){

	.container{
		max-width: 63.5416666667%!important;

//		transform: scale(1.1);
//		transform-origin: 50% 0;
	}
}

.icons {
	background: #444444;
	text-align: center;
	position: relative;
	padding-top: 130px;
	padding-bottom: 143px; }
	.icons * {
	  color: white; }
	.icons:after {
	  content: '';
	  position: absolute;
	  top: 320px;
	  width: 2799px;
	  height: 444px;
	  display: block;
	  background: url(../images/bg-icons.svg) no-repeat;
	  background-size: 2799px 444px;
	  left: -95px;
	  mix-blend-mode: multiply; }
	.icons__heading h1, .icons__heading h2, .icons__heading h3, .icons__heading h4, .icons__heading h5, .icons__heading h6, .icons__heading p {
	  margin-bottom: 140px; }
	  .icons__heading h1 span, .icons__heading h2 span, .icons__heading h3 span, .icons__heading h4 span, .icons__heading h5 span, .icons__heading h6 span, .icons__heading p span {
		color: #00F500; }
	.icons__item-wrap {
	  margin: 0 -10px;
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -ms-flex-wrap: wrap;
		  flex-wrap: wrap;
	  -webkit-box-pack: center;
		  -ms-flex-pack: center;
			  justify-content: center; }
	.icons__item {
	  width: 20%;
	  padding: 10px;
	  text-align: center; }
	  @media (max-width: 1219px) {
		.icons__item {
		  width: 210px; } }
	  .icons__item figure {
		margin: 0;
		height: 104px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
			-ms-flex-direction: column;
				flex-direction: column;
		-webkit-box-pack: end;
			-ms-flex-pack: end;
				justify-content: flex-end;
		width: 100%; }
		.icons__item figure svg, .icons__item figure img {
		  margin: 0 auto; }
	.icons__caption {
	  color: white;
	  width: 100%;
	  height: 175px;
	  margin-top: 25px;
	  margin-bottom: 10px; }
	  @media (max-width: 1219px) {
		.icons__caption {
		  height: 150px; } }
	  @media (max-width: 575px) {
		.icons__caption {
		  height: auto;
		  min-height: 100px; } }
	  .icons__caption p {
		font-size: 20px;
		font-weight: 600;
		line-height: 1.3; }

/*--------------------------------------------------
	!Collection of common classes
	and SASS mixins
-------------------------------------------------- */

$em-base: 10;

@mixin font-size($sizeValue: 16 ){
	font-size: $sizeValue + px;
	font-size: ($sizeValue / $em-base) + rem;
}

@mixin line-height($sizeValue: 16, $lineHeight: 25 ){
	line-height: ($lineHeight / $sizeValue + em);
}

@mixin letter-spacing($sizeValue: 16, $spacing: 0 ){
	letter-spacing: ($spacing / $sizeValue + em);
}

@mixin text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin svg_img($img_path) {
	background-image: url(#{$img_path}.svg);
	background-repeat: no-repeat;
}

@mixin inline {
	display: inline-block;
}

@mixin of{
	margin: 0 12.1% 0 5.9%;
	width: 82%;

	@media (max-width: $b2){
		margin: 0 auto;
		width: 93%;
		float: none;
	}
}

@mixin tof{
	margin: 0 12.1% 0 20.2%;
	margin: 0 12.1% 0 0;
	width: 67.7%;
	float: right;
	clear: none!important;

	@media (max-width: $b2){
		margin: 0 auto;
		width: 93%;
		float: none;
	}
}

@mixin tofs{
	margin: -3px 12.1% 0 25.4%;
	margin: -3px 12.1% 0 0;
	width: 62.5%;
	float: right;
	clear: none!important;

	@media (max-width: $b2){
		margin: 0 auto;
		width: 93%;
		float: none;
	}
}

@mixin centerer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin centerer-vertical {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
}

@mixin centerer-horizontal {
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
}

/*	--------------------------------------------------
	!Common Classes
-------------------------------------------------- */

//!!BOX-SIZING + NO OUTLINE

html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*,
*:before,
*:after{
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
	outline: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0)!important;
	tap-highlight-color: rgba(0,0,0,0)!important;
	-webkit-backface-visibility: hidden!important;
	backface-visibility: hidden!important;

	-webkit-font-smoothing: antialiased;
	-ms-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	max-width: 100%;
//	transform: translateZ(0);
//	will-change: transform;

}

.slick-slider{

	*{
		max-width: none;
	}

	img{
		max-width: 100%;
	}
}

input[type=search]{
	box-sizing: border-box;
}

table,
table *{
	-webkit-backface-visibility: visible!important;
	backface-visibility: visible!important;
}

//!!CLEARFIX
//.p-img a:after,
.main-wrapper:after,
.pakery-grid:after,
.gform_fields:after,
.clearfix:after{
	visibility:hidden;
	display:block;
	font-size:0;
	content:" ";
	clear:both;
	height:0;
}

@media (max-width: $b2){

	.sub-menu:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}
}

* html .clearfix{
	height:1%;
}

//.p-img a,
.main-wrapper,
.pakery-grid,
.gform_fields,
.clearfix{
	display:block;
}
//!!ACCESSIBILITY HIDE
.visually-hidden{
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

//!!IMAGE REPLACEMENT
.img-replacement{
	display: inline-block;
	text-indent:200%;
	white-space:nowrap;
	overflow:hidden;
}

.hide{
	display:none;
}

.show{
	display: block;
}

.list-no-bullets{
	list-style: none;
}

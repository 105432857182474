//@font-face {
//	font-family: 'Logical';
//	src: url('../fonts/Logical-Book.woff2') format('woff2'),
//		url('../fonts/Logical-Book.woff') format('woff');
//	font-weight: 400;
//	font-style: normal;
//}
//
//@font-face {
//	font-family: 'Logical';
//	src: url('../fonts/Logical-Bold.woff2') format('woff2'),
//		url('../fonts/Logical-Bold.woff') format('woff');
//	font-weight: 700;
//	font-style: normal;
//}

@font-face {
	font-family: 'Logical';
	src: url('../fonts/YfefaTXDRYfi.woff2') format('woff2'),
		url('../fonts/YfefaTXDRYfi.woff') format('woff'),
		url('../fonts/YfefaTXDRYfi.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Logical';
	src: url('../fonts/YfefaTXDRYcY.woff2') format('woff2'),
		url('../fonts/YfefaTXDRYcY.woff') format('woff'),
		url('../fonts/YfefaTXDRYcY.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Graphik';
	src: url('../fonts/Graphik-Regular.woff2') format('woff2'),
		url('../fonts/Graphik-Regular.woff') format('woff'),
		url('../fonts/Graphik-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Graphik';
	src: url('../fonts/Graphik-Medium.woff2') format('woff2'),
		url('../fonts/Graphik-Medium.woff') format('woff'),
		url('../fonts/Graphik-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Graphik';
	src: url('../fonts/Graphik-Semibold.woff2') format('woff2'),
		url('../fonts/Graphik-Semibold.woff') format('woff'),
		url('../fonts/Graphik-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Graphik';
	src: url('../fonts/Graphik-Bold.woff2') format('woff2'),
		url('../fonts/Graphik-Bold.woff') format('woff'),
		url('../fonts/Graphik-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Graphik';
	src: url('../fonts/Graphik-Black.woff2') format('woff2'),
		url('../fonts/Graphik-Black.woff') format('woff'),
		url('../fonts/Graphik-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

//BREAK POINTS
$b1:				1160px + 60px - 1px; //$base-width + $grid-gutter-width - 1px
$b2:				992px - 1px; //992px - 1px
$b3:				768px - 1px; //768px - 1px
$b4:				576px - 1px; //576px - 1px
$b5:				480px - 1px; //450px

//xs: 0,
//sm: 576px,
//md: 768px,
//lg: 992px,
//xl: $base-width

//!!COLOURS
$red:				#ff0000;

$green:				#00F500;
$green-dark:		#5FD520;

$green-alt:			#00b000;

$blue:				#2626F0;
$blue-dark:			#182FD3;

$pink:				#ff4563;
$pink-dark:			#D34A59;

$orange:			#fd8401;

$purple:			#9e45e3;

$grey-lighter:		#F0F0F0;
$grey-light:		#515151;
$grey:				#444444;

$white:				#fff;
$black:				#000;

$colour: 			$grey;
$main-bg-colour:	$grey-lighter;

$font-grap:			'Graphik', sans-serif;
//regular - 400
//medium - 500
//semi - 600
//bold - 700
//black - 900

$font-logi:			'Logical', sans-serif;
//book - 400
//bold - 700

$main-font:			$font-grap;
$alt-font:			$font-logi;

//!!GRID SETTINGS
$grid-columns:		12 !default;
$grid-gutter-width:	60px !default;

//!!PAGE WIDTH
$base-width:		1160px;
//$base-width: 1085px;

//!!SITE TRANSITIONS
$time:				.2s;

//!!FONT SIZE DIVIDER
$divider:			1.25;
$divider-big:		1.4;

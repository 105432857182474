//<div id="burger" class="burger clearfix">menu
//	<div class="line1"></div>
//</div>

$outerWidth: 50px;
$outerHeight: 50px;

$lineHeight: 2px;
$lineWidth: 21px;
$gap: 5px;
$radius: 0;

$topLineColour: $grey;
$topLineColourOpen: $grey;

$middleLineColour: $grey;
$middleLineColourOpen: transparent;

$bottomLineColour: $grey;
$bottomLineColourOpen: $grey;

.burger{
	height: $outerHeight;
	width: $outerWidth;
	line-height: $outerHeight;
	padding: 1px;
	-webkit-perspective: 900000px;
	perspective: 900000px;
	order: 1;
	z-index: 100;
	transition: $time;
	pointer-events: auto;
	position: relative;
	border-radius: 70px;
	display: block;
	cursor: pointer;

	&:after{
		content: '';
		position: absolute;
		width: 48px;
		height: 48px;
		max-width: none;
		border-radius: 99999999px;
		background-color: white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%,-50%, 0) scale(1);
		transform: translate(-50%,-50%);
		transition: .75s 0s ease-in-out;

		@media (min-width: 2132px + 1){
			width: 49px * 1.1109;
			height: 48px * 1.1109;
		}

		@media (min-width: 2346px + 1){
			width: 48px * 1.2218;
			height: 48px * 1.2218;
		}

		@media (min-width: 2558px + 1){
			width: 48px * 1.3328;
			height: 48px * 1.3328;
		}
	}

	.none-menu-link &,
	.menu-open &{

		&:after{
			//transform: translate3d(-50%,-50%, 0) scale(105);
			width: 395vw;
			height: 395vw;

			@media (max-width: 800px){
//				transform: translate3d(-50%,-50%, 0) scale(40);
//				transform: translate3d(-50%,-50%, 0) scale(65);
				width: 595vw;
				height: 595vw;
			}

		}
	}

	.none-menu-link &{

		.line1{
			display: none;
		}
	}

	.line1{
		width: $lineWidth;
		max-width: 100%;
		height: $lineHeight;
		background-color: $middleLineColour;
		position: absolute;
		top: (($outerHeight / 2) - ($lineHeight / 2));
		left: 50%;
		border-radius: $radius;
		transform: translateX(-50%);
		background-position: center center;
		z-index: 101;

		@media (min-width: 2132px + 1){
			width: $lineWidth * 1.1109;
			height: $lineHeight * 1.1109;
		}

		@media (min-width: 2346px + 1){
			width: $lineWidth * 1.2218;
			height: $lineHeight * 1.2218;
		}

		@media (min-width: 2558px + 1){
			width: $lineWidth * 1.3328;
			height: $lineHeight * 1.3328;
		}

		&:before,
		&:after{
			width: $lineWidth;
			max-width: 100%;
			height: $lineHeight;
			position: absolute;
			content: '';
			left: 0;
			border-radius: $radius;
			transform-origin: center center;
			background-position: center center;

			@media (min-width: 2132px + 1){
				width: $lineWidth * 1.1109;
				height: $lineHeight * 1.1109;
			}

			@media (min-width: 2346px + 1){
				width: $lineWidth * 1.2218;
				height: $lineHeight * 1.2218;
			}

			@media (min-width: 2558px + 1){
				width: $lineWidth * 1.3328;
				height: $lineHeight * 1.3328;
			}
		}

		&:before{
			transform: translateY(-#{$gap + $lineHeight});
			background-color: $topLineColour;
		}

		&:after{
			transform: translateY(#{$gap + $lineHeight});
			background-color: $bottomLineColour;
		}
	}

	&.close{

		.line1{
			background-color: $middleLineColour;
			transition: visibility .1s .2s, background-color .1s .2s;

			&:before{
				transform: translateY(-#{$gap + $lineHeight});
				background-color: $topLineColour;
				visibility: visible;
				animation: before .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
			}

			&:after{
				transform: translateY(#{$gap + $lineHeight});
				background-color: $bottomLineColour;
				visibility: visible;
				animation: after .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
			}

			@keyframes before {
				0% {transform: rotate(-45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(-#{$gap + $lineHeight});}
			}

			@keyframes after {
				0% {transform: rotate(45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(#{$gap + $lineHeight});}
			}
		}
	}

	&.open{

		.line1{
			transform-origin: 50% 50%;
			transition: visibility .1s .25s, background-color .1s .25s;
			background-color: $middleLineColourOpen;

			&:before{
				transform: rotate(45deg) translateY(0);
				background-color: $topLineColourOpen;
				visibility: visible;
				animation: before-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
			}

			&:after{
				transform: rotate(-45deg) translateY(0);
				background-color: $bottomLineColourOpen;
				visibility: visible;
				animation: after-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
			}

			@keyframes before-open {
				0% {transform: translateY(-#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(45deg) translateY(0);}
			}

			@keyframes after-open {
				0% {transform: translateY(#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(-45deg) translateY(0);}
			}
		}
	}
}

 //!!FORM
::-webkit-input-placeholder {
	@include font-size(15);
	color: white;

	@media (max-width: $b2){
		@include font-size(15/$divider);
	}
}

:-moz-placeholder { /* Firefox 18- */
	@include font-size(15);
	color: white;

	@media (max-width: $b2){
		@include font-size(15/$divider);
	}
}

::-moz-placeholder {  /* Firefox 19+ */
	@include font-size(15);
	color: white;

	@media (max-width: $b2){
		@include font-size(15/$divider);
	}
}

:-ms-input-placeholder {
	@include font-size(15);
	color: white;

	@media (max-width: $b2){
		@include font-size(15/$divider);
	}
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 0;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 19px;

input,
input.input-text,
textarea,
select{
	display: block;
	padding: 12px 0;
	@include font-size(15);
	font-weight: 300;
	font-family: $main-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: $input-bottom-margin;
	border: none;
	border-bottom: 1px solid white;
	border-radius: 0px;
	color: white;
	line-height: 1.3em;
	-webkit-appearance: none;
	box-shadow: none;
	background-color: transparent;

	@media (max-width: $b2){
		@include font-size(15/$divider);
	}
}

textarea{
	height: 50px;
	min-height: 50px;
	min-width: 100%;

	@media (max-width: $b3){
		height: 40px;
		min-height: 40px;
	}
}

input:focus,
input.input-text:focus,
textarea:focus,
select:focus{
	outline: none;
}

input:focus,
input.input-text:focus,
textarea:focus,
select:focus{
	outline: none;
	transition: color 0.5s ease, background-color 0.5s ease;
}

//
//!!SELECT START
//
select{
	padding-right: 51px;
	appearance: none;
	@include svg_img("../images/select-icon");
	background-position: 100% 50%;
	line-height: 49px;
	height: 49px;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;
	border: 1px solid $grey;


	@media (max-width: $b2){
		line-height: 44px;
		height: 44px;
	}

	&::-ms-expand {
		display: none;
	}

	.ie9 &,
	.lt-ie9 &{
		background-image: none!important;
		padding: 11px 13px 12px 13px!important;
	}

	option{
		padding-top: 5px;
		padding-bottom: 5px;
	}
}
//
//!!SELECT END
//

label{
	padding-top: 0;
	display: block;
	@include font-size(14);
	@include line-height(14,16.8);
	color: $colour;
	font-weight: 300;
	line-height: 1.22em;
	margin-bottom: 20px;
	font-family: $main-font;
	letter-spacing: 0;

	@media (max-width: $b3){
		@include font-size(10);
	}

	.gfield_required{
		display: none;
	}
}

*[type="submit"]{
	@extend .btn;
	margin-bottom: 0;
	text-transform: uppercase;
	width: auto;
}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

input[type="radio"],
input[type="checkbox"]{
	display: none;

	.lt-ie9 &{
		@include inline;
		background-color: transparent;
		border: none;
		width: auto;
		min-width: 12px;
		margin-top: 6px;
		margin-bottom: 6px;

		& + .wpcf7-list-item-label,
		& + label{
			padding-left: 0px!important;

			&:before{
				display: none;
			}
		}
	}

	& + .wpcf7-list-item-label,
	& + label{
		padding-left: 29px!important;
		position: relative;
		cursor: pointer;
		line-height: 18px;
		@include inline;
		@include font-size(15);
		font-weight: 400;

		&:before{
			position: absolute;
			@include svg_img("../images/checkbox");
			content: "";
			width: 18px;
			height: 18px;
			left: 0;
			background-position: 0% 50%;
		}
	}

	&:checked + label:before,
	&:checked + .wpcf7-list-item-label:before,
	& + label.checked:before{
		background-position: 100% 50%;
	}
}

input[type="radio"]{

	& + .wpcf7-list-item-label,
	& + label{

		&:before{
			@include svg_img("../images/radio");
		}
	}
}

//
//!!RADIO & CHECKBOX END
//


//
//!!CONTACT FORM 7 START
//
.send-con{
	display: flex;
	justify-content: flex-end;
	padding: 3px 0 0;
}

.wpcf7{
	position: relative;

	.col{
		position: static;
	}

	form{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 1px 0 1px;

		[type="submit"]{
			margin-top: 23px;

			&:hover,
			&:focus{
				background-color: #132194;
				border-color: #132194!important;
			}

			&.space-above{
				margin-top: 76px;

				@media (max-width: $b3){
					margin-top: -11px;
				}
			}
		}

		.field-p{
			margin-bottom: 0;
		}

		&.sent{

			.wpcf7-list-item{

				label{
					opacity: 1;
				}
			}

			.cf7sr-g-recaptcha,
			label,
			.wpcf7-form-control-wrap,
			input[type="submit"]{
				pointer-events: none;
				opacity: .2;
				transition: .4s;
			}


		}

		label{

			span{
				color: $red;
			}

			.wpcf7-list-item-label{
				color: $colour;
			}
		}

		.wpcf7-form-control-wrap{
			display: block;
			width: 100%;
		}

		> div{
			width: 100%;
		}

		.form-text{
			display: flex;
			flex-direction: column;
			text-align: center;
			align-items: center;
		}

		.small,
		.medium,
		.large{
			position: relative;

			span{
				display: block;
			}
		}

		.small{
			width: 32%;

			@media (max-width: $b3){
				width: 48%;

				+ .small + .small{
					width: 100%;
				}
			}

			@media (max-width: $b4){
				width: 100%;
			}
		}

		.medium{
			width: 45%;

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.large{
			width: 100%;

			textarea{
				margin-top: 20px;
			}
		}

		.has-label{
			display: flex;
			align-items: baseline;

			label{
				width: 100px;
			}

			input,
			input.input-text,
			textarea,
			select{

			}

			.wpcf7-form-control-wrap{
				width: calc(100% - 100px);
			}
		}

		.btn-con{
			position: static;
			justify-content: center;
		}

		span.wpcf7-not-valid-tip{
			position: absolute;
			background-color: transparent;
			color: $white!important;
			padding: 0px 20px 2px 4px;
			background-color: $red;
			@include font-size(13);
			font-weight: 600;
			line-height: 14px;
			width: auto;
			bottom: -16px;
			transition: $time;
			left: 0px;
			display: none;
		}

		.submit-con{
			display: flex;
			justify-content: flex-end;

			@media (max-width: $b3){
				justify-content: center;
				padding-top: 25px;
			}
		}


		.wpcf7-checkbox,
		.wpcf7-radio{
			display: flex;
		}

		.wpcf7-list-item{
			display: block;
			margin: 0 10px $input-bottom-margin / 2 15px;

			label{
				margin-bottom: $input-bottom-margin / 2;
				text-align: left;
			}
		}
	}

	//ajax load and loading animation
	span.ajax-loader.ajax-loader{
		position: absolute;
		top: -15px;
		left: -15px;
		height: calc(100% + 30px);
		width: calc(100% + 30px);
		max-width: calc(100% + 30px);
		background-image: none;
		background-color: rgba(white, .75);
		margin: 0;

		//visibility: visible;

		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 20px;
			height: 20px;
			animation-name: loading-op;
			animation-iteration-count: infinite;
			animation-duration: 3.6s;
			transform-origin: 50% 50%;
			animation-timing-function: linear;
			border: 2px solid $green;

			@keyframes loading-op{
				0%,
				100%{
					transform: translate(calc(-50% - -10px), calc(-50% - -10px));
				}

				25%{
					transform: translate(calc(-50% - 10px), calc(-50% - -10px));
				}

				50%{
					transform: translate(calc(-50% - 10px), calc(-50% - 10px));
				}

				75%{
					transform: translate(calc(-50% - -10px), calc(-50% - 10px));
				}
			}
		}

		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 20px;
			height: 20px;
			animation-name: loading;
			animation-iteration-count: infinite;
			animation-duration: 3.6s;
			transform-origin: 50% 50%;
			animation-timing-function: linear;
			border: 2px solid $green;

			@keyframes loading{
				0%,
				100%{
					transform: translate(calc(-50% - 10px), calc(-50% - 10px));
				}

				25%{
					transform: translate(calc(-50% - -10px), calc(-50% - 10px));
				}

				50%{
					transform: translate(calc(-50% - -10px), calc(-50% - -10px));
				}

				75%{
					transform: translate(calc(-50% - 10px), calc(-50% - -10px));
				}
			}
		}
	}

	div.wpcf7-mail-sent-ok,
	div.wpcf7-validation-errors,
	div.wpcf7-acceptance-missing{
		margin: 1em 0;
		border: 2px solid $red;
		background-color: $red;
		border-radius: 30px;
		color: $white;
		text-align: center;
		@include font-size(15);
		line-height: 1.5em;
		padding: 10px 10px;
		font-weight: 700;
		font-family: $alt-font;

		@media (max-width: $b2){
			padding: 8px 5px;
		}
	}

	div.wpcf7-mail-sent-ok{
		background-color: darken($green, 12%);
		border: 2px solid darken($green, 12%);
	}

	.LV_validation_message{
		display: none;
	}
}

.re ~ .res{
	display: none;
}

//
//!!CONTACT FORM 7 END
//

.grey-alt-text-with-image-section{
  background-color: $grey-lighter;
  overflow: hidden;
  position: relative;

  &.waypoint{
    transform: none;
    opacity: 1;
    visibility: visible;

    .back{
      opacity: 0;
      visibility: hidden;
    }

    .lower{
      transform: translateY(-100%) translateX(-30%);
      opacity: 0;
      visibility: hidden;

      @media (max-width: $b3){
        transform: translateY(-100%);
      }
    }

    &.animate{

      .back{
        opacity: 1;
        visibility: visible;
        transition: .4s .4s;
      }

      .lower{
        transform: translateY(0%) translateX(-30%);
        opacity: 1;
        visibility: visible;
        transition: .4s .4s;

        @media (max-width: $b3){
          transform: translateY(0%);
        }
      }
    }
  }

  .text-column{

    @media (min-width: $b2 + 1){
      column-count: 2;
      column-gap: 10px;

      ul{
        break-inside: avoid;
        break-inside: avoid-column;

        li{
          break-inside: avoid;
        }
      }
    }

    h6{
      break-after: avoid;
    }

    p{
      break-inside: avoid;
    }
  }

  .text-side{
    position: relative;
    z-index: 112;
    padding-bottom: 60px;

    @media (min-width: $b3 + 1){
      padding-right: 10px;
      padding-bottom: 40px;
    }

    ul{

      li{


      }
    }

    h2{
      margin-bottom: 0.75em;
    }

    h4{
      line-height: 1.15em;
      letter-spacing: .01em;
      margin-bottom: .6em;
    }

    *{
      color: $colour;
    }
  }

  .row{
    padding-top: 180px;
    align-items: center;
    min-height: 100vh;
    //min-height: 904px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 100vh;
    }

    @media (max-width: $b3){
      padding-top: 100px;
    }
  }

  .img-side{
    position: relative;
    align-self: flex-end;

    img{
      position: absolute;
      left: 50%;
      bottom: 0;
      z-index: -1;
      transform: translateX(-50%);
      max-width: 801px;
      z-index: 111;

      @media (min-width: 2132px + 1){
        max-width: 801px * 1.1109;
        width: 131%;
      }

      @media (min-width: 2346px + 1){
        max-width: 801px  * 1.2218;
      }

      @media (min-width: 2558px + 1){
        max-width: 801px  * 1.3328;
      }

      @media (max-width: 1333px){

        @media (min-width: 1100px + 1){
          max-width: 750px;
        }
      }

      @media (max-width: 1100px){
        max-width: 150%;
      }

      &.lower{
        max-width: 134%;

        @media (max-width: 1333px){

          @media (min-width: 1024px + 1){
            max-width: 74%;
          }
        }

        @media (max-width: 1024px){
          max-width: 74%;
        }

        @media (max-width: $b3){
          margin-left: auto;
          margin-right: auto;
        }

        @media (max-height: 500px){
          max-width: 37%;
          margin-bottom: 11%;
        }
      }

      @media (max-width: $b3){
        position: relative;
        left: auto;
        max-width: 100%;

        &.back{
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 50%;

          @media (max-height: 500px){
            max-width: 58%;
          }
        }
      }
    }
  }

  .img-con.img-con{
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 50%;
    z-index: 3;

    @media (max-width: $b3){
      position: relative;
      right: -15px;
    }
  }

  h1{
    color: $blue;
    margin-bottom: 0em;
  }

  h2{
    color: $pink;
  }

  .btn{
    margin-top: 3.2em;

    @media (max-width: $b3){
      margin-top: 25px;
    }
  }

  .img-con{
    background-color: $green;
  }
}

.page-id-8039 /*.text-with-under-image-section +*/ .grey-alt-text-with-image-section {
  position:relative;
  overflow:visible;
  background:$pink url(../images/gradient-pink-to-blue.jpg) no-repeat center center;
  background-size:cover;
  padding-bottom:275px;
  @media (max-width:767px) {
    padding-bottom:90px;
  }
  &:before {
    //content:'';
    width:1150px;
    height:1150px;
    background:#FF4463;
    opacity:0.2;
    display:block;
    border-radius:100%;
    @include centerer-horizontal;
    top:-665px;
  }
  * {
    color:white;
  }
  ul {
    li {
      &:before {
        background:white;
      }
    }
  }
  .under {
    &:After {
      display:none;
    }
  }
}
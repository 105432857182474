@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-family: "slick" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .15 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path + $url);
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	width: 50px;
	height: 50px;
	line-height: 0;
	font-size: 0;
	cursor: pointer;
	background: transparent;
	color: transparent;
	bottom: 24px;
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	z-index: 2;
	transition: $time;

	@media (min-width: 2132px + 1){
		width: 50px * 1.1109;
		height: 50px * 1.1109;
	}

	@media (min-width: 2346px + 1){
		width: 50px * 1.2218;
		height: 50px * 1.2218;
	}

	@media (min-width: 2558px + 1){
		width: 50px * 1.3328;
		height: 50px * 1.3328;
	}

	@media (max-width: $b3){
		width: 48px;
		height: 48px;
	}

	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;

		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}

	&.slick-disabled{
		pointer-events: none;
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
	&:before {
		content: '';
		background-size: 50px 50px;
		background-position: 50% 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50px;
		height: 50px;
		line-height: 1;
		opacity: $slick-opacity-default;
		display: block;
		background-repeat: no-repeat;
		transition: $time;

		@media (min-width: 2132px + 1){
			background-size: 50px * 1.1109 50px * 1.1109;
			width: 50px * 1.1109;
			height: 50px * 1.1109;
		}

		@media (min-width: 2346px + 1){
			background-size: 50px * 1.2218 50px * 1.2218;
			width: 50px * 1.2218;
			height: 50px * 1.2218;
		}

		@media (min-width: 2558px + 1){
			background-size: 50px * 1.3328 50px * 1.3328;
			width: 50px * 1.3328;
			height: 50px * 1.3328;
		}


		@media (max-width: $b3){
			width: 48px;
			height: 48px;
			background-size: 48px 48px;
		}
	}
}

.slick-prev {
	left: 60px;

	@media (max-width: $b3){
		left: 15px;
	}

//	@media (max-width: 1230px){
//		left: -38px;
//	}

	[dir="rtl"] & {
		left: auto;
		right: -68px;

		@media (max-width: 1230px){
			right: -38px;
		}
	}

	@media (min-width: 1024px + 1){
		&:hover{

			&:before {
				background-image: url(../images/slide-prev-hover.svg);
			}
		}
	}

	&:before {
		background-image: url(../images/slide-prev.svg);
		[dir="rtl"] & {
			background-image: url(../images/slide-next.svg);
		}
	}
}

.slick-next {
	right: 60px;

	@media (max-width: $b3){
		right: 15px;
	}

//	@media (max-width: 1230px){
//		right: -38px;
//	}

	[dir="rtl"] & {
		left: -68px;
		right: auto;

		@media (max-width: 1230px){
			left: -38px;
		}
	}

	@media (min-width: 1024px + 1){
		&:hover{

			&:before {
				background-image: url(../images/slide-next-hover.svg);
			}
		}
	}

	&:before {
		background-image: url(../images/slide-next.svg);
		[dir="rtl"] & {
			background-image: url(../images/slide-prev.svg);
		}
	}
}

/* Dots */

//.slick-dotted.slick-slider {
//	margin-bottom: 30px;
//}

.slick-dots {
	position: absolute;
	top: 50%;
	right: 60px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 15px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-width: $b3){
		right: 12px;
	}

	//right: calc(50vw - #{$base-width / 2} - 12px);

	li {
		position: relative;
		display: inline-block;
		height: 15px;
		width: 15px;
		margin: 7.5px 0;
		padding: 0;
		cursor: pointer;

		@media (min-width: 2132px + 1){
			height: 15px * 1.1109;
			width: 15px * 1.1109;
		}

		@media (min-width: 2346px + 1){
			height: 15px * 1.2218;
			width: 15px * 1.2218;
		}

		@media (min-width: 2558px + 1){
			height: 15px * 1.3328;
			width: 15px * 1.3328;
		}

		@media (max-width: $b3){
			margin: 5px 0;
		}

		button {
			border: 0;
			background: white;
			display: block;
			height: 15px;
			width: 15px;
			border-radius: 15px;
			box-shadow: inset 0 0 0 2px $white;
			outline: none;
			line-height: 0px;
			cursor: pointer;
			font-size: 0;
			padding: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: $time;

			@media (min-width: 2132px + 1){
				height: 15px * 1.1109;
				width: 15px * 1.1109;
				border-radius: 15px * 1.1109;
			}

			@media (min-width: 2346px + 1){
				height: 15px * 1.2218;
				width: 15px * 1.2218;
				border-radius: 15px * 1.2218;
			}

			@media (min-width: 2558px + 1){
				height: 15px * 1.3328;
				width: 15px * 1.3328;
				border-radius: 15px * 1.3328;
			}

			&:hover, &:focus {
				outline: none;
				background-color: $blue;
			}
		}

		&.slick-active button {
			background-color: $blue;
		}
	}
}

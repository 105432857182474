.text-with-under-image-section{
  overflow: hidden;

  &.alt{

    .text-top{

      .inner{
        text-align: center;
        width: 790px;
      }

      h2 em{
        color: $pink;
      }
    }
  }

  &.waypoint{
    opacity: 1;
    visibility: visible;
    transform: none;

    .image-under{
      opacity: 0;
      visibility: hidden;
      transform: translateY(100%);
    }

    &.animate{

      .image-under{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: .4s .4s;
      }
    }
  }

  h2{
    @include font-size(40);

    em{
      font-style: normal;
      color: $green;
    }
  }

  .image-under{
    align-self: flex-end;

    @media (min-width: 1920px + 1){

      img{
        width: 100%;
      }
    }

    @media (max-width: $b3){
      padding-left: 3px;
      padding-right: 3px;
    }

    @media (max-width: 1366px){

      img{
        max-width: 72%;
        margin: 0 auto;
      }
    }

    @media (max-width: 1050px){

      img{
        max-width: 97%;
      }
    }

    @media (max-width: $b2){

      img{
        max-width: 100%;
      }
    }
  }

  .row{
    min-height: 100vh;
    padding-top: 140px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 100vh;
    }

    @media (max-width: $b2){
      min-height: 0vh;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: 0vh;
      }
    }

    @media (max-width: $b3){
      padding-top: 100px;
    }
  }

  .inner{
    width: 690px;
    margin: 0 auto;

    @media (min-width: 2132px + 1){
      width: 690px * 1.1109;
    }

    @media (min-width: 2346px + 1){
      width: 690px * 1.2218;
    }

    @media (min-width: 2558px + 1){
      width: 690px * 1.3328;
    }
  }

  .text-top{
    margin-bottom: 2.25em;
  }
}

// fibre cafe page
.page-id-8039 .text-with-under-image-section {
  .text-top {
    margin-bottom:375px;
    margin-bottom:130px;
  }
  .image-under {
    position:relative;
    img {
      //max-width:938px;
      //margin:0 auto;
      //margin-bottom:90px;
      //position:relative;
      //z-index:1;
    }

  }
}
.page-id-8039 .pink-text-with-icon-section {
  background:#efefef;
  padding-bottom:260px;
  @media (max-width:767px) {
    padding-bottom:90px;
  }
  .text-side * {
    color:black;
  }
  .text {
    h2 {
      color:$pink;
    }
  }
  .text-side ul li {
    &:before {
      background:black;
    }
  }
  .under * {
    @media (max-width:767px) {
      color:black;
    }
  }
}
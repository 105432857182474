//!!MENU
header{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: fixed;
	top: 0;
	transition: $time;
	background-color: transparent;
	z-index: 9999;
}

.logo-con{
	width: 200px;
	transition: $time;

	@media (min-width: 2132px + 1){
		width: 200px * 1.1109;
	}

	@media (min-width: 2346px + 1){
		width: 200px * 1.2218;
	}

	@media (min-width: 2558px + 1){
		width: 200px * 1.3328;
	}

	@media (max-width: $b3){
		width: 130px;
	}
}



html:not(.menu-open){

		&:not(.over-footer) .person-page.person-page.person-page.person-page{

		@media (max-width: $b3){

			#site-logo{
				filter: opacity(1) contrast(0) brightness(9999);
			}
		}
	}


	&:not(.page-scrolled){

		&.person-open{

			#site-logo{
				filter: opacity(1) contrast(0) brightness(9999);
				transition: 0s 0s;

				.alt.alt{
					filter: opacity(1);
					visibility: visible;
					transition: 0s 0s;
				}

				.small,
				.big{
					filter: opacity(0);
					visibility: hidden;
					transition: 0s 0s;
				}
			}
		}

		&.first-is-light{

			#site-logo{

				.big{
					filter: opacity(0);
					visibility: hidden;
					transition: 0s 0s;
				}

				.alt{
					filter: opacity(1);
					visibility: visible;
					transition: 0s 0s;
				}
			}

			.small-menu{

//				a{
//					color: white;
//
//					&:hover,
//					&:focus{
//						color: $green;
//					}
//				}
			}
		}
	}



	&.over-light{

		#site-logo{
			filter: opacity(1) contrast(0) brightness(9999);
			visibility: visible;
			transition: 0s 0s;

			.alt.alt,
			.big{
				filter: opacity(0);
				visibility: hidden;
				transition: 0s 0s;
			}

			.small{
				filter: opacity(1);
				visibility: visible;
				transition: 0s 0s;
			}
		}

		.small-menu{

//			a{
//				color: white;
//
//				&:hover,
//				&:focus{
//					color: $green;
//				}
//			}
		}
	}

	&.over-dark{

		#site-logo{

			.alt.alt,
			.big{
				filter: opacity(0);
				visibility: hidden;
				transition: 0s 0s;
			}

			.small{
				filter: opacity(1);
				visibility: visible;
				transition: 0s 0s;
			}
		}
	}
}

#site-logo{
	display: block;
	height: auto;
	max-width: 100%;
	width: 200px;
	height: 58px;
	position: relative;
	margin: 0;
	z-index: 100;
	transition: 0s .4s;

	@media (min-width: 2132px + 1){
		width: 200px * 1.1109;
		height: 58px * 1.1109;
	}

	@media (min-width: 2346px + 1){
		width: 200px * 1.2218;
		height: 58px * 1.2218;
	}

	@media (min-width: 2558px + 1){
		width: 200px * 1.3328;
		height: 58px * 1.3328;
	}

	img{
		max-height: 58px;

		@media (min-width: 2132px + 1){
			max-height: 58px * 1.1109;
		}

		@media (min-width: 2346px + 1){
			max-height: 58px * 1.2218;
		}

		@media (min-width: 2558px + 1){
			max-height: 58px * 1.3328;
		}
	}

	@media (max-width: $b3){
		width: 130px;
		height: 38px;

		img{
			max-height: 38px;
		}
	}

	.menu-open &{
		transition: 0s .75s;
		z-index: 102;

		.big{
			filter: opacity(0);
			visibility: hidden;
			transition: 0s .75s;
		}

		.small{
			filter: opacity(1);
			visibility: visible;
			transition: .5s .75s;
		}
	}

	.alt{
		filter: opacity(0);
		visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
	}

	.big{
		transition: 0s .5s;

		.scrolled:not(.menu-open) &{
			filter: opacity(0);
			visibility: hidden;
			transition: 0s;
		}
	}

	.small{
		filter: opacity(0);
		visibility: hidden;
		position: absolute;
		transition: .5s 0s;
		top: 0;
		left: 0;

		.scrolled:not(.menu-open) &{
			filter: opacity(1) contrast(0) brightness(9999);
			visibility: visible;
			transition: 0s;
		}

		htm:not(.menu-open) &{
			filter: opacity(1) contrast(0) brightness(9999);
			visibility: visible;
			transition: 0s;
		}
	}

	&.over.over{


		.small{
			filter: opacity(1);
		}
	}

	img{
		display: block;
		width: 100%;
	}
}

.menu-top-outer{
	padding-top: 61px;
	padding-bottom: 0px;
	transition: $time;

	@media (max-width: $b2){
		padding-top: 17px;
		padding-bottom: 0px;
	}

	@media (max-width: $b3){
		padding-top: 10px;
		padding-bottom: 0px;
	}

//	.scrolled &{
//		background-color: rgba(#EFEFEF, .75);
//		padding-top: 20px;
//		padding-bottom: 20px;
//	}

	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;

//		@media (min-width: $b2 + 1){
//			padding-left: 24px;
//			padding-right: 24px;
//		}
	}

	.menu-con{
		display: flex;
		justify-content: space-between;
		align-items: center;

		ul{
			margin: 0 26px 0 0;

			@media (max-width: $b3){
				margin: 0 5px 0 5px;
			}

			li{
				margin: 0;

				&:first-of-type{

					a{
						margin-left: 0;
					}
				}

				&:last-of-type{

					a{
						margin-right: 0;
					}
				}

				a{
					margin: 0 6px;
					color: $grey;
					text-decoration: none;
					font-family: $alt-font;

					@media (max-width: $b3){
						margin: 0 2.5px;
						@include font-size(10);
					}

					.scrolled &{
						color: $white;
					}

					&:hover,
					&:focus{
						color: $green;
					}
				}
			}
		}
	}
}

.videos-section + .product-section.colour-pink{

	.btn.btn.btn{

		&:hover,
		&:focus{
			color: $colour!important;
			border-color: $green;
			background-color: $green;
		}
	}
}

.menu-bottom-outer{
	position: absolute;
	top: 184.64px;
	top: 119px;
	left: 0;
	width: 100%;
	height: calc(100vh - 184.64px);
	height: calc(100vh - 119px);
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transition: 0s;

	@media (max-width: $b2){
		top: 70px;
		top: 75px;
		height: calc(100vh - 70px);
		height: calc(100vh - 75px);
	}

	@media (max-width: $b3){
		top: 54px;
		height: calc(100vh - 54px);
	}

	.scrolled &{
		top: 90px;
		height: calc(100vh - 90px);

		@media (max-width: $b2){
			top: 70px;
			top: 60px;
			height: calc(100vh - 70px);
			height: calc(100vh - 60px);
		}
	}

	.menu-open &{
		opacity: 1;
		visibility: visible;
		transition: 0s;
	}

	.container{
		position: relative;
		height: 100%;
	}

	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		position: static;

		@media (max-width: $b2){
			align-items: center;
		}
	}

	.row{
		@media (max-width: $b2){
			min-height: 100%;
		}
	}

	.side{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 40px;
		z-index: 11;
		opacity: 0;
		visibility: hidden;
		transition: .5s 0s;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		width: 25px;
		padding-bottom: 95px;
		padding-top: 10px;

		@media (min-width: 1920px + 1){
			width: 2.5rem;
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			width: 40px;
			display: flex;
			justify-content: center;
		}

		@media (min-width: $b2 + 1){
			padding-bottom: 105px;

			.bottom.bottom{
				bottom: 50px;
			}

			@media (max-height: 667px){

				.tag.tag{
					display: none;
				}

				.top.top a{
					margin-bottom: 0;
				}
			}

			@media (max-height: 388px){

				.top.top a{
					display: none;
				}
			}
		}

		@media (max-width: $b2){

			@media (min-width: $b3 + 1){

				@media (max-height: 601px){

					.tag.tag{
						display: none;
					}

					.top.top a{
						margin-bottom: 0;
					}
				}

				@media (max-height: 326px){

					.top.top a{
						display: none;
					}
				}
			}
		}

		@media (max-width: $b3){

			@media (max-height: 583px){

				.tag.tag{
					display: none;
				}

				.top.top a{
					margin-bottom: 0;
				}
			}

			@media (max-height: 303px){

				.top.top a{
					display: none;
				}
			}
		}

		@media (max-width: $b3){
			left: 20px;
		}

		.menu-open &{
			opacity: 1;
			visibility: visible;
			transition: .5s .75s;
		}

		.bottom{
			display: flex;
			flex-direction: column;
			margin-top: 0;
			position: absolute;
			bottom: 25px;
			width: 25px;
			max-width: 25px;
			left: 50%;
			transform: translateX(-50%);

			@media (min-width: 1920px + 1){
				width: 2.5rem;
				max-width: 2.5rem;
			}

			a{
				width: 25px;
				height: 25px;
				border-radius: 25px;
				background-color: $colour;
				line-height: 25px;
				text-align: center;
				margin-bottom: 10px;

				@media (min-width: 1920px + 1){
					width: 2.5rem;
					height: 2.5rem;
					line-height: 2.5rem;
				}

				&:last-of-type{
					margin-bottom: 0;
				}

				&:hover,
				&:focus{
					background-color: $green;
				}

				i{
					@include font-size(15);
					color: white;
				}
			}
		}

		.top{
			writing-mode: tb;
			display: flex;
			transform: scale(-1);
			@include font-size(15);
			font-family: $alt-font;
			font-weight: 600;
			//margin-bottom: 125px;

			@supports (-ms-ime-align: auto) {
				transform: scale(-1) rotate(90deg);
				width: 515px;
				min-width: 515px;

				@media (max-height: 667px){
					width: 175px;
					min-width: 175px;

					.tag.tag{
						display: none;
					}

					.top.top a{
						margin-bottom: 0;
					}
				}
			}

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				transform: scale(-1) rotate(90deg);
				width: 515px;
				min-width: 515px;

				@media (max-height: 667px){
					width: 175px;
					min-width: 175px;

					.tag.tag{
						display: none;
					}

					.top.top a{
						margin-bottom: 0;
					}
				}


			}

			*{
				@include font-size(15);
			}

			.tag{
				margin-bottom: 0;

				@supports (-ms-ime-align: auto) {
					margin-bottom: 0;
					margin-left: 20px;
				}

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					margin-bottom: 0;
					margin-left: 20px;
				}
			}

			a{
				color: $colour;
				margin-bottom: 20px;
				text-decoration: none;
				font-weight: 600;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					margin-bottom: 0;
					margin-left: 20px;
				}

				@supports (-ms-ime-align: auto) {
					margin-bottom: 0;
					margin-left: 20px;
				}

				&:hover,
				&:focus{
					color: $green;
				}
			}
		}
	}
}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';


#mainMenu{
	transform: translate3d(0, 100vh, 0);
	transition: .5s 0s;
	padding-left: 140px;
	max-height: calc(100vh - 140px);
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	scrollbar-width: none; /* Firefox */
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;

	@media (max-width: 1024px){
		margin-top: -40px;
	}

	@media (max-height: 500px){
		max-height: calc(100vh - 54px);
		margin-top: 0!important;
	}

	@media (max-width: $b3){
		margin-top: -4em;
	}

	&::-webkit-scrollbar {
		display: none;
		width: 0px;
		background: transparent; /* Chrome/Safari/Webkit */
	}

	@media (max-width: $b3){
		padding-left: 50px;
	}

	.scrolled &{
		max-height: calc(100vh - 90px);
	}

	.menu-open &{
		transform: translate3d(0, 0vh, 0);
		transition: .75s .85s cubic-bezier(.25, 0, 0, 1);
	}

//	@media (max-width: $b2){
//		width: 100%;
//		display: flex;
//		align-items: center;
//		flex-direction: column;
//		position: absolute;
//		top: 100%;
//		left: 0;
//		right: 0;
//		width: auto;
//		transition: .3s 0s linear;
//		z-index: -1;
//		margin-top: 0;
//		display: none;
//		padding: 0 28px;
//		background-color: $white;
//
//		.menu-open &{
//			transform: translateY(0);
//			z-index: -1;
//			transition: .15s 0s linear;
//			display: flex;
//		}
//	}
//
//	@media (max-width: $b3){
//		padding: 0 10px;
//	}

}

//#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
//#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
//#mainMenu .main-menu .current-menu-parent,
//#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
//#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){
//
//	> a{
//		color: $white;
//		background-color: $blue;
//	}
//}

.small-menu.small-menu.small-menu .current,
.main-menu .current{
	pointer-events: none;
	color: $blue;
}

.small-menu.small-menu.small-menu{
	display: flex;
	justify-content: space-between;
	align-items: center;

	li{

		&:nth-of-type(1){

			a{
				color: $pink;

				&:hover,
				&:focus{
					color: $green;
				}
			}
		}

		&:nth-of-type(2){

			a{
				color: $blue;

				&:hover,
				&:focus{
					color: $green;
				}
			}
		}

		a{
			display: block;
			padding: 10px 33px;
			background-color: white;
			color: $blue;
			border-radius: 32px;
			text-align: center;

			@media (min-width: 2132px + 1){
				padding: 10px * 1.1109 33px * 1.1109;
				border-radius: 32px * 1.1109;
			}

			@media (min-width: 2346px + 1){
				padding: 10px * 1.2218 33px * 1.2218;
				border-radius: 32px * 1.2218;
			}

			@media (min-width: 2558px + 1){
				padding: 10px * 1.3328 33px * 1.3328;
				border-radius: 32px * 1.3328;
			}

			@media (max-width: $b3){
				padding: 5px 5px;
			}

			&:hover,
			&:focus{
				color: $green;
			}
		}
	}
}

ul.main-menu{
	margin: 0;
	padding: 0;
	@include font-size(0);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;

	@media (max-width: $b2){
		padding-top: 0;
	}

	> li{

		&.menu-item-has-children{
			position: relative;

			@media (min-width: $b2 + 1){

				&:hover,
				&:focus{

					> ul.sub-menu{
						display: block;
					}
				}
			}

			@media (max-width: $b2){
				padding-right: 40px;
				background-image: url(../images/arrow-down-blue.svg);
				background-repeat: no-repeat;
				background-position: right 8px top 16px;

				&.open{
					background-image: url(../images/arrow-up-blue.svg);

					> ul.sub-menu{
						display: block;
					}
				}
			}

			> .sub-menu{
				margin-bottom: 0;
				width: 100%;
				max-width: none;
				top: 100%;
				left: 0;
				position: absolute;
				background-color: white;
				display: none;
				min-width: 200px;

				@media (max-width: $b2){
					position: relative;
					top: 0;
					background-color: transparent;
					padding: 0;
					display: none;
					min-width: none;
				}

				li{
					display: block;
					padding: 0!important;
					margin: 0;

					@media (max-width: $b2){
						border-bottom: none;
					}

					&:before{
						display: none;
					}
				}

				a{
					padding: 6px 18px;
					display: block;
					@include font-size(18);

					@media (max-width: $b2){
						@include font-size(14);
						display: inline-block;
					}
				}
			}
		}
	}

	li{
		@include font-size(135);
		line-height: 1em;
		text-align: left;
		margin-bottom: .1em;

		@media (max-width: 1200px){
			margin: 0;
		}

		@media (max-width: $b2){
			width: 100%;
			margin: 0;
			@include font-size(20);
		}

		@media (max-width: $b3){
			@include font-size(75);
		}

		@media (max-width: $b4){
			@include font-size(39);
		}

		> a{
			@include font-size(135);
			@include font-size(101);
			width: auto;
			color: $grey-lighter;
			line-height: 1.2em;
			text-decoration: none!important;
			font-weight: 600;
			position: relative;
			font-family: $alt-font;
			padding: 0 0;
			display: block;
			white-space: nowrap;
			position: relative;
			letter-spacing: -0.02em;

			&:after{
				content: '';
				position: absolute;
				right: -10px;
				width: 100%;
				width: calc(100% + 20px);
				max-width: calc(100% + 20px);
				left: -10px;
				bottom: 0;
				background-color: white;
				overflow: hidden;
				max-height: 0;
				transition: .5s;
				height: 100%;
			}

			&.active{

				&:after{
					max-height: 100%;
				}
			}

			@media (max-height: 750px ){
				@include font-size(110);
				@include font-size(90);
			}

			@media (max-width: $b2){
				@include font-size(75);
				line-height: 1em;
			}

			@media (max-width: $b3){
				@include font-size(70);
				padding: 10px 0;
			}

			@media (max-width: $b4){
				@include font-size(38);
				padding: 5px 0;
			}

			&:hover,
			&:focus{
				color: $green;
			}
		}
	}
}

.text-with-video-section{
    position: relative;
    overflow: hidden;
    margin-bottom: -1px;

    &.waypoint{
        transform: none;
        opacity: 1;
        visibility: visible;

        .img-con img{
            transform: translateY(100%);
            opacity: 0;
            visibility: hidden;
        }

        &.animate{

            .img-con img{
                transform: translateY(0%);
                opacity: 1;
                visibility: visible;
                transition: .4s .4s;
            }
        }
    }

    &.colour-light-grey{
        background-color: $grey-lighter;

        *{
            color: $grey;
        }

        .icon-con .i-text,
        h2{
            color: #707070;
        }

        .icon-con .i-text{
            color: $grey;
        }

        ul{

            li{

                &:before{
                    background-color: $green;
                }
            }
        }
    }

    &.side-left{

        .img-side{
            order: 2;

            img{
                right: 0;

                @media (max-width: $b1){

                    @media (min-width: $b3 + 1){
                        right: -20%
                    }
                }

                @media (max-width: $b3){
                    right: -15px;
                    float: right;
                }
            }
        }

        .text-side{
            order: 1;
        }
    }

    h2{
        margin-bottom: .8em;
    }

    h2{
        @include font-size(40);

        @media (max-width: 1500px){
            @include font-size(40);
        }

        @media (max-width: $b2){
            @include font-size(30);
        }
    }

    h4{
        line-height: 1.1em;
        letter-spacing: 0.01em;
    }

    .icon-con{
        display: flex;
        align-items: center;
        margin-top: 3em;

        @media (max-width: 1366px){
            margin-top: 2em;
        }

        .i-text{
            @include font-size(30);
            margin-left: 10px;
            line-height: 1.2em;
            font-family: $alt-font;
        }
    }

    .row{
        padding-top: 160px;
        align-items: center;
        min-height: 100vh;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            height: 100vh;
        }

        @media (max-width: $b3){
            padding-top: 100px;
        }
    }

    .img-side{
        position: relative;

        @media (max-width: $b3){
            order: 2;
        }

        @media (min-width: $b3 + 1){
            margin-top: -15%;
        }

        &:before{
            content: '';
            position: absolute;
            top: 26%;
            left: -34%;
            width: 120%;
            max-width: 120%;
            padding-top: 102.3%;
            background-image: url(../images/finger-print.svg);
            background-size: 100%;
        }

        // img{
        // 	position: absolute;
        // 	bottom: 0;
        // 	max-width: 50%;

        // 	@media (max-width: $b1){

        // 		@media (min-width: $b3 + 1){
        // 			max-width: 70%;
        // 		}
        // 	}

        // 	@media (max-width: $b3){
        // 		position: relative;
        // 		max-width: 50%;
        // 	}
        // }


        .img-con{
            position: relative;
            display: inline-block;

            iframe{
                width: 100%;
            }

            .play{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                &:hover{

                    &::before{
                        background-color: $green;
                    }
                }

                &:before,
                &::after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: $time;
                }

                &::before{
                    width: 82px;
                    height: 52px;
                    background-color: $blue;
                    mix-blend-mode: multiply;
                    z-index: 1;
                }

                &::after{
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 12px 0 12px 17px;
                    border-color: transparent transparent transparent #ffffff;
                    z-index: 2;
                }
            }
        }
    }

    *{
        color: white;
    }

    .img-side{

        @media (min-width: $b3 + 1){
            padding-left: 76px;
        }
    }

    .text-side{
        padding-bottom: 50px;

        @media (min-width: $b3 + 1){
            padding-left: 10px;
        }
    }

    .text{
        margin-bottom: 1.1em;
    }

    .text-column{

        @media (min-width: $b2 + 1){
            //			column-count: 2;
            //			column-gap: 26px;

            display: flex;
            justify-content: space-between;

            p,
            ul{
                flex: 0 0 calc(50% - 13px);
            }

            ul{
                break-inside: avoid;
                break-inside: avoid-column;

                li{
                    break-inside: avoid;
                }
            }
        }

        p{
            break-inside: avoid;
        }
    }

    .btn-con{
        padding-top: 52px;
    }
}
// layout 2 ################
.text-with-video-section.layout-2 {
    background:#444444;
    * {
        color:white;
    }
    .highlight {
        color:$green;
    }
    .container {
        &:After {
            content:'';
            display:block;
            clear:both;
        }
    }
    .text-with-video-section__heading {
        text-align:center;
        //font-weight:400;
        margin-bottom:85px;
        position:relative;
    }
    .text-with-video-section__video-wrap {
        //background:$blue;
        padding-top:137px;
        position:relative;
        @media (max-width:767px) {

        }
        &:before {
            content:'';
            position:absolute;
            top:0;
            left:0;
            right:0;
            height:697px;
            background:$blue;
        }
        &:after {
            content:'';
            width:736px;
            height:736px;
            background:url(../images/text-with-video-bg-print.svg) no-repeat center center;
            display:block;
            position:absolute;
            right:104px;
            bottom:-383px;
            z-index:0;

        }
        .img-con {
            position:relative;
            z-index:1;
            //margin-bottom:-185px;
        }
    }

    .text-with-video-section__video-wrap + .text-with-video-section__content {
        padding-top:295px;
        padding-top:111px;
        background:#444444;
    }
    ul {
        li {
            &:before {
                background:$green;
            }
        }
    }
    .text-with-video-section__intro {
        max-width:765px;
        position:relative;
        &:before {
            content:'';
            background:#585757;
            width:674px;
            height:674px;
            border-radius:100%;
            position:absolute;
            top:-256px;
            left:-140px;
            display:block;
            z-index:0;
        }
        &__text {
            position:relative;
            z-index:1;
        }
        h3 {
            font-size:3rem;
        }
    }
    .text-column {
        width:50%;
        float:right;
        margin-top:115px;
        font-size:15px;
        position:relative;
        z-index:1;
        @media (max-width:767px) {
            width:100%;
            margin-top:0;
        }
    }
}

